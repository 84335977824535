import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import HomePrivate from './pages/homePrivate/HomePrivate';
import 'react-toastify/dist/ReactToastify.css';
import AppMenu from './menus/AppMenu';
import Suppliers from './pages/suppliers/Suppliers';
import Customers from './pages/customers/Customers';
import Users from './pages/users/Users';
import Invoices from './pages/invoices/Invoices';
import Partners from './pages/partners/Partners';
import InvoiceNumber from './pages/invoicenumbers/Invoicenumbers';
import BulkInvoices from './pages/bulkinvoices/BulkInvoices';
import PdfSchemas from './pages/pdfschemas/PdfSchemas';
import PdfLink from './pages/pdflink/PdfLink';
import HomeInit from './pages/homeinit/HomeInit';
import { useReduxDispatch } from '../store/Store';
import { homeActions } from '../store/appSlices/HomeSlice';
import { FIXIP_CUSTOMER, FIXIP_SUPPLIER, FIXIP_SUPPORT } from '../utils/variables';
import { usePermissions } from '../logics/usePermissions';
import { Layout } from './Layout';
import Emails from './pages/emails/Emails';
import AboutUs from './pages/aboutUs/aboutUs';
import Footer from './menus/Footer';
import EmailTemplates from './pages/emailTemplates/EmailTemplates';
import InvoiceStores from './pages/invoiceStores/InvoiceStores';
import Sites from './pages/sites/Sites';
import Suppliers_ from './pages/supplierPages/suppliers/Suppliers_';
import Sites_ from './pages/supplierPages/sites/Sites_';
import Invoices_ from './pages/supplierPages/invoices/Invoices_';
import Emails_ from './pages/supplierPages/emails/Emails_';
import Partners_ from './pages/supplierPages/partners/Partners_';
import InvoiceStores_ from './pages/supplierPages/invoiceStores/InvoiceStores_';
import EmailTemplates_ from './pages/supplierPages/emailTemplates/EmailTemplates_';
import Users_ from './pages/supplierPages/users/Users_';
import UndeliveredEmails_ from './pages/supplierPages/undeliveredEmails/UndeliveredEmails_';
import Invoices_c from './pages/customerPages/invoices/Invoices_c';

const App: React.FC = () => {
  const { isSupportUser, isSupplierUser, isCustomerUser } = usePermissions();
  const dispatch = useReduxDispatch();
  const [showLogo, setShowLogo] = useState(true);

  const fixIp = useMemo(() => {
    if (isSupportUser) return FIXIP_SUPPORT;
    else if (isSupplierUser) return FIXIP_SUPPLIER;
    else if (isCustomerUser) return FIXIP_CUSTOMER;
  }, [isCustomerUser, isSupplierUser, isSupportUser]);

  useEffect(() => {
    const setWidthHeight = () => {
      dispatch(homeActions.setWindowWidth(window.innerWidth));
      dispatch(homeActions.setWindowHeight(window.innerHeight));
    };
    window.addEventListener('resize', setWidthHeight);
    return () => {
      window.removeEventListener('resize', setWidthHeight);
    };
  }, [dispatch]);

  return (
    <>
      {!showLogo && <AppMenu showLogo={showLogo} />}
      <Layout showLogo={showLogo}>
        <Routes>
          <Route path="/" element={<HomeInit showLogo={showLogo} setShowLogo={setShowLogo} />} />
          <Route path="/pdflink/:hash" element={<PdfLink showLogo={showLogo} setShowLogo={setShowLogo} />} />
          <Route path="/login" element={<HomePrivate showLogo={showLogo} setShowLogo={setShowLogo} />} />

          <Route path={`${FIXIP_SUPPORT}/suppliers`} element={<Suppliers />} />
          <Route path={`${FIXIP_SUPPORT}/sites`} element={<Sites />} />
          <Route path={`${FIXIP_SUPPORT}/invoicenumber`} element={<InvoiceNumber />} />
          <Route path={`${FIXIP_SUPPORT}/partners`} element={<Partners />} />
          <Route path={`${FIXIP_SUPPORT}/customers`} element={<Customers />} />
          <Route path={`${FIXIP_SUPPORT}/users`} element={<Users />} />
          <Route path={`${FIXIP_SUPPORT}/invoices`} element={<Invoices />} />
          <Route path={`${FIXIP_SUPPORT}/bulkinvoices`} element={<BulkInvoices />} />
          <Route path={`${FIXIP_SUPPORT}/pdfschemas`} element={<PdfSchemas />} />
          <Route path={`${FIXIP_SUPPORT}/emails`} element={<Emails />} />
          <Route path={`${FIXIP_SUPPORT}/aboutus`} element={<AboutUs />} />
          <Route path={`${FIXIP_SUPPORT}/emailtemplates`} element={<EmailTemplates />} />
          <Route path={`${FIXIP_SUPPORT}/invoicestores`} element={<InvoiceStores />} />

          <Route path={`${FIXIP_SUPPLIER}/suppliers_`} element={<Suppliers_ />} />
          <Route path={`${FIXIP_SUPPLIER}/sites_`} element={<Sites_ />} />
          <Route path={`${FIXIP_SUPPLIER}/invoices_`} element={<Invoices_ />} />
          <Route path={`${FIXIP_SUPPLIER}/emails_`} element={<Emails_ />} />
          <Route path={`${FIXIP_SUPPLIER}/undeliveredemails_`} element={<UndeliveredEmails_ />} />
          <Route path={`${FIXIP_SUPPLIER}/partners_`} element={<Partners_ />} />
          <Route path={`${FIXIP_SUPPLIER}/invoicestores_`} element={<InvoiceStores_ />} />
          <Route path={`${FIXIP_SUPPLIER}/emailtemplates_`} element={<EmailTemplates_ />} />
          <Route path={`${FIXIP_SUPPLIER}/users_`} element={<Users_ />} />

          <Route path={'/pdflink/invoices_c'} element={<Invoices_c />} />

          {/* <Route path="/" element={<HomeInit showLogo={showLogo} setShowLogo={setShowLogo} />} /> */}
          {/* <Route path="/" element={<Home />} /> */}
        </Routes>
        {!showLogo && <Footer />}
      </Layout>
      <ToastContainer limit={5} closeOnClick={true} pauseOnHover={true} className="!w-fit !max-w-xl !whitespace-pre-line" />
    </>
  );
};

export default App;
