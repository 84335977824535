import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch } from '../../../../store/Store';
import { DataColumnsProp } from '../../../types/Table.type';
import { Button } from '@mui/material';
import { tableActions } from '../../../../store/appSlices/TableSlice';
import HtmlIcon from '@mui/icons-material/Html';
import { tableVariables } from '../../../../utils/variables';

export const useDataColumnsEmailTemplate_ = () => {
  const { t } = useTranslation(['emailTemplate']);
  const dispatch = useReduxDispatch();

  const handleClickData = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    dispatch(tableActions.setRowEmailTemplateId(id));
    dispatch(tableActions.setIsEmailTemplateViewModalOpen_(true));
  };

  const handleClickSample = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, sample: object) => {
    e.preventDefault();
    dispatch(tableActions.setRowCustom(sample));
    dispatch(tableActions.setIsEmailTemplateSampleViewModalOpen_(true));
  };

  const handleClickLogo = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, logo: object) => {
    e.preventDefault();
    dispatch(tableActions.setRowCustom({ src: logo }));
    dispatch(tableActions.setIsEmailTemplateViewLogoModalOpen(true));
  };

  const dataColumns: DataColumnsProp[] = [
    { id: 'id', label: t('id', { ns: ['emailTemplate'] }), type: 'number' },
    { id: 'name', label: t('name', { ns: ['emailTemplate'] }), type: 'string' },
    { id: 'language', label: t('language', { ns: ['emailTemplate'] }), type: 'string' },

    {
      id: 'data',
      label: t('data', { ns: ['emailTemplate'] }),
      type: 'string',
      component: (row) => {
        if (row.data) {
          return (
            <Button size="small" sx={{ textTransform: 'uppercase' }} onClick={(e) => handleClickData(e, row.id)}>
              {/* <span className={`bg-transparent ${tableVariables.buttonsColorText} text-nowrap`}>{t('data', { ns: ['emailTemplate'] })}</span> */}
              <HtmlIcon />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'logo',
      label: t('logo', { ns: ['emailTemplate'] }),
      type: 'string',
      component: (row) => {
        if (row.logo) {
          return (
            <Button size="small" sx={{ textTransform: 'uppercase' }} onClick={(e) => handleClickLogo(e, row.logo)}>
              <img className="m-auto h-auto max-w-[100px] transition-all duration-300 hover:cursor-zoom-in" src={row.logo} alt="logo"></img>;
              {/* <img className="m-auto h-auto max-w-[100px] transition-all duration-300 hover:grayscale-0 hover:max-w-xs hover:cursor-zoom-in" src={row.logo} alt="logo"></img>; */}
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    // {
    //   id: 'samle',
    //   label: t('sample', { ns: ['emailTemplate'] }),
    //   type: 'string',
    //   component: (row) => {
    //     if (row.sample) {
    //       return (
    //         <Button size="small" sx={{ textTransform: 'capitalize' }} onClick={(e) => handleClickSample(e, row.sample)}>
    //           <span className={`bg-transparent ${tableVariables.buttonsColorText} text-nowrap`}>{t('code', { ns: ['emailTemplate'] })}</span>
    //         </Button>
    //       );
    //     } else {
    //       return <></>;
    //     }
    //   },
    // },
    // { id: 'type', label: t('type', { ns: ['emailTemplate'] }), type: 'string' },
    // {
    //   id: 'supplier',
    //   label: t('supplier', { ns: ['emailTemplate'] }),
    //   type: 'string',
    //   component: (row) => {
    //     if (row.supplier && row.supplier.name) {
    //       return <span>{row.supplier.name}</span>;
    //     } else {
    //       return <></>;
    //     }
    //   },
    // },
  ];
  return { dataColumns };
};
