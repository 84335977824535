import React from 'react';
import { tableActions } from '../store/appSlices/TableSlice';
import { useAPI } from './useAPI';
import { useReduxDispatch, useReduxSelector } from '../store/Store';
import { KeyedMutator } from 'swr';
import { API_CREATE_SUPPLIER, API_DATA, API_DELETE_SUPPLIER, API_UPDATE_SUPPLIER, API_UPDATE_SUPPLIER_S } from '../utils/variables';
import { convertKey, keyConvert } from '../utils/common';
import { DialogProps } from '@mui/material';
import { usePermissions } from './usePermissions';
import { authActions } from '../store/appSlices/AuthSlice';
import { FieldsProfileProps } from '../components/UI/InputModal';
import { SelectOptionsEmailTemplateType } from '../components/types/EmailTemplate.type';

type UseSupplierProps = {
  mutateSuppliers: KeyedMutator<any>;
};

type FormInputsProps = {
  [key: string]: any;
};

export const useSupplier = ({ mutateSuppliers }: UseSupplierProps) => {
  const dispatch = useReduxDispatch();
  const rowCustom = useReduxSelector((state) => state.table.rowCustom);
  const rowId = useReduxSelector((state) => state.table.rowId);

  const { createData, updateData, deleteData } = useAPI();
  const { isSupportUser, isSupplierUser, isCustomerUser, userSupplierId, userSupplierName } = usePermissions();

  const handleOpenCreateModal = () => {
    dispatch(tableActions.setIsSupplierCreateModalOpen(true));
  };

  const handleCloseCreate = () => {
    dispatch(tableActions.setIsSupplierCreateModalOpen(false));
  };

  const handleCloseCreateModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    dispatch(tableActions.setIsSupplierCreateModalOpen(false));
  };

  const handleCancelCreate = () => {
    dispatch(tableActions.setIsSupplierCreateModalOpen(false));
  };

  const onSubmitCreateProp = async (
    data: FormInputsProps,
    profileFields?: FieldsProfileProps,
    customLogoBase64?: string,
    withEmailTemplates?: boolean,
    emailTemplates?: SelectOptionsEmailTemplateType[],
  ) => {
    const newData = {} as FormInputsProps;
    const profile = {} as FormInputsProps;

    Object.keys(data).map((key: string) => {
      const profileItems = profileFields?.filter(({ items }) => items.some(({ name }) => name === key));
      if (profileItems && profileItems.length > 0) {
        profile[convertKey(key) as keyof typeof profile] = data[key as keyof typeof data];
      } else {
        newData[convertKey(key) as keyof typeof newData] = data[key as keyof typeof data];
      }
    });
    newData['profile'] = profile;
    const logo = customLogoBase64?.substring(customLogoBase64?.indexOf('src="') + 5);
    newData['profile']['logo'] = logo?.substring(0, logo.length - 1);
    newData['emailTemplates'] = emailTemplates;

    const api = API_DATA + API_CREATE_SUPPLIER;
    try {
      const response = await createData(api, newData);
      mutateSuppliers();
      return response;
    } catch (err) {
      return err;
    }
    // dispatch(tableActions.setIsSupplierCreateModalOpen(false));
  };

  const handleOpenEditModal = (row: any) => {
    const newData = {} as any;
    Object.keys(row).map((key: string) => {
      newData[keyConvert(key)] = row[key as keyof typeof row];
    });
    dispatch(tableActions.setRowCustom(newData));
    dispatch(tableActions.setIsSupplierEditModalOpen(true));
  };

  const handleCloseEdit = () => {
    dispatch(tableActions.setIsSupplierEditModalOpen(false));
    dispatch(tableActions.setRowCustom({}));
  };

  const handleCloseEditModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    dispatch(tableActions.setIsSupplierEditModalOpen(false));
    dispatch(tableActions.setRowCustom({}));
  };

  const handleCancelEdit = () => {
    dispatch(tableActions.setIsSupplierEditModalOpen(false));
    dispatch(tableActions.setRowCustom({}));
  };

  const modifyUserProfileSupplier = (id: string, newData: FormInputsProps) => {
    if (isSupportUser) {
      dispatch(authActions.setProfileSupportSupplierId(newData.supplierId));
      dispatch(authActions.setProfileSupportSupplierName(newData.supplierName));
    } else if (isSupplierUser) {
      dispatch(authActions.setProfileSupplierSupplierId(newData.supplierId));
      dispatch(authActions.setProfileSupplierSupplierName(newData.supplierName));
    }
  };

  const onSubmitEditProp = async (
    data: FormInputsProps,
    profileFields?: FieldsProfileProps,
    customLogoBase64?: string,
    withEmailTemplates?: boolean,
    emailTemplates?: SelectOptionsEmailTemplateType[],
    supplierId?: string,
  ) => {
    const newData = {} as FormInputsProps;
    const profile = {} as FormInputsProps;

    Object.keys(data).map((key: string) => {
      const profileItems = profileFields?.filter(({ items }) => items.some(({ name }) => name === key));
      if (profileItems && profileItems.length > 0) {
        profile[convertKey(key) as keyof typeof profile] = data[key as keyof typeof data];
      } else {
        newData[convertKey(key) as keyof typeof newData] = data[key as keyof typeof data];
      }
    });

    newData['profile'] = profile;

    const logo = customLogoBase64?.substring(customLogoBase64?.indexOf('src="') + 5);
    newData['profile']['logo'] = logo?.substring(0, logo.length - 1);
    newData['emailTemplates'] = emailTemplates;

    const id = isSupportUser ? rowCustom['id' as keyof typeof rowCustom] : isSupplierUser && supplierId ? supplierId : undefined;

    const api = isSupportUser ? `${API_DATA}${API_UPDATE_SUPPLIER}/${id}` : isSupplierUser ? `${API_DATA}${API_UPDATE_SUPPLIER_S}` : '';

    try {
      const response = await updateData(api, newData);

      if (response.success) {
        mutateSuppliers();
        if (id && userSupplierId === id) modifyUserProfileSupplier(id, newData);
      }

      return response;
    } catch (err) {
      return err;
    }
  };

  const handleOpenDeleteModal = (rowId: string) => {
    dispatch(tableActions.setRowId(rowId));
    dispatch(tableActions.setIsSupplierDeleteModalOpen(true));
  };
  const handleCloseDelete = () => {
    dispatch(tableActions.setIsSupplierDeleteModalOpen(false));
    dispatch(tableActions.setRowId(''));
  };

  const handleCloseDeleteModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    dispatch(tableActions.setIsSupplierDeleteModalOpen(false));
    dispatch(tableActions.setRowId(''));
  };

  const handleCancelDelete = () => {
    dispatch(tableActions.setIsSupplierDeleteModalOpen(false));
    dispatch(tableActions.setRowId(''));
  };

  const onDelete = async () => {
    const id = rowId;
    const api = `${API_DATA}${API_DELETE_SUPPLIER}/${id}`;
    try {
      const { response } = await deleteData(api);
      mutateSuppliers();
      dispatch(tableActions.setRowId(''));
    } catch (err) {
      return err;
    }
    dispatch(tableActions.setIsSupplierDeleteModalOpen(false));
  };

  const handleOpenRowDetailModal = (rowId: string) => {
    dispatch(tableActions.setRowId(rowId));
    dispatch(tableActions.setIsItemModalOpen(true));
  };

  const openBulkCreateSuppliersModal = () => {
    dispatch(tableActions.setIsBulkCreateSuppliersModalOpen(true));
  };

  return {
    handleOpenCreateModal,
    handleCloseCreate,
    handleCloseCreateModal,
    handleCancelCreate,
    onSubmitCreateProp,
    handleOpenEditModal,
    handleCloseEdit,
    handleCloseEditModal,
    handleCancelEdit,
    onSubmitEditProp,
    handleOpenDeleteModal,
    handleCloseDelete,
    handleCloseDeleteModal,
    handleCancelDelete,
    onDelete,
    handleOpenRowDetailModal,
    openBulkCreateSuppliersModal,
  };
};
