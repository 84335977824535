import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField, ThemeProvider, createTheme } from '@mui/material';
import { API_DATA, serverPagination } from '../../utils/variables';
import { SelectOptionsProp } from '../types/Table.type';
import useSWR from 'swr';
import { useAxios } from '../../logics/useAxios';
import useTextToSpeech from '../../logics/useTextToSpeech';
import { useColors } from '../../logics/useColors';

type SelectSearchProps = {
  selectLabel: string;
  selectOptions?: OptionsProps[];
  selectValue: string;
  onSelect: Dispatch<SetStateAction<string>>;
  rounded?: boolean;
  color?: any;
  minWidth?: number;
  minHeight?: number;
  nonEditable?: boolean;
  async?: boolean;
  apiLink?: string;
  initSelectId?: string;
  initOpen?: boolean;
  initSelectOptions?: OptionsProps;
  defaultSelectOptions?: OptionsProps;
  disabled?: boolean;
};

export type OptionsProps = {
  id: string;
  label: string;
};

export const SelectSearch: React.FC<SelectSearchProps> = ({
  selectLabel,
  selectValue,
  onSelect,
  selectOptions,
  rounded = true,
  color,
  minWidth = 80,
  minHeight = 30,
  nonEditable = false,
  async = false,
  apiLink,
  initSelectId,
  initOpen = false,
  initSelectOptions,
  defaultSelectOptions,
  disabled = false,
}) => {
  const [open, setOpen] = useState(initOpen);
  const [options, setOptions] = useState<OptionsProps[]>([]);
  const { apiService } = useAxios();
  const [init, setInit] = useState(true);

  const { tableSettings } = useColors();

  const { handlePlay, handleStop } = useTextToSpeech();

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
  } = useSWR(async && open ? [API_DATA + apiLink, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const data = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  useEffect(() => {
    if (init && data) {
      if (defaultSelectOptions) {
        setOptions([defaultSelectOptions]);
      }
      setInit(false);
    }
  }, [data, defaultSelectOptions, init, initSelectId, onSelect, options]);

  useEffect(() => {
    if (!async && selectOptions) {
      setOptions(selectOptions);
    } else if (async && open && fetchedData && !isLoadingData) {
      if (initSelectOptions) {
        setOptions([initSelectOptions, ...data]);
      } else {
        setOptions(data);
      }
    }
  }, [async, data, fetchedData, initSelectId, initSelectOptions, isLoadingData, open, selectOptions]);

  const handleChangeSelect = (data: SelectOptionsProp | null) => {
    if (data) onSelect(data.id);
  };

  const selectTheme = createTheme({
    palette: {
      primary: {
        main: color ? color.buttonsColor : tableSettings.buttonsColor,
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: color ? color.buttonsColor : tableSettings.buttonsColor,
            '&.MuiInputLabel-shrink': {
              color: color ? color.selectLabelTextColor : tableSettings.selectLabelTextColor,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: rounded ? '30px' : '0px',
            height: minHeight,
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              color: color ? color.selectLabelTextColor : tableSettings.selectLabelTextColor,
              borderColor: color ? color.buttonsColorBorder : tableSettings.buttonsColorBorder,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              color: color ? color.selectLabelTextColor : tableSettings.selectLabelTextColor,
              borderColor: color ? color.buttonsColor : tableSettings.buttonsColor,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              color: color ? color.selectLabelTextColor : tableSettings.selectLabelTextColor,
              borderColor: color ? color.buttonsColor : tableSettings.buttonsColor,
            },
          },
        },
      },
    },
  });

  return (
    <div onMouseEnter={() => handlePlay(selectLabel)} onMouseLeave={() => handleStop()}>
      <ThemeProvider theme={selectTheme}>
        <Autocomplete
          disablePortal
          sx={{
            my: 2,
            '&:hover': {
              '& .MuiInputBase-root': {
                borderRadius: rounded ? 30 : 0,
                color: color ? color.hooverButtonsColor : 'white',
                backgroundColor: color ? color.hooverBackgroundColor : tableSettings.buttonsColor,
              },
            },
            '& fieldset ': {
              borderRadius: rounded ? 30 : 0,
              minWidth: minWidth,
            },
            '& .MuiInputBase-root': {
              height: minHeight,
              color: color ? color.buttonsColor : tableSettings.selectLabelTextColor,
            },
            '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
              backgroundColor: tableSettings.selectOptionsColor,
            },
          }}
          size="small"
          options={!nonEditable ? options : []}
          getOptionLabel={(item: SelectOptionsProp) => item.label}
          disableClearable={true}
          renderInput={(params) => (
            <TextField
              sx={{
                minWidth: minWidth,
                // backgroundColor: '#F1F1F1',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: color ? color.buttonsColorBorder : tableSettings.buttonsColorBorder,
                },
                borderRadius: rounded ? 30 : 0,
                borderColor: color ? color.buttonsColorBorder : tableSettings.buttonsColorBorder,
                // '&:hover .MuiOutlinedInput-notchedOutline': {
                //   borderColor: color ? color.buttonsColorBorder : buttonsColorBorder,
                // },
                '&:hover': {
                  '&& fieldset': {
                    border: '1px solid',
                    borderColor: color ? color.buttonsColorBorder : tableSettings.buttonsColorBorderHover,
                  },
                },
              }}
              {...params}
              label={selectLabel}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {async && isLoadingData ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          ListboxProps={{
            style: {
              maxHeight: '250px',
            },
          }}
          onChange={(e, data) => handleChangeSelect(data)}
          value={!init ? options.find((item) => item.id === selectValue) : options ? options[0] : undefined}
          defaultValue={defaultSelectOptions ? defaultSelectOptions : undefined}
          getOptionDisabled={(option) => nonEditable}
          open={async ? open : undefined}
          onOpen={
            async
              ? () => {
                  setOpen(true);
                }
              : undefined
          }
          onClose={
            async
              ? () => {
                  setOpen(false);
                }
              : undefined
          }
          loading={async && isLoadingData}
          disabled={disabled}
        />
      </ThemeProvider>
    </div>
  );
};
