import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterItemType } from '../../../types/Table.type';
import { usePermissions } from '../../../../logics/usePermissions';
import useTextToSpeech from '../../../../logics/useTextToSpeech';
import { useReduxDispatch, useReduxSelector } from '../../../../store/Store';
import { useAxios } from '../../../../logics/useAxios';
import { useColors } from '../../../../logics/useColors';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import useSWR from 'swr';
import { API_DATA, API_LIST_SITES_S, API_LIST_SUPPLIER_S, API_LIST_SUPPLIER_USERS_S, serverPagination } from '../../../../utils/variables';
import { useFilters } from '../../../../logics/useFilters';
import { tableActions } from '../../../../store/appSlices/TableSlice';
import { homeActions } from '../../../../store/appSlices/HomeSlice';
import { Refresh } from '@mui/icons-material';
import { useSites } from '../../../../logics/useSites';
import { TableMain } from '../../../UI/Table/TableMain';
import ConfirmModal from '../../../UI/ConfirmModal';
import SiteModal from '../../../UI/SiteModal';
import { useCreateSiteFields } from '../../../fields/site/createFields';
import { useDataColumnsSite_ } from '../../../fields/supplierPagesFields/site/dataColumns';

const Sites_: React.FC = () => {
  const { i18n, t } = useTranslation(['site']);
  const [Filters, setFilters] = useState<FilterItemType[]>([]);
  const isSiteCreateModalOpen = useReduxSelector((state) => state.table.isSiteCreateModalOpen);
  const isSiteEditModalOpen = useReduxSelector((state) => state.table.isSiteEditModalOpen);
  const isSiteDeleteModalOpen = useReduxSelector((state) => state.table.isSiteDeleteModalOpen);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const { isSupplierUser, userSupplierId, userSupplierName } = usePermissions();
  const [loadingSelectList, setLoadingSelectList] = useState(true);

  const { handlePlay, handleStop } = useTextToSpeech();
  const dispatch = useReduxDispatch();
  const { apiService } = useAxios();

  const {
    mixins: { toolbar },
  } = useTheme();

  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const { tableSettings } = useColors();

  const {
    data: fetchedDataSupplier,
    error: isLoadingDataErrorSupplier,
    isLoading: isLoadingDataSupplier,
    isValidating: isFetchingDataSupplier,
  } = useSWR(isSupplierUser ? [API_DATA + API_LIST_SUPPLIER_S, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const {
    data: fetchedDataUsers,
    error: isLoadingDataErrorUsers,
    isLoading: isLoadingDataUsers,
    isValidating: isFetchingDataUsers,
  } = useSWR(isSupplierUser ? [`${API_DATA}${API_LIST_SUPPLIER_USERS_S}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const users = useMemo(() => {
    if (fetchedDataUsers && !isLoadingDataUsers) {
      return fetchedDataUsers;
    } else return undefined;
  }, [fetchedDataUsers, isLoadingDataUsers]);

  const defaultvalues = useMemo(
    () =>
      fetchedDataSupplier && !isLoadingDataSupplier
        ? {
            supplierId: userSupplierId,
            supplierName: fetchedDataSupplier.name,
            supplierTaxNumber: fetchedDataSupplier.tax_number,
            supplierEuTaxNumber: fetchedDataSupplier.eu_tax_number,
            supplierAddress: fetchedDataSupplier.address,
            editable: true,
            language: i18n.language.toUpperCase() as 'EN' | 'HU',
          }
        : {},
    [fetchedDataSupplier, i18n.language, isLoadingDataSupplier, userSupplierId],
  );

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate: mutateSites,
  } = useSWR(isSupplierUser ? [`${API_DATA}${API_LIST_SITES_S}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const data = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  const { dataColumns } = useDataColumnsSite_();

  const { fetchedData_filtered } = useFilters({ Filters: Filters, Columns: dataColumns, fetchedData: data });

  const { fields } = useCreateSiteFields({ setLoadingSelectList, supplierId: userSupplierId });

  const {
    handleOpenCreateModal,
    handleCloseCreate,
    handleCloseCreateModal,
    handleCancelCreate,
    onSubmitCreateProp,
    handleOpenEditModal,
    handleCloseEdit,
    handleCloseEditModal,
    handleCancelEdit,
    onSubmitEditProp,
    handleOpenDeleteModal,
    handleCloseDelete,
    handleCloseDeleteModal,
    handleCancelDelete,
    onDelete,
  } = useSites({ mutateSites });

  useEffect(() => {
    dispatch(tableActions.setIsSiteCreateModalOpen(false));
    dispatch(tableActions.setIsSiteEditModalOpen(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(homeActions.setPageName('sites_'));
  }, [dispatch]);

  return isSupplierUser ? (
    <>
      <div className="bg-transparent h-full" style={{ marginLeft: isUserSignedIn && isSupplierUser ? 50 : '' }}>
        <div className="mt-2 flex gap-5 items-center">
          <Tooltip arrow title={t('refreshData', { ns: ['table'] })}>
            <IconButton onClick={() => mutateSites()} onMouseEnter={() => handlePlay(t('refreshData', { ns: ['table'] }))} onMouseLeave={() => handleStop()}>
              <Refresh sx={{ fontSize: 30, color: tableSettings.buttonsColor, '&:hover': { scale: '1.5' } }} />
            </IconButton>
          </Tooltip>

          {/* <SelectAutoWidth selectLabel={selectLabel!} selectValue={invoiceType} onSelect={setInvoiceType} selectOptions={selectOptions!} minHeight={tableVariables.selectHeight} /> */}

          {/* {userSupplierId && ( */}
          {/* <button
              className={`bg-transparent ${buttonsColorText} px-4 border ${tableVariables.buttonsHeight} align-middle ${buttonsColorBorder} ${tableVariables.buttonsColorHover} hover:text-white hover:border-transparent py-0 rounded-full`}
              onClick={handleOpenCreateModal}
            >
              <Tooltip title={t('createSite', { ns: ['site'] })}>
                <Typography sx={{ color: buttonsColorText }}>
                  <span>{t('createSite', { ns: ['site'] }).toUpperCase()}</span>
                </Typography>
              </Tooltip>
            </button> */}
          {/* )} */}

          {/* <TableFilter Columns={dataColumns} Filters={Filters} setFilters={setFilters} /> */}

          <div className="m-auto" style={{ color: tableSettings.titleTextColor }}>
            {t('siteTitle', { ns: ['site'] }).toUpperCase()}
          </div>
        </div>
        <>
          <div className={` flex justify-items-center h-[calc(100%-${appHeight}px)]`}>
            <div className={`w-[100%] absolute left-[50%] top-[calc(${appHeight}px)] -translate-x-1/2`}>
              <TableMain
                rows={fetchedData_filtered}
                isLoadingData={isLoadingData}
                isFetchingData={isFetchingData}
                dataColumns={dataColumns}
                selectId={'id'}
                handleOpenEditModal={handleOpenEditModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
                mutate={mutateSites}
                apiValue="sites_"
                translation={'site'}
              />
            </div>
          </div>

          {userSupplierId && isSiteCreateModalOpen && !loadingSelectList && (
            <SiteModal
              openModal={isSiteCreateModalOpen}
              handleCloseModal={handleCloseCreateModal}
              handleClose={handleCloseCreate}
              handleCancel={handleCancelCreate}
              onSubmitProp={onSubmitCreateProp}
              resetOnSubmit
              modalTitle={t('createSiteTitle', { ns: ['site'] })}
              contentText={t('', { ns: ['site'] })}
              submitText={t('create', { ns: ['site'] })}
              fields={fields}
              translation="site"
              defaultValues={defaultvalues}
              users={users}
              existingSites={data}
            />
          )}

          {/* {isSiteEditModalOpen && !loadingSelectList && (
            <SiteModal
              openModal={isSiteEditModalOpen}
              handleCloseModal={handleCloseEditModal}
              handleClose={handleCloseEdit}
              handleCancel={handleCancelEdit}
              onSubmitProp={onSubmitEditProp}
              resetOnSubmit
              modalTitle={t('editUserTitle', { ns: ['user'] })}
              contentText={t('userMinMaxLen', { ns: ['site'] })}
              submitText={t('update', { ns: ['user'] })}
              fields={editFieldsNoPasswords}
              translation="site"
              defaultValues={rowCustom}
              userId={userId}
              editUserProfile={rowCustom ? (rowCustom.type === '1' ? supportUserProfileFields : rowCustom.type === '2' ? supplierUserProfileFields : customerUserProfileFields) : undefined}
              edit
            />
          )} */}

          {isSiteDeleteModalOpen && (
            <ConfirmModal
              openModal={isSiteDeleteModalOpen}
              handleCloseModal={handleCloseDeleteModal}
              handleClose={handleCloseDelete}
              handleCancel={handleCancelDelete}
              onConfirmProp={onDelete}
              modalTitle={t('deleteSiteTitle', { ns: ['site'] })}
              contentText={t('toDeleteSite', { ns: ['site'] })}
              confirmText={t('delete', { ns: ['site'] })}
              translation="sites"
            />
          )}
        </>
      </div>
    </>
  ) : (
    <></>
  );
};

export default Sites_;
