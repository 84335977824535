import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColors } from '../../../../logics/useColors';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { useAxios } from '../../../../logics/useAxios';
import { usePermissions } from '../../../../logics/usePermissions';
import { useReduxDispatch, useReduxSelector } from '../../../../store/Store';
import { API_DATA, API_LIST_UNDELIVERED_EMAILS_S, serverPagination } from '../../../../utils/variables';
import { useFilters } from '../../../../logics/useFilters';
import { FilterItemType } from '../../../types/Table.type';
import { Refresh } from '@mui/icons-material';
import { TableMain } from '../../../UI/Table/TableMain';
import { ListModal } from '../../../UI/ListModal';
import { homeActions } from '../../../../store/appSlices/HomeSlice';
import useTextToSpeech from '../../../../logics/useTextToSpeech';
import { useDataColumnsUndeliveredEmail_ } from '../../../fields/supplierPagesFields/undeliveredEmail/dataColumns';
import useSWR from 'swr';

const UndeliveredEmails_: React.FC = () => {
  const { t } = useTranslation(['email']);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const [Filters, setFilters] = useState<FilterItemType[]>([]);
  const isEmailDataJsonListModalOpen = useReduxSelector((state) => state.table.isEmailDataJsonListModalOpen);

  const { isSupplierUser } = usePermissions();
  const { apiService } = useAxios();
  const dispatch = useReduxDispatch();

  const { handlePlay, handleStop } = useTextToSpeech();

  const {
    mixins: { toolbar },
  } = useTheme();
  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const { tableSettings } = useColors();

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate: mutateEmails,
  } = useSWR(isUserSignedIn && isSupplierUser ? [API_DATA + `${API_LIST_UNDELIVERED_EMAILS_S}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const data = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  const { dataColumns, dataColumnsData, dataColumnsUndelivered } = useDataColumnsUndeliveredEmail_();
  const { fetchedData_filtered } = useFilters({ Filters: Filters, Columns: dataColumns, fetchedData: data });

  useEffect(() => {
    dispatch(homeActions.setPageName('undeliveredemails_'));
  }, [dispatch]);

  return isSupplierUser ? (
    <div className="bg-transparent h-full" style={{ marginLeft: isUserSignedIn && isSupplierUser ? 50 : '' }}>
      <div className="mt-2 flex gap-5 items-center">
        <Tooltip arrow title={t('refreshData', { ns: ['table'] })}>
          <IconButton onClick={() => mutateEmails()} onMouseEnter={() => handlePlay(t('refreshData', { ns: ['table'] }))} onMouseLeave={() => handleStop()}>
            <Refresh sx={{ fontSize: 30, color: tableSettings.buttonsColor, '&:hover': { scale: '1.5' } }} />
          </IconButton>
        </Tooltip>

        {/* <TableFilter Columns={dataColumns} Filters={Filters} setFilters={setFilters} /> */}

        <div className="m-auto" style={{ color: tableSettings.titleTextColor }}>
          {t('undeliveredEmailsTitle', { ns: ['email'] })}
        </div>
      </div>

      <>
        <div className={` flex justify-items-center h-[calc(100%-${appHeight}px)]`}>
          <div className={`w-[100%] absolute left-[50%] top-[calc(${appHeight}px)] -translate-x-1/2`}>
            <TableMain
              rows={fetchedData_filtered}
              isLoadingData={isLoadingData}
              isFetchingData={isFetchingData}
              dataColumns={dataColumns}
              selectId={'id'}
              // handleOpenEditModal={handleOpenEditModal}
              // handleOpenDeleteModal={handleOpenDeleteModal}
              mutate={mutateEmails}
              apiValue="emails_"
              translation={'email'}
            />
          </div>
        </div>

        {/* {isEmailLoginJsonListModalOpen && <ListModal modal={8} jsonData={data} columns={dataColumnsLogin} title={t('listEmailLoginJsonTitle', { ns: ['email'] })} translation="email" />}  */}
        {isEmailDataJsonListModalOpen && <ListModal modal={9} jsonData={[data]} columns={dataColumnsData} title={t('listEmailDataJsonTitle', { ns: ['email'] })} translation="email" />}
      </>
    </div>
  ) : (
    <></>
  );
};

export default UndeliveredEmails_;
