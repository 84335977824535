import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterItemType } from '../../types/Table.type';
import { usePermissions } from '../../../logics/usePermissions';
import useTextToSpeech from '../../../logics/useTextToSpeech';
import { useReduxDispatch, useReduxSelector } from '../../../store/Store';
import { useAxios } from '../../../logics/useAxios';
import { useColors } from '../../../logics/useColors';
import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import useSWR from 'swr';
import { API_DATA, API_LIST_SITES, API_LIST_SUPPLIER, API_LIST_SUPPLIER_USERS, API_SELECTLIST_SUPPLIERS, serverPagination, tableVariables } from '../../../utils/variables';
import { useFilters } from '../../../logics/useFilters';
import { tableActions } from '../../../store/appSlices/TableSlice';
import { homeActions } from '../../../store/appSlices/HomeSlice';
import { SelectSearch } from '../../UI/SelectSearch';
import { Refresh } from '@mui/icons-material';
import { useSites } from '../../../logics/useSites';
import { TableMain } from '../../UI/Table/TableMain';
import ConfirmModal from '../../UI/ConfirmModal';
import { useDataColumnsSite } from '../../fields/site/dataColumns';
import { useCreateSiteFields } from '../../fields/site/createFields';
import SiteModal from '../../UI/SiteModal';

const Sites: React.FC = () => {
  const { i18n, t } = useTranslation(['site']);
  const [Filters, setFilters] = useState<FilterItemType[]>([]);
  const isSiteCreateModalOpen = useReduxSelector((state) => state.table.isSiteCreateModalOpen);
  const isSiteEditModalOpen = useReduxSelector((state) => state.table.isSiteEditModalOpen);
  const isSiteDeleteModalOpen = useReduxSelector((state) => state.table.isSiteDeleteModalOpen);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const { isSupportUser, isSupplierUser, userSupplierId, userSupplierName } = usePermissions();
  const [invoiceSupplier, setInvoiceSupplier] = useState<string>(userSupplierId ? userSupplierId : '0');
  const [loadingSelectList, setLoadingSelectList] = useState(true);

  const { handlePlay, handleStop } = useTextToSpeech();
  const dispatch = useReduxDispatch();
  const { apiService } = useAxios();

  const {
    mixins: { toolbar },
  } = useTheme();

  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const { tableSettings } = useColors();

  const {
    data: fetchedDataSupplier,
    error: isLoadingDataErrorSupplier,
    isLoading: isLoadingDataSupplier,
    isValidating: isFetchingDataSupplier,
  } = useSWR(invoiceSupplier && invoiceSupplier !== '0' ? [API_DATA + API_LIST_SUPPLIER + `/${invoiceSupplier}`, 'GET', ''] : null, ([url, method, body]) =>
    apiService({ url: url, method: method, data: body }),
  );

  const {
    data: fetchedDataUsers,
    error: isLoadingDataErrorUsers,
    isLoading: isLoadingDataUsers,
    isValidating: isFetchingDataUsers,
  } = useSWR(invoiceSupplier && invoiceSupplier !== '0' ? [`${API_DATA}${API_LIST_SUPPLIER_USERS}/${invoiceSupplier}`, 'GET', ''] : null, ([url, method, body]) =>
    apiService({ url: url, method: method, data: body }),
  );

  const {
    data: fetchedDataExistingSites,
    error: isLoadingDataErrorExistingSites,
    isLoading: isLoadingDataExistingSites,
    isValidating: isFetchingDataExistingSites,
  } = useSWR(invoiceSupplier && invoiceSupplier !== '0' ? [`${API_DATA}${API_LIST_SITES}/${invoiceSupplier}`, 'GET', ''] : null, ([url, method, body]) =>
    apiService({ url: url, method: method, data: body }),
  );

  const users = useMemo(() => {
    if (fetchedDataUsers && !isLoadingDataUsers) {
      return fetchedDataUsers;
    } else return undefined;
  }, [fetchedDataUsers, isLoadingDataUsers]);

  const existingSites = useMemo(() => {
    if (fetchedDataExistingSites && !isLoadingDataExistingSites) {
      return fetchedDataExistingSites;
    } else return undefined;
  }, [fetchedDataExistingSites, isLoadingDataExistingSites]);

  const defaultvalues = useMemo(
    () =>
      fetchedDataSupplier && !isLoadingDataSupplier
        ? {
            supplierId: userSupplierId,
            supplierName: fetchedDataSupplier.name,
            supplierTaxNumber: fetchedDataSupplier.tax_number,
            supplierEuTaxNumber: fetchedDataSupplier.eu_tax_number,
            supplierAddress: fetchedDataSupplier.address,
            editable: true,
            language: i18n.language.toUpperCase() as 'EN' | 'HU',
          }
        : {},
    [fetchedDataSupplier, i18n.language, isLoadingDataSupplier, userSupplierId],
  );

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate: mutateSites,
  } = useSWR(isSupportUser || isSupplierUser ? [`${API_DATA}${API_LIST_SITES}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const data = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  const { dataColumns } = useDataColumnsSite();

  const { fetchedData_filtered } = useFilters({ Filters: Filters, Columns: dataColumns, fetchedData: data });

  const { fields } = useCreateSiteFields({ setLoadingSelectList, supplierId: invoiceSupplier });

  const {
    handleOpenCreateModal,
    handleCloseCreate,
    handleCloseCreateModal,
    handleCancelCreate,
    onSubmitCreateProp,
    handleOpenEditModal,
    handleCloseEdit,
    handleCloseEditModal,
    handleCancelEdit,
    onSubmitEditProp,
    handleOpenDeleteModal,
    handleCloseDelete,
    handleCloseDeleteModal,
    handleCancelDelete,
    onDelete,
  } = useSites({ mutateSites });

  useEffect(() => {
    dispatch(tableActions.setIsSiteCreateModalOpen(false));
    dispatch(tableActions.setIsSiteEditModalOpen(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(homeActions.setPageName('site'));
  }, [dispatch]);

  return isSupportUser || isSupplierUser ? (
    <>
      <div className="bg-transparent h-full" style={{ marginLeft: isUserSignedIn && isSupportUser ? 50 : '' }}>
        <div className="mt-2 flex gap-5 items-center">
          <Tooltip arrow title={t('refreshData', { ns: ['table'] })}>
            <IconButton onClick={() => mutateSites()} onMouseEnter={() => handlePlay(t('refreshData', { ns: ['table'] }))} onMouseLeave={() => handleStop()}>
              <Refresh sx={{ fontSize: 30, color: tableSettings.buttonsColor, '&:hover': { scale: '1.5' } }} />
            </IconButton>
          </Tooltip>

          {/* <SelectAutoWidth selectLabel={selectLabel!} selectValue={invoiceType} onSelect={setInvoiceType} selectOptions={selectOptions!} minHeight={tableVariables.selectHeight} /> */}

          {isSupportUser && invoiceSupplier && (
            <SelectSearch
              selectLabel={t('supplier', { ns: ['invoice'] })}
              selectValue={invoiceSupplier}
              onSelect={setInvoiceSupplier}
              rounded={true}
              minWidth={250}
              minHeight={tableVariables.selectHeight}
              async
              apiLink={API_SELECTLIST_SUPPLIERS}
              initSelectOptions={{ id: '0', label: t('allSuppliers', { ns: ['invoice'] }) }}
              defaultSelectOptions={userSupplierId && userSupplierName ? { id: userSupplierId, label: userSupplierName } : { id: '0', label: t('allSuppliers', { ns: ['invoice'] }) }}
              nonEditable={isSupplierUser}
            />
          )}

          {invoiceSupplier !== '0' && (
            <button
              className={`bg-transparent ${tableSettings.buttonsColorText} px-4 border ${tableVariables.buttonsHeight} align-middle ${tableSettings.buttonsColorBorder} ${tableSettings.buttonsColorHover} hover:text-white hover:border-transparent py-0 rounded-full`}
              onClick={handleOpenCreateModal}
            >
              <Tooltip title={t('createSite', { ns: ['site'] })}>
                <Typography sx={{ color: tableSettings.buttonsColorText }}>
                  <span>{t('createSite', { ns: ['site'] }).toUpperCase()}</span>
                </Typography>
              </Tooltip>
            </button>
          )}

          {/* <TableFilter Columns={dataColumns} Filters={Filters} setFilters={setFilters} /> */}

          <div className="m-auto" style={{ color: tableSettings.titleTextColor }}>
            {t('siteTitle', { ns: ['site'] }).toUpperCase()}
          </div>
        </div>
        <>
          <div className={` flex justify-items-center h-[calc(100%-${appHeight}px)]`}>
            <div className={`w-[100%] absolute left-[50%] top-[calc(${appHeight}px)] -translate-x-1/2`}>
              <TableMain
                rows={fetchedData_filtered}
                isLoadingData={isLoadingData}
                isFetchingData={isFetchingData}
                dataColumns={dataColumns}
                selectId={'id'}
                handleOpenEditModal={handleOpenEditModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
                mutate={mutateSites}
                apiValue="sites"
                translation={'site'}
              />
            </div>
          </div>

          {invoiceSupplier && isSiteCreateModalOpen && !loadingSelectList && (
            <SiteModal
              openModal={isSiteCreateModalOpen}
              handleCloseModal={handleCloseCreateModal}
              handleClose={handleCloseCreate}
              handleCancel={handleCancelCreate}
              onSubmitProp={onSubmitCreateProp}
              resetOnSubmit
              modalTitle={t('createSiteTitle', { ns: ['site'] })}
              contentText={t('', { ns: ['site'] })}
              submitText={t('create', { ns: ['site'] })}
              fields={fields}
              translation="site"
              defaultValues={defaultvalues}
              users={users}
              existingSites={existingSites}
            />
          )}

          {/* {isSiteEditModalOpen && !loadingSelectList && (
            <SiteModal
              openModal={isSiteEditModalOpen}
              handleCloseModal={handleCloseEditModal}
              handleClose={handleCloseEdit}
              handleCancel={handleCancelEdit}
              onSubmitProp={onSubmitEditProp}
              resetOnSubmit
              modalTitle={t('editUserTitle', { ns: ['user'] })}
              contentText={t('userMinMaxLen', { ns: ['site'] })}
              submitText={t('update', { ns: ['user'] })}
              fields={editFieldsNoPasswords}
              translation="site"
              defaultValues={rowCustom}
              userId={userId}
              editUserProfile={rowCustom ? (rowCustom.type === '1' ? supportUserProfileFields : rowCustom.type === '2' ? supplierUserProfileFields : customerUserProfileFields) : undefined}
              edit
            />
          )} */}

          {isSiteDeleteModalOpen && (
            <ConfirmModal
              openModal={isSiteDeleteModalOpen}
              handleCloseModal={handleCloseDeleteModal}
              handleClose={handleCloseDelete}
              handleCancel={handleCancelDelete}
              onConfirmProp={onDelete}
              modalTitle={t('deleteSiteTitle', { ns: ['site'] })}
              contentText={t('toDeleteSite', { ns: ['site'] })}
              confirmText={t('delete', { ns: ['site'] })}
              translation="sites"
            />
          )}
        </>
      </div>
    </>
  ) : (
    <></>
  );
};

export default Sites;
