import React, { useMemo } from 'react';
import { useReduxSelector } from '../store/Store';
import { usePermissions } from './usePermissions';
import backgroundImage from '../static/background.jpg';
import { landingPageColors, pageColors, sideMenuColors, tableColors } from '../utils/colorVariables';

export const useColors = () => {
  const isLightMode = useReduxSelector((state) => state.home.lightMode);
  const isBackground = useReduxSelector((state) => state.home.pagesWithBackground);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const { isSupportUser, isSupplierUser, isCustomerUser } = usePermissions();

  const panelBackGroundColor = useMemo(
    () =>
      isSupportUser
        ? isLightMode
          ? landingPageColors.panelColors.supportPanel.backgroundColor.lightMode
          : landingPageColors.panelColors.supportPanel.backgroundColor.darkMode
        : isSupplierUser
          ? isLightMode
            ? landingPageColors.panelColors.supplierPanel.backgroundColor.lightMode
            : landingPageColors.panelColors.supplierPanel.backgroundColor.darkMode
          : isCustomerUser
            ? isLightMode
              ? landingPageColors.panelColors.customerPanel.backgroundColor.lightMode
              : landingPageColors.panelColors.customerPanel.backgroundColor.darkMode
            : undefined,
    [isCustomerUser, isLightMode, isSupplierUser, isSupportUser],
  );

  const panelColor = useMemo(
    () =>
      isSupportUser
        ? isLightMode
          ? landingPageColors.panelColors.supportPanel.color.lightMode
          : landingPageColors.panelColors.supportPanel.color.darkMode
        : isSupplierUser
          ? isLightMode
            ? landingPageColors.panelColors.supplierPanel.color.lightMode
            : landingPageColors.panelColors.supplierPanel.color.darkMode
          : isCustomerUser
            ? isLightMode
              ? landingPageColors.panelColors.customerPanel.color.lightMode
              : landingPageColors.panelColors.customerPanel.color.darkMode
            : landingPageColors.panelColors.supportPanel.color.lightMode,
    [isCustomerUser, isLightMode, isSupplierUser, isSupportUser],
  );

  const panelBorderColor = useMemo(
    () =>
      isSupportUser
        ? isLightMode
          ? landingPageColors.panelColors.supportPanel.borderColor.lightMode
          : landingPageColors.panelColors.supportPanel.borderColor.darkMode
        : isSupplierUser
          ? isLightMode
            ? landingPageColors.panelColors.supplierPanel.borderColor.lightMode
            : landingPageColors.panelColors.supplierPanel.borderColor.darkMode
          : isCustomerUser
            ? isLightMode
              ? landingPageColors.panelColors.customerPanel.borderColor.lightMode
              : landingPageColors.panelColors.customerPanel.borderColor.darkMode
            : landingPageColors.panelColors.supportPanel.borderColor.lightMode,
    [isCustomerUser, isLightMode, isSupplierUser, isSupportUser],
  );

  const backgroundColor_ = useMemo(
    () =>
      isUserSignedIn
        ? pageColors.isBackgroundImage
          ? isLightMode
            ? pageColors.backgroundColorWithImage.lightMode
            : pageColors.backgroundColorWithImage.darkMode
          : isLightMode
            ? pageColors.backgroundColor.lightMode
            : pageColors.backgroundColor.darkMode
        : landingPageColors.isBackgroundImage
          ? isLightMode
            ? landingPageColors.backgroundColorWithImage.lightMode
            : landingPageColors.backgroundColorWithImage.darkMode
          : isLightMode
            ? landingPageColors.backgroundColor.lightMode
            : landingPageColors.backgroundColor.darkMode,
    [isLightMode, isUserSignedIn],
  );

  const backgroundImage_ = useMemo(
    () =>
      !isUserSignedIn
        ? landingPageColors.isBackgroundImage
          ? `linear-gradient(${panelBackGroundColor}), url(${backgroundImage})`
          : undefined
        : pageColors.isBackgroundImage
          ? `linear-gradient(${panelBackGroundColor}), url(${backgroundImage})`
          : undefined,
    [isUserSignedIn, panelBackGroundColor],
  );

  const footerTextColor = useMemo(
    () =>
      !isUserSignedIn
        ? isLightMode
          ? landingPageColors.footerTextColor.lightMode
          : landingPageColors.footerTextColor.darkMode
        : isLightMode
          ? pageColors.footerTextColor.lightMode
          : pageColors.footerTextColor.darkMode,
    [isLightMode, isUserSignedIn],
  );

  const appMenuSettings = useMemo(() => {
    return {
      backGroundColor: !isUserSignedIn
        ? isLightMode
          ? landingPageColors.appMenuColors.backgroundColor.lightMode
          : landingPageColors.appMenuColors.backgroundColor.darkMode
        : isLightMode
          ? isSupportUser
            ? pageColors.appMenuColors.support.backgroundColor.lightMode
            : pageColors.appMenuColors.other.backgroundColor.lightMode
          : isSupportUser
            ? pageColors.appMenuColors.support.backgroundColor.darkMode
            : pageColors.appMenuColors.other.backgroundColor.darkMode,
      borderColor: !isUserSignedIn
        ? isLightMode
          ? landingPageColors.appMenuColors.borderColor.lightMode
          : landingPageColors.appMenuColors.borderColor.darkMode
        : isLightMode
          ? isSupportUser
            ? pageColors.appMenuColors.support.borderColor.lightMode
            : pageColors.appMenuColors.other.borderColor.lightMode
          : isSupportUser
            ? pageColors.appMenuColors.support.borderColor.darkMode
            : pageColors.appMenuColors.other.borderColor.darkMode,
      iconsColor: !isUserSignedIn
        ? isLightMode
          ? landingPageColors.appMenuColors.iconsColor.lightMode
          : landingPageColors.appMenuColors.iconsColor.darkMode
        : isLightMode
          ? isSupportUser
            ? pageColors.appMenuColors.support.iconsColor.lightMode
            : pageColors.appMenuColors.other.iconsColor.lightMode
          : isSupportUser
            ? pageColors.appMenuColors.support.iconsColor.darkMode
            : pageColors.appMenuColors.other.iconsColor.darkMode,
      selectedIconColor: isLightMode
        ? isSupportUser
          ? pageColors.appMenuColors.support.selectedIconColor.lightMode
          : pageColors.appMenuColors.other.selectedIconColor.lightMode
        : isSupportUser
          ? pageColors.appMenuColors.support.selectedIconColor.darkMode
          : pageColors.appMenuColors.other.selectedIconColor.darkMode,
      textColor: isLightMode
        ? isSupportUser
          ? pageColors.appMenuColors.support.textColor.lightMode
          : pageColors.appMenuColors.other.textColor.lightMode
        : isSupportUser
          ? pageColors.appMenuColors.support.textColor.darkMode
          : pageColors.appMenuColors.other.textColor.darkMode,
    };
  }, [isLightMode, isSupportUser, isUserSignedIn]);

  const landingPageSettings = useMemo(() => {
    return {
      titleTextColor: isLightMode ? landingPageColors.titleTextColor.lightMode : landingPageColors.titleTextColor.darkMode,
      panelBackGroundColor: panelBackGroundColor,
      panelColor: panelColor,
      panelCardBorderColor: panelBorderColor,
      loginPanelBackGroundColor: isLightMode ? landingPageColors.loginPanel.backgroundColor.lightMode : landingPageColors.loginPanel.backgroundColor.darkMode,
      loginPanelBorderColor: isLightMode ? landingPageColors.loginPanel.borderColor.lightMode : landingPageColors.loginPanel.borderColor.darkMode,
      loginPanelTextColor: isLightMode ? landingPageColors.loginPanel.textColor.lightMode : landingPageColors.loginPanel.textColor.darkMode,
    };
  }, [isLightMode, panelBackGroundColor, panelBorderColor, panelColor]);

  const sideMenuSettings = useMemo(() => {
    return {
      backGroundColor: isLightMode ? sideMenuColors.backgroundColor.lightMode : sideMenuColors.backgroundColor.darkMode,
      textColor: isLightMode ? sideMenuColors.textColor.lightMode : sideMenuColors.textColor.darkMode,
      textColorHover: isLightMode ? sideMenuColors.textColor.hover.lightMode : sideMenuColors.textColor.hover.darkMode,
      iconColor: isLightMode ? sideMenuColors.iconColor.lightMode : sideMenuColors.iconColor.darkMode,
      iconColorHover: isLightMode ? sideMenuColors.iconColor.hover.lightMode : sideMenuColors.iconColor.hover.darkMode,
      iconBackgroundColorHover: isLightMode ? sideMenuColors.iconColor.hover.backgroundColor.lightMode : sideMenuColors.iconColor.hover.backgroundColor.darkMode,
      selectedIconColor: isLightMode ? sideMenuColors.selectedIconColor.lightMode : sideMenuColors.selectedIconColor.darkMode,
    };
  }, [isLightMode]);

  const tableSettings = useMemo(() => {
    return {
      titleTextColor: isBackground ? tableColors.titleTextColorBackground : tableColors.titleTextColor,
      titleTextColorDark: tableColors.titleTextColorDark,
      textColor: isBackground ? tableColors.titleTextColorBackground : tableColors.titleTextColor,
      buttonsColor: tableColors.buttonsColor,
      buttonsColorBorder: isBackground ? (isLightMode ? tableColors.buttonsColorBorderBackground : tableColors.buttonsColorBorder) : tableColors.buttonsColorBorder,
      buttonsColorBorderHover: isLightMode ? tableColors.buttonsColor : tableColors.selectBorderColor,
      buttonsColorText: isBackground ? tableColors.titleTextColorDark : isLightMode ? tableColors.titleTextColor : tableColors.titleTextColorDark,
      buttonsColorHover: tableColors.buttonsColorHover,
      buttonsColorGroupHover: tableColors.buttonsColorGroupHover,
      groupHoverColorText: tableColors.groupHoverColorText,
      groupHoverColorTextError: tableColors.groupHoverColorTextError,
      buttonsColorTextError: tableColors.buttonsColorTextError,
      buttonsColorGroupHoverError: tableColors.buttonsColorGroupHoverError,
      selectOptionsColor: isBackground ? tableColors.selectOptionsColorBackGround : tableColors.selectOptionsColor,
      selectLabelTextColor: isLightMode ? (isBackground ? tableColors.selectLabelTextColorBackground : tableColors.selectLabelTextColor) : tableColors.titleTextColorDark,
      selectBorderColor: isLightMode ? (isBackground ? tableColors.selectBorderColorBackground : tableColors.selectBorderColor) : tableColors.selectBorderColor,
    };
  }, [isBackground, isLightMode]);

  return {
    panelBackGroundColor,
    panelBorderColor,
    backgroundColor_,
    backgroundImage_,
    footerTextColor,
    landingPageSettings,
    appMenuSettings,
    sideMenuSettings,
    tableSettings,
  };
};
