import React, { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataColumnsProp, Order } from '../../types/Table.type';
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Pagination } from './Pagination';
import { KeyedMutator } from 'swr';
import { TableToolbar } from './TableToolbar';
import { TableHeadComponent } from './TableHead';
import { Check, Remove } from '@mui/icons-material';
import { Spinner } from '../Spinner';
import { getComparator, stableSort } from '../../../utils/common';
import { RowMenuCustom } from './RowMenuCustom';
import { DELETABLE_TABLES, EDITABLE_TABLES, tableVariables } from '../../../utils/variables';
import { useReduxSelector } from '../../../store/Store';
import { useColors } from '../../../logics/useColors';
import { usePermissions } from '../../../logics/usePermissions';

type Data = {
  [key: string]: any;
};

type TableMainProps = {
  rows: any;
  isLoadingData: boolean;
  isFetchingData: boolean;
  dataColumns: DataColumnsProp[];
  selectId: string;
  handleOpenEditModal?: (row: any) => void;
  handleOpenDeleteModal?: (rowId: string) => void;
  mutate?: KeyedMutator<any>;
  apiValue?: string;
  tableTitle?: string;
  translation: string;
};

const editableTables = EDITABLE_TABLES;
const deletableTables = DELETABLE_TABLES;

export const TableMain: React.FC<TableMainProps> = ({
  rows,
  isLoadingData,
  isFetchingData,
  dataColumns,
  selectId,
  handleOpenEditModal,
  handleOpenDeleteModal,
  mutate,
  apiValue = '',
  tableTitle,
  translation,
}) => {
  const { t } = useTranslation(['table']);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState(selectId);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const rowsPerPageOptions = [5, 10, 25, { label: 'All', value: -1 }];
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { backgroundColor_, backgroundImage_ } = useColors();
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const { isSupportUser, isSupplierUser, isCustomerUser } = usePermissions();
  const { tableSettings } = useColors();

  const {
    mixins: { toolbar },
  } = useTheme();

  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const columns = useMemo<any[]>(() => {
    const headers = [] as any;
    dataColumns.forEach((item) => {
      if (!item.hidden) {
        headers.push({
          header: item.label,
        });
      }
    });
    return headers;
  }, [dataColumns]);

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: Data) => parseInt(n[selectId]));
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleClick = (event: MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedRows.slice(0, selectedIndex), selectedRows.slice(selectedIndex + 1));
    }
    setSelectedRows(newSelected);
  };

  const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => {
    return selectedRows.indexOf(id) !== -1;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const visibleRows = useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rows, rowsPerPage]);

  const hasMenuButton = useMemo(() => {
    if (handleOpenDeleteModal && handleOpenEditModal && (deletableTables.includes(apiValue) || editableTables.includes(apiValue))) return true;
    else return false;
  }, [apiValue, handleOpenDeleteModal, handleOpenEditModal]);

  const hasSelect = useMemo(() => {
    // if (hasMenuButton) return true;
    // else
    return false;
  }, []);

  return (
    <>
      <div className={`flex justify-items-center h-[calc(100%-${appHeight}px)]`}>
        <div
          className={`w-[100%] absolute left-[50%] top-[calc(${appHeight}px)] -translate-x-1/2`}
          style={{ marginLeft: isUserSignedIn && (isSupportUser || isSupplierUser || isCustomerUser) ? 50 : '' }}
        >
          {!isLoadingData && !isFetchingData && rows && rows.length > 0 ? (
            <>
              <Paper
                sx={{
                  width: isUserSignedIn && (isSupportUser || isSupplierUser || isCustomerUser) ? '95%' : '100%',
                  overflow: 'hidden',
                  mb: 2,
                  color: tableSettings.textColor,
                  backgroundColor: backgroundColor_,
                }}
              >
                {hasSelect && mutate && apiValue ? (
                  <TableToolbar
                    rows={rows}
                    visibleRows={visibleRows}
                    dataColumns={dataColumns}
                    columns={columns}
                    numSelected={selectedRows.length}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    mutate={mutate}
                    apiValue={apiValue}
                    tableTitle={tableTitle}
                    translation={translation}
                    canDelete={deletableTables.includes(apiValue)}
                  />
                ) : (
                  <Typography sx={{ mb: 3 }}></Typography>
                )}

                <TableContainer sx={{ maxWidth: '100%', maxHeight: '60vh', border: '1px solid grey', color: tableSettings.textColor, backgroundColor: backgroundColor_ }}>
                  <Table stickyHeader aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                    <TableHeadComponent
                      dataColumns={dataColumns}
                      numSelected={selectedRows.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                      hasMenu={hasMenuButton}
                      hasSelect={hasSelect}
                    />

                    <TableBody>
                      {visibleRows.map((row, index) => {
                        const isItemSelected = isSelected(parseInt(row[selectId] as string));
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row[selectId]} selected={isItemSelected}>
                            {hasMenuButton && (
                              <TableCell padding="none" style={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}>
                                <RowMenuCustom
                                  row={row}
                                  handleOpenEditModal={handleOpenEditModal!}
                                  handleOpenDeleteModal={handleOpenDeleteModal!}
                                  canDelete={deletableTables.includes(apiValue)}
                                  canEdit={editableTables.includes(apiValue)}
                                />
                              </TableCell>
                            )}

                            {hasSelect && (
                              <TableCell
                                padding="checkbox"
                                style={{ zIndex: '9', position: 'sticky', left: 0, color: tableSettings.textColor, background: backgroundColor_, borderRight: '1px solid grey' }}
                              >
                                <Checkbox
                                  sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                                  // color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                  onClick={(event) => handleClick(event, parseInt(row[selectId] as string))}
                                />
                              </TableCell>
                            )}

                            <TableCell sx={{ color: tableSettings.textColor }} component="th" id={labelId} scope="row" padding="none" align="right">
                              {row[selectId]}
                            </TableCell>
                            {dataColumns.map((item, index) => {
                              if (index > 0 && !item.hidden) {
                                const value = row[item.id as keyof typeof row];
                                return (
                                  <TableCell sx={{ color: tableSettings.textColor }} key={index} align={item.type === 'number' ? 'right' : 'left'}>
                                    {item.component ? (
                                      item.component(row)
                                    ) : item.type === 'boolean' ? (
                                      value ? (
                                        <Check color="success" />
                                      ) : (
                                        <Remove color="error" />
                                      )
                                    ) : (
                                      <span className="text-nowrap">{value}</span>
                                    )}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })}

                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 50 : 58) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination rows={rows} rowsPerPageOptions={rowsPerPageOptions} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} page={page} setPage={setPage} />
              </Paper>
            </>
          ) : !isLoadingData && !isFetchingData ? (
            <div className="flex justify-center m-8">
              <Typography sx={{ color: tableSettings.textColor, fontSize: tableVariables.noDataFontSize }}>{`${t('noData', { ns: ['table'] })} 😳`}</Typography>
            </div>
          ) : (
            <Spinner text={'Loading ...'} color="primary" />
          )}
        </div>
      </div>
    </>
  );
};
