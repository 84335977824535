import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { API_DATA, API_SELECTLIST_EMAILTEMPLATES, IS_CUSTOM_EMAIL_BOX, IS_USE_NAV_CONNECTOR, regExpVariables, serverPagination } from '../../../utils/variables';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../../../logics/useAxios';
import useSWR from 'swr';
import { useReduxSelector } from '../../../store/Store';
import { SelectOptionsEmailTemplateType } from '../../types/EmailTemplate.type';

type ModalProps = {
  setLoadingSelectList: Dispatch<SetStateAction<boolean>>;
};

export const useCreateSupplierFields = ({ setLoadingSelectList }: ModalProps) => {
  const { t } = useTranslation(['supplier']);
  const isSupplierCreateModalOpen = useReduxSelector((state) => state.table.isSupplierCreateModalOpen);
  const isSupplierEditModalOpen = useReduxSelector((state) => state.table.isSupplierEditModalOpen);
  const [selectOptionsEmailTemplatesHU, setSelectOptionsEmailTemplatesHU] = useState<SelectOptionsEmailTemplateType[]>([]);
  const [selectOptionsEmailTemplatesEN, setSelectOptionsEmailTemplatesEN] = useState<SelectOptionsEmailTemplateType[]>([]);

  const { apiService } = useAxios();
  const {
    data: fetchedDataEmailTemplates,
    error: isLoadingDataErrorEmailTemplates,
    isLoading: isLoadingDataEmailTemplates,
    isValidating: isFetchingDataEmailTemplates,
    mutate: mutateEmailTemplates,
  } = useSWR(isSupplierCreateModalOpen || isSupplierEditModalOpen ? [API_DATA + API_SELECTLIST_EMAILTEMPLATES, 'GET', ''] : null, ([url, method, body]) =>
    apiService({ url: url, method: method, data: body }),
  );

  const dataEmailTemplates = useMemo(() => {
    if (fetchedDataEmailTemplates) {
      if (!serverPagination) return fetchedDataEmailTemplates;
      return fetchedDataEmailTemplates.results;
    } else return undefined;
  }, [fetchedDataEmailTemplates]);

  useEffect(() => {
    if (dataEmailTemplates) {
      const dataEmailTemplatesHU = dataEmailTemplates.filter((x: any) => x.language === 'HU');
      const dataEmailTemplatesEN = dataEmailTemplates.filter((x: any) => x.language === 'EN');
      setSelectOptionsEmailTemplatesHU(dataEmailTemplatesHU ? dataEmailTemplatesHU : []);
      setSelectOptionsEmailTemplatesEN(dataEmailTemplatesEN ? dataEmailTemplatesEN : []);
      setLoadingSelectList(false);
    }
  }, [dataEmailTemplates, setLoadingSelectList]);

  const fields = [
    {
      label: t('baseSupplierData', { ns: ['supplier'] }),
      items: [
        { name: 'name', type: 'text', visible: true, nonEditable: true },
        { name: 'address', type: 'text', visible: true },
        { name: 'taxNumber', type: 'text', pattern: regExpVariables.taxNumberPattern, visible: true, nonEditable: true },
        { name: 'euTaxNumber', required: false, type: 'text', pattern: regExpVariables.euTaxNumberPattern, visible: true },
        { name: 'groupTaxNumber', required: false, type: 'text', visible: true },
        { name: 'email', type: 'text', pattern: regExpVariables.emailPattern, visible: true },
      ],
    },
  ];

  const configEmailFields = [
    {
      label: t('supplierConfigEmailData', { ns: ['supplier'] }),
      items: [
        {
          name: 'isUseConfigEmail',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: '',
        },
        {
          name: 'configEmail',
          type: 'text',
          pattern: regExpVariables.emailPattern,
          required: false,
          visible: true,
          group: 'isUseConfigEmail',
          warning: true,
          warningText: 'This account must be exist in email server !',
          clone: 'configSender',
          helperText: t('helperTextConfigEmail', { ns: ['supplier'] }),
        },
        {
          name: 'configPassword',
          type: 'password',
          required: false,
          visible: true,
          group: 'isUseConfigEmail',
          helperText: t('helperTextConfigPassword', { ns: ['supplier'] }),
        },
        {
          name: 'configSender',
          type: 'text',
          required: false,
          visible: true,
          group: 'isUseConfigEmail',
          helperText: t('helperTextConfigSender', { ns: ['supplier'] }),
        },
        {
          name: 'isSendUndeliveredInfoEmail',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: '',
        },
        {
          name: 'infoUndeliveredEmail',
          type: 'text',
          pattern: regExpVariables.emailPattern,
          required: false,
          visible: true,
          group: 'isSendUndeliveredInfoEmail',
          helperText: t('helperTextInfoUndeliveredEmail', { ns: ['supplier'] }),
        },
        {
          name: 'isUseInvoiceStore',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: '',
        },
        {
          name: 'registeredInvoiceStoreSenderEmail',
          type: 'text',
          pattern: regExpVariables.emailPattern,
          required: false,
          visible: true,
          group: 'isUseInvoiceStore',
          helperText: t('helperTextRegisteredInvoiceStoreSenderEmail', { ns: ['supplier'] }),
        },
        {
          name: 'isSendInvoiceStoreInfoEmail',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: 'isUseInvoiceStore',
        },
        {
          name: 'infoInvoiceStoreEmail',
          type: 'text',
          pattern: regExpVariables.emailPattern,
          required: false,
          visible: true,
          group: 'isSendInvoiceStoreInfoEmail',
          helperText: t('helperTextInfoInvoiceStoreEmail', { ns: ['supplier'] }),
        },
        {
          name: 'isUseEmailNotReceiving',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: '',
        },
        {
          name: 'sendDaysFromEmailNotReceiving',
          type: 'string',
          pattern: regExpVariables.numberNoFirstZeroPattern,
          required: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          helperText: t('helperTextSendFromNotReceiving', { ns: ['supplier'] }),
        },
        {
          name: 'maxNumberOfSentEmailNotReceiving',
          type: 'string',
          pattern: regExpVariables.numberNoFirstZeroPattern,
          required: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          helperText: t('helperTextMaxNumberOfSentEmailNotReceiving', { ns: ['supplier'] }),
        },
        {
          name: 'emailSendingDays',
          type: 'lineText',
          required: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
        },
        {
          name: 'isMonday',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          inline: true,
          labelPlacement: 'top',
        },
        {
          name: 'isTuesday',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          inline: true,
          labelPlacement: 'top',
        },
        {
          name: 'isWednesday',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          inline: true,
          labelPlacement: 'top',
        },
        {
          name: 'isThursday',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          inline: true,
          labelPlacement: 'top',
        },
        {
          name: 'isFriday',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          inline: true,
          labelPlacement: 'top',
        },
        {
          name: 'isSaturday',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          inline: true,
          labelPlacement: 'top',
        },
        {
          name: 'isSunday',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
          inline: true,
          labelPlacement: 'top',
        },
        {
          name: 'emailSendingDays_',
          type: 'lineText',
          required: false,
          visible: true,
          group: 'isUseEmailNotReceiving',
        },
        {
          name: 'isUseNavConnector',
          type: 'boolean',
          required: false,
          default: true,
          visible: true,
          group: '',
        },
        {
          name: 'navUserLogin',
          type: 'text',
          required: false,
          default: 'fuwagirnpejrg5t',
          visible: true,
          group: 'isUseNavConnector',
        },
        {
          name: 'navUserPassword',
          type: 'password',
          required: false,
          default: 'Nefelejtsdel15',
          visible: true,
          group: 'isUseNavConnector',
        },
        {
          name: 'navUserTaxNumber',
          type: 'text',
          required: false,
          default: '24091271',
          visible: true,
          group: 'isUseNavConnector',
        },
        {
          name: 'navUserSignatureKey',
          type: 'text',
          required: false,
          default: '83-ba1a-4998ea16697623CQUKFK80MD',
          visible: true,
          group: 'isUseNavConnector',
        },
        {
          name: 'navUserExchangeKey',
          type: 'text',
          required: false,
          default: '199b23CQUKFL15ME',
          visible: true,
          group: 'isUseNavConnector',
        },
        {
          name: 'isUseConfigTemplate',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: '',
        },
        {
          name: 'defaultConfigTemplateHu',
          type: 'select',
          selectOptions: selectOptionsEmailTemplatesHU,
          selectSearch: false,
          required: false,
          // default: 'email_template_hu',
          visible: true,
          group: 'isUseConfigTemplate',
        },
        {
          name: 'defaultConfigTemplateEn',
          type: 'select',
          selectOptions: selectOptionsEmailTemplatesEN,
          selectSearch: false,
          required: false,
          // default: 'email_template_en',
          visible: true,
          group: 'isUseConfigTemplate',
        },
        {
          name: 'isUseCustomLogo',
          type: 'boolean',
          required: false,
          default: false,
          visible: true,
          group: '',
        },
      ],
    },
  ];

  const booleanFields = configEmailFields[0].items.filter((item) => item.type === 'boolean').map(({ name }) => name);
  const navConnectorFields = ['isUseNavConnector', 'navUserLogin', 'navUserPassword', 'navUserTaxNumber', 'navUserSignatureKey', 'navUserExchangeKey'];
  const customEmailBoxFields = ['isUseConfigEmail', 'configEmail', 'configPassword', 'configSender'];

  if (!IS_USE_NAV_CONNECTOR) {
    const newItems = [] as any;
    configEmailFields[0].items.forEach((item) => {
      if (!navConnectorFields.includes(item.name)) newItems.push(item);
    });
    configEmailFields[0].items = newItems;
  }

  if (!IS_CUSTOM_EMAIL_BOX) {
    const newItems_ = [] as any;
    configEmailFields[0].items.forEach((item) => {
      if (!customEmailBoxFields.includes(item.name)) newItems_.push(item);
    });
    configEmailFields[0].items = newItems_;
  }

  return { fields, configEmailFields, dataEmailTemplates, selectOptionsEmailTemplatesHU, selectOptionsEmailTemplatesEN, booleanFields, mutateEmailTemplates };
};
