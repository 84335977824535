import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterItemType } from '../../../types/Table.type';
import { usePermissions } from '../../../../logics/usePermissions';
import useTextToSpeech from '../../../../logics/useTextToSpeech';
import { useReduxDispatch, useReduxSelector } from '../../../../store/Store';
import { useAxios } from '../../../../logics/useAxios';
import { useColors } from '../../../../logics/useColors';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import useSWR from 'swr';
import { API_DATA, API_LIST_INVOICESTORES_S, serverPagination } from '../../../../utils/variables';
import { useFilters } from '../../../../logics/useFilters';
import { homeActions } from '../../../../store/appSlices/HomeSlice';
import { Refresh } from '@mui/icons-material';
import { TableMain } from '../../../UI/Table/TableMain';
import { useInvoiceStore } from '../../../../logics/useInvoiceStore';
import InvoiceStoreEmailDataViewModal from '../../../UI/InvoiceStoreEmailDataViewModal';
import InvoiceStoreEmailAttachmentsViewModal from '../../../UI/InvoiceStoreEmailAttachmentsViewModal';
import { useDataColumnsInvoiceStore_ } from '../../../fields/supplierPagesFields/invoiceStore/dataColumns';

const InvoiceStores_: React.FC = () => {
  const { t } = useTranslation(['invoiceStore']);
  const [Filters, setFilters] = useState<FilterItemType[]>([]);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const { isSupplierUser, userSupplierId, userSupplierName } = usePermissions();
  const isInvoiceStoreEmailDataViewModalOpen = useReduxSelector((state) => state.table.isInvoiceStoreEmailDataViewModalOpen);
  const isInvoiceStoreEmailAttachmentsViewModalOpen = useReduxSelector((state) => state.table.isInvoiceStoreEmailAttachmentsViewModalOpen);
  const { handlePlay, handleStop } = useTextToSpeech();
  const dispatch = useReduxDispatch();
  const { apiService } = useAxios();

  const {
    mixins: { toolbar },
  } = useTheme();

  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const { tableSettings } = useColors();

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate: mutateInvoiceStore,
  } = useSWR(isSupplierUser ? [`${API_DATA}${API_LIST_INVOICESTORES_S}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const data = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  const { dataColumns } = useDataColumnsInvoiceStore_();

  const { fetchedData_filtered } = useFilters({ Filters: Filters, Columns: dataColumns, fetchedData: data });
  const {
    handleOpenCreateModal,
    handleCloseCreate,
    handleCloseCreateModal,
    handleCancelCreate,
    onSubmitCreateProp,
    handleOpenEditModal,
    handleCloseEdit,
    handleCloseEditModal,
    handleCancelEdit,
    onSubmitEditProp,
    handleOpenDeleteModal,
    handleCloseDelete,
    handleCloseDeleteModal,
    handleCancelDelete,
    onDelete,
  } = useInvoiceStore({ mutateInvoiceStore });

  useEffect(() => {
    dispatch(homeActions.setPageName('invoicestore_'));
  }, [dispatch]);

  return isSupplierUser ? (
    <>
      <div className="bg-transparent h-full" style={{ marginLeft: isUserSignedIn && isSupplierUser ? 50 : '' }}>
        <div className="mt-2 flex gap-5 items-center">
          <Tooltip arrow title={t('refreshData', { ns: ['table'] })}>
            <IconButton onClick={() => mutateInvoiceStore()} onMouseEnter={() => handlePlay(t('refreshData', { ns: ['table'] }))} onMouseLeave={() => handleStop()}>
              <Refresh sx={{ fontSize: 30, color: tableSettings.buttonsColor, '&:hover': { scale: '1.5' } }} />
            </IconButton>
          </Tooltip>

          {/* <SelectAutoWidth selectLabel={selectLabel!} selectValue={invoiceType} onSelect={setInvoiceType} selectOptions={selectOptions!} minHeight={tableVariables.selectHeight} />

            {isSupportUser && invoiceSupplier && (
              <SelectSearch
                selectLabel={t('supplier', { ns: ['invoice'] })}
                selectValue={invoiceSupplier}
                onSelect={setInvoiceSupplier}
                rounded={true}
                minWidth={250}
                minHeight={tableVariables.selectHeight}
                async
                apiLink={API_SELECTLIST_SUPPLIERS}
                initSelectOptions={{ id: '0', label: t('allSuppliers', { ns: ['invoice'] }) }}
                defaultSelectOptions={userSupplierId && userSupplierName ? { id: userSupplierId, label: userSupplierName } : { id: '0', label: t('allSuppliers', { ns: ['invoice'] }) }}
                nonEditable={isSupplierUser}
              />
            )}

            {(isSupportUser || isSupplierUser) && invoiceSupplier && (
              <SelectSearch
                selectLabel={t('supplierPartner', { ns: ['invoice'] })}
                selectValue={invoicePartner}
                onSelect={setInvoicePartner}
                rounded={true}
                minWidth={250}
                minHeight={tableVariables.selectHeight}
                async
                apiLink={`${API_SELECTLIST_SUPPLIER_PARTNER}/${invoiceSupplier}`}
                initSelectOptions={{ id: '0', label: t('allPartners', { ns: ['invoice'] }) }}
                defaultSelectOptions={{ id: '0', label: t('allPartners', { ns: ['invoice'] }) }}
              />
            )} */}

          {/* <TableFilter Columns={dataColumns} Filters={Filters} setFilters={setFilters} /> */}

          <div className="m-auto" style={{ color: tableSettings.titleTextColor }}>
            {t('invoiceStoreTitle', { ns: ['invoiceStore'] })}
          </div>
        </div>
        <>
          <div className={` flex justify-items-center h-[calc(100%-${appHeight}px)]`}>
            <div className={`w-[100%] absolute left-[50%] top-[calc(${appHeight}px)] -translate-x-1/2`}>
              <TableMain
                rows={fetchedData_filtered}
                isLoadingData={isLoadingData}
                isFetchingData={isFetchingData}
                dataColumns={dataColumns}
                selectId={'id'}
                handleOpenEditModal={handleOpenEditModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
                mutate={mutateInvoiceStore}
                apiValue="invoicestore_"
                translation={'invoiceStore'}
              />
            </div>
          </div>

          {isInvoiceStoreEmailDataViewModalOpen && <InvoiceStoreEmailDataViewModal />}
          {isInvoiceStoreEmailAttachmentsViewModalOpen && <InvoiceStoreEmailAttachmentsViewModal />}
        </>
      </div>
    </>
  ) : (
    <></>
  );
};

export default InvoiceStores_;
