import React, { useMemo, useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
  DropAnimation,
  defaultDropAnimation,
  useDroppable,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates, arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import TaskItem from './TaskItem';
import Typography from '@mui/material/Typography';
import SortableTaskItem from './SortableTaskItem';
import { Box } from '@mui/material';

export type Task = {
  id: string;
  name: string;
  email: string;
  role: string;
  status: any;
};

export type TaskFields = {
  id: string;
  label: string;
  component: (x: Task) => JSX.Element;
}[];

type BoardSections = {
  [name: string]: Task[];
};

type BoardSectionListProps = {
  BOARD_SECTIONS: { [key: string]: string };
  INITIAL_TASKS: Task[];
  TASK_FIELDS: TaskFields;
};

type BoardSectionProps = {
  id: string;
  title: string;
  tasks: Task[];
  TASK_FIELDS: TaskFields;
};

const BoardSection: React.FC<BoardSectionProps> = ({ id, title, tasks, TASK_FIELDS }) => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <Box sx={{ backgroundColor: '#eee', padding: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <SortableContext id={id} items={tasks} strategy={verticalListSortingStrategy}>
        <div ref={setNodeRef}>
          {tasks.map((task) => (
            <Box key={task.id} sx={{ mb: 2 }}>
              <SortableTaskItem id={task.id}>
                <TaskItem task={task} TASK_FIELDS={TASK_FIELDS} />
              </SortableTaskItem>
            </Box>
          ))}
        </div>
      </SortableContext>
    </Box>
  );
};

const BoardSectionList: React.FC<BoardSectionListProps> = ({ BOARD_SECTIONS, INITIAL_TASKS, TASK_FIELDS }) => {
  const getTaskById = (tasks: Task[], id: string) => {
    return tasks.find((task) => task.id === id);
  };

  const initializeBoard = (tasks: Task[]) => {
    const boardSections: BoardSections = {};
    Object.keys(BOARD_SECTIONS).forEach((boardSectionKey) => {
      boardSections[boardSectionKey] = tasks.filter((tasks) => tasks.status === boardSectionKey);
    });
    return boardSections;
  };

  const findBoardSectionContainer = (boardSections: BoardSections, id: string) => {
    if (id in boardSections) return id;
    const container = Object.keys(boardSections).find((key) => boardSections[key].find((item) => item.id === id));
    return container;
  };

  const initialBoardSections = initializeBoard(INITIAL_TASKS);
  const [boardSections, setBoardSections] = useState<BoardSections>(initialBoardSections);
  const [activeTaskId, setActiveTaskId] = useState<null | string>(null);

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }));

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveTaskId(active.id as string);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const activeContainer = findBoardSectionContainer(boardSections, active.id as string);
    const overContainer = findBoardSectionContainer(boardSections, over?.id as string);
    if (!activeContainer || !overContainer || activeContainer === overContainer) return;

    setBoardSections((boardSection) => {
      const activeItems = boardSection[activeContainer];
      const overItems = boardSection[overContainer];

      const activeIndex = activeItems.findIndex((item) => item.id === active.id);
      const overIndex = overItems.findIndex((item) => item.id !== over?.id);

      return {
        ...boardSection,
        [activeContainer]: [...boardSection[activeContainer].filter((item) => item.id !== active.id)],
        [overContainer]: [
          ...boardSection[overContainer].slice(0, overIndex),
          boardSections[activeContainer][activeIndex],
          ...boardSection[overContainer].slice(overIndex, boardSection[overContainer].length),
        ],
      };
    });
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const activeContainer = findBoardSectionContainer(boardSections, active.id as string);
    const overContainer = findBoardSectionContainer(boardSections, over?.id as string);
    if (!activeContainer || !overContainer || activeContainer !== overContainer) return;

    const activeIndex = boardSections[activeContainer].findIndex((task) => task.id === active.id);
    const overIndex = boardSections[overContainer].findIndex((task) => task.id === over?.id);

    if (activeIndex !== overIndex) {
      setBoardSections((boardSection) => ({
        ...boardSection,
        [overContainer]: arrayMove(boardSection[overContainer], activeIndex, overIndex),
      }));
    }
    setActiveTaskId(null);
  };

  const numberOfColumns = useMemo(() => Object.entries(BOARD_SECTIONS).length, [BOARD_SECTIONS]);
  const task = useMemo(() => (activeTaskId ? getTaskById(INITIAL_TASKS, activeTaskId) : null), [activeTaskId, INITIAL_TASKS]);
  const dropAnimation: DropAnimation = { ...defaultDropAnimation };

  return (
    <Container sx={{ backgroundColor: '#33C6E7', marginY: 5, paddingY: 5 }}>
      <DndContext sensors={sensors} collisionDetection={closestCorners} onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnd={handleDragEnd}>
        <Grid container spacing={4}>
          {Object.keys(boardSections).map((boardSectionKey) => {
            return (
              <Grid item xs={12 / numberOfColumns} key={boardSectionKey}>
                <BoardSection id={boardSectionKey} title={BOARD_SECTIONS[boardSectionKey]} tasks={boardSections[boardSectionKey]} TASK_FIELDS={TASK_FIELDS} />
              </Grid>
            );
          })}
          <DragOverlay dropAnimation={dropAnimation}>{task ? <TaskItem task={task} TASK_FIELDS={TASK_FIELDS} /> : null}</DragOverlay>
        </Grid>
      </DndContext>
    </Container>
  );
};

export default BoardSectionList;
