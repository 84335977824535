import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch, useReduxSelector } from '../../../../store/Store';
import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { usePermissions } from '../../../../logics/usePermissions';
import { useAxios } from '../../../../logics/useAxios';
import { API_DATA, API_LIST_USERS_S, authVariables, tableVariables } from '../../../../utils/variables';
import { SelectAutoWidth } from '../../../UI/Select';
import { useTable } from '../../../../logics/useTable';
import { Refresh } from '@mui/icons-material';
import { useUser } from '../../../../logics/useUser';
import ConfirmModal from '../../../UI/ConfirmModal';
import { useFilters } from '../../../../logics/useFilters';
import { FilterItemType } from '../../../types/Table.type';
import { ItemModal } from '../../../UI/ItemModal';
import { TableMain } from '../../../UI/Table/TableMain';
import { useColors } from '../../../../logics/useColors';
import { homeActions } from '../../../../store/appSlices/HomeSlice';
import useTextToSpeech from '../../../../logics/useTextToSpeech';
import { useDataColumnsUser_ } from '../../../fields/supplierPagesFields/user/dataColumns';
import useSWR from 'swr';
import { useCreateUserFields_ } from '../../../fields/supplierPagesFields/user/createFields';
import InputModalUser_ from '../../../UI/InputModalUser_';
import { tableActions } from '../../../../store/appSlices/TableSlice';

const Users_: React.FC = () => {
  const { t } = useTranslation(['user']);
  const selectLabel = t('userType', { ns: ['user'] });
  const selectLabelProfile = t('withProfile', { ns: ['user'] });
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const isItemModalOpen = useReduxSelector((state) => state.table.isItemModalOpen);
  const isUserCreateModalOpen = useReduxSelector((state) => state.table.isUserCreateModalOpen);
  const isUserEditModalOpen = useReduxSelector((state) => state.table.isUserEditModalOpen);
  const isUserDeleteModalOpen = useReduxSelector((state) => state.table.isUserDeleteModalOpen);
  const isUpdateUserProfile = useReduxSelector((state) => state.table.isUpdateUserProfile);
  const rowCustom = useReduxSelector((state) => state.table.rowCustom) as any;
  const [withProfile, setWithProfile] = useState('true');
  const [Filters, setFilters] = useState<FilterItemType[]>([]);
  const { isSupplierUser, userId, userSupplierId, userProfile } = usePermissions();

  const dispatch = useReduxDispatch();
  const { apiService } = useAxios();

  const { handlePlay, handleStop } = useTextToSpeech();

  const {
    mixins: { toolbar },
  } = useTheme();
  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const { tableSettings } = useColors();

  const defaultvalues = useMemo(
    () =>
      userSupplierId
        ? {
            supplierId: userSupplierId,
          }
        : {},
    [userSupplierId],
  );

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate,
  } = useSWR(isUserSignedIn && isSupplierUser ? [API_DATA + `${API_LIST_USERS_S}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const { selectOptions, dataColumns, dataColumnsProfile } = useDataColumnsUser_();

  const { openDeleteSelectedConfirm, openBulkCreateModal } = useTable({ apiValue: 'users' });

  const { fetchedData_filtered } = useFilters({ Filters: Filters, Columns: dataColumns, fetchedData: fetchedData });

  const {
    handleOpenCreateModal,
    handleCloseCreate,
    handleCloseCreateModal,
    handleCancelCreate,
    onSubmitCreateProp,
    handleOpenEditModal,
    handleCloseEdit,
    handleCloseEditModal,
    handleCancelEdit,
    onSubmitEditProp,
    handleOpenDeleteModal,
    handleCloseDelete,
    handleCloseDeleteModal,
    handleCancelDelete,
    onDelete,
    handleOpenRowDetailModal,
  } = useUser({
    mutate,
  });

  const { fields: createFields, supplierUserProfileFields } = useCreateUserFields_();

  const editFieldsNoPasswords = createFields.map((field) => ({ ...field, items: field.items.filter((el) => !['password', 'passwordRe'].includes(el.name)) }));

  useEffect(() => {
    if (isUpdateUserProfile) {
      mutate();
      dispatch(tableActions.setIsUpdateUserProfile(false));
    }
  }, [dispatch, isUpdateUserProfile, mutate]);

  const dataColumns_ = useMemo(() => {
    if (withProfile === 'true' && dataColumnsProfile) return [...dataColumns, ...dataColumnsProfile];
    else return dataColumns;
  }, [dataColumns, dataColumnsProfile, withProfile]);

  const isManager = useMemo(() => {
    if (userProfile.role === '0') return true;
    else return false;
  }, [userProfile]);

  useEffect(() => {
    dispatch(homeActions.setPageName('users_'));
  }, [dispatch]);

  return isUserSignedIn && isSupplierUser ? (
    <>
      <div className="bg-transparent h-full" style={{ marginLeft: isUserSignedIn && isSupplierUser ? 50 : '' }}>
        <div className="mt-2 flex gap-5 items-center">
          <Tooltip arrow title={t('refreshData', { ns: ['table'] })}>
            <IconButton onClick={() => mutate()} onMouseEnter={() => handlePlay(t('refreshData', { ns: ['table'] }))} onMouseLeave={() => handleStop()}>
              <Refresh sx={{ fontSize: 30, color: tableSettings.buttonsColor, '&:hover': { scale: '1.5' } }} />
            </IconButton>
          </Tooltip>

          {isManager && (
            <button
              className={`bg-transparent ${tableSettings.buttonsColorText} px-4 border ${tableVariables.buttonsHeight} align-middle ${tableSettings.buttonsColorBorder} ${tableSettings.buttonsColorHover} hover:text-white hover:border-transparent py-0 rounded-full`}
              onClick={handleOpenCreateModal}
              onMouseEnter={() => handlePlay(t('createUser', { ns: ['user'] }))}
              onMouseLeave={() => handleStop()}
            >
              <Tooltip title={t('createUser', { ns: ['user'] })}>
                <Typography sx={{ color: tableSettings.buttonsColorText }}>
                  <span>{t('createUser', { ns: ['user'] }).toUpperCase()}</span>
                </Typography>
              </Tooltip>
            </button>
          )}

          <SelectAutoWidth
            selectLabel={selectLabelProfile!}
            selectValue={withProfile}
            onSelect={setWithProfile}
            selectOptions={[
              { id: 'true', label: t('trueProfile', { ns: ['user'] }) },
              { id: 'false', label: t('falseProfile', { ns: ['user'] }) },
            ]}
            minHeight={tableVariables.selectHeight}
          />
          {/* <TableFilter Columns={dataColumns_} Filters={Filters} setFilters={setFilters} /> */}

          <div className="m-auto" style={{ color: tableSettings.titleTextColor }}>
            {t('usersTitle', { ns: ['user'] })}
          </div>
        </div>

        <div className={` flex justify-items-center h-[calc(100%-${appHeight}px)]`}>
          <div className={`w-[100%] absolute left-[50%] top-[calc(${appHeight}px)] -translate-x-1/2`}>
            <TableMain
              rows={fetchedData_filtered}
              isLoadingData={isLoadingData}
              isFetchingData={isFetchingData}
              dataColumns={dataColumns_}
              selectId={'id'}
              handleOpenEditModal={handleOpenEditModal}
              handleOpenDeleteModal={handleOpenDeleteModal}
              mutate={mutate}
              apiValue="users_"
              translation="user"
            />
          </div>
        </div>

        {isUserCreateModalOpen && createFields && supplierUserProfileFields && (
          <InputModalUser_
            openModal={isUserCreateModalOpen}
            handleCloseModal={handleCloseCreateModal}
            handleClose={handleCloseCreate}
            handleCancel={handleCancelCreate}
            onSubmitProp={onSubmitCreateProp}
            resetOnSubmit
            modalTitle={t('createUserTitle', { ns: ['user'] })}
            contentText={
              t('userMinMaxLen', { min: authVariables.userNameMinCharacter, max: authVariables.userNameMaxCharacter, ns: ['user'] }) +
              ' ' +
              t('toCreateUser', { minChar: authVariables.userPasswordMinCharacter, minDigit: authVariables.userPasswordMinDigit, minUpper: authVariables.userPasswordMinUpper, ns: ['user'] })
            }
            submitText={t('create', { ns: ['user'] })}
            fields={createFields}
            supplierUserProfileFields={supplierUserProfileFields}
            translation="user"
            createUserProfile={true}
            defaultValues={defaultvalues}
            userSupplierId={userSupplierId ? userSupplierId : ''}
            userId={userId ? userId : ''}
          />
        )}

        {isUserEditModalOpen && (
          <InputModalUser_
            openModal={isUserEditModalOpen}
            handleCloseModal={handleCloseEditModal}
            handleClose={handleCloseEdit}
            handleCancel={handleCancelEdit}
            onSubmitProp={onSubmitEditProp}
            resetOnSubmit
            modalTitle={t('editUserTitle', { ns: ['user'] })}
            contentText={
              t('userMinMaxLen', { min: authVariables.userNameMinCharacter, max: authVariables.userNameMaxCharacter, ns: ['user'] }) +
              ' ' +
              t('toCreateUser', { minChar: authVariables.userPasswordMinCharacter, minDigit: authVariables.userPasswordMinDigit, minUpper: authVariables.userPasswordMinUpper, ns: ['user'] })
            }
            submitText={t('update', { ns: ['user'] })}
            fields={editFieldsNoPasswords}
            supplierUserProfileFields={supplierUserProfileFields}
            translation="user"
            defaultValues={rowCustom}
            userId={userId ? userId : ''}
            editUserProfile={rowCustom ? (rowCustom.type === '2' ? supplierUserProfileFields : undefined) : undefined}
            edit
            userSupplierId={userSupplierId ? userSupplierId : ''}
          />
        )}

        {isUserDeleteModalOpen && (
          <ConfirmModal
            openModal={isUserDeleteModalOpen}
            handleCloseModal={handleCloseDeleteModal}
            handleClose={handleCloseDelete}
            handleCancel={handleCancelDelete}
            onConfirmProp={onDelete}
            modalTitle={t('deleteUserTitle', { ns: ['user'] })}
            contentText={t('toDeleteUser', { ns: ['user'] })}
            confirmText={t('delete', { ns: ['user'] })}
            translation="user"
          />
        )}
        {isItemModalOpen && <ItemModal apiLink={'/list/supplier'} title={t('listSupplierTitle', { ns: ['user'] })} translation="supplier" />}
      </div>
    </>
  ) : (
    <></>
  );
};

export default Users_;
