import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grid, Slide, Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useTextToSpeech from '../../logics/useTextToSpeech';
import { useReduxDispatch, useReduxSelector } from '../../store/Store';
import { tableActions } from '../../store/appSlices/TableSlice';
import { API_DATA, API_INVOICESTORE_ATTACHMENT_FILEVIEW } from '../../utils/variables';
import { TransitionProps } from '@mui/material/transitions';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useAxios } from '../../logics/useAxios';
import SaveIcon from '@mui/icons-material/Save';
import { useColors } from '../../logics/useColors';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} onExited={() => {}} />;
});

const InvoiceStoreEmailAttachmentsViewModal: React.FC = () => {
  const { t } = useTranslation('invoiceStore');
  const [open, setOpen] = useState(true);
  const data = useReduxSelector((state) => state.table.rowCustom as any);
  const dispatch = useReduxDispatch();
  const { apiService } = useAxios();
  const { handlePlay, handleStop } = useTextToSpeech();
  const { tableSettings } = useColors();

  const handleClose = () => {
    setOpen(false);
    dispatch(tableActions.setIsInvoiceStoreEmailAttachmentsViewModalOpen(false));
  };

  const handleCancelModal = () => {
    handleClose();
  };

  const handleCloseModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  const handleClickAttachmentFile = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowId: string, checksum: string, fileName: string) => {
    e.preventDefault();
    try {
      const response = await apiService({
        url: API_DATA + `${API_INVOICESTORE_ATTACHMENT_FILEVIEW}/${rowId}/${checksum ? `?type=${checksum}` : ''}`,
        method: 'GET',
        data: undefined,
        responseType: 'blob',
      });

      if (response) {
        const blob = new Blob([response], {
          type: 'application/octet-stream',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // link.remove();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(`${err.response?.statusText}`);
    }
  };

  return (
    <>
      <Dialog
        sx={{
          border: 1,
          borderColor: tableSettings.buttonsColor,
          '& .MuiDialog-container': {
            '& .MuiPaper-root': { minWidth: '600px', maxWidth: 'auto' },
          },
        }}
        open={open}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <DialogTitle className="flex items-center justify-center text-2xl py-5" onMouseEnter={() => handlePlay(t('viewLogoModal', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
          {t('invoiceStoreEmailAttachmentsTitle', { ns: ['invoiceStore'] })}
        </DialogTitle>
        <DialogContent dividers={true} onMouseEnter={() => handlePlay(t('logoSrc', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
          <Zoom in={true} style={{ transitionDelay: open ? '100ms' : '0ms' }} timeout={700}>
            <DialogContentText>
              <div className="flex flex-col pb-5">
                {data.attachments.length > 0 ? (
                  data.attachments.map((item: any, index: number) => (
                    <Grid key={index} container spacing={1} sx={{ alignItems: 'center' }}>
                      <Grid container item sm={6} direction="column" sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                        <div className="mr-3">
                          <span className="mr-1 font-semibold">{t('fileName', { ns: ['invoiceStore'] })}:</span>
                          <span className="mr-1">{item.fileName}</span>
                        </div>
                      </Grid>

                      <Grid container item sm={4} direction="column" sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                        <div className="mr-3">
                          <span className="mr-1 font-semibold">{t('fileSize', { ns: ['invoiceStore'] })}:</span>
                          <span className="mr-1">{item.size}</span>
                          <span className="mr-1">{t('bytes', { ns: ['invoiceStore'] })}</span>
                        </div>
                      </Grid>

                      <Grid container item sm={2} direction="column" sx={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                        <div className="mr-1">
                          <Button
                            onClick={(e) => handleClickAttachmentFile(e, data.id, item.checksum, item.fileName)}
                            onMouseEnter={() => handlePlay(t('saveFile', { ns: ['invoiceStore'] }))}
                            onMouseLeave={() => handleStop()}
                          >
                            <span className="bg-transparent text-blue-500 px-4 border border-blue-500 hover:bg-blue-500 hover:text-white hover:border-transparent py-0 hover:scale-100 rounded-full">
                              <SaveIcon />
                            </span>
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <span>{`${t('noData', { ns: ['table'] })} 😳`}</span>
                )}
              </div>
            </DialogContentText>
          </Zoom>
        </DialogContent>
        <DialogActions>
          <div className="flex gap-9 mr-7 mb-7">
            <Button onClick={handleCancelModal} onMouseEnter={() => handlePlay(t('cancel', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
              <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                {t('cancel', { ns: ['invoiceStore'] })}
              </span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default InvoiceStoreEmailAttachmentsViewModal;
