import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch } from '../../../store/Store';
import { DataColumnsProp } from '../../types/Table.type';
import { Button } from '@mui/material';
import { tableActions } from '../../../store/appSlices/TableSlice';
import HtmlIcon from '@mui/icons-material/Html';

export const useDataColumnsSite = () => {
  const { t } = useTranslation(['site']);
  const dispatch = useReduxDispatch();

  const handleClickUsers = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    dispatch(tableActions.setRowEmailTemplateId(id));
    dispatch(tableActions.setIsEmailTemplateViewModalOpen_(true));
  };

  const dataColumns: DataColumnsProp[] = [
    { id: 'id', label: t('id', { ns: ['site'] }), type: 'number' },
    { id: 'name', label: t('name', { ns: ['site'] }), type: 'string' },
    { id: 'address', label: t('address', { ns: ['site'] }), type: 'string' },
    { id: 'xmlcode', label: t('xmlcode', { ns: ['site'] }), type: 'string' },
    {
      id: 'users',
      label: t('users', { ns: ['site'] }),
      type: 'string',
      component: (row) => {
        if (row.users) {
          return (
            <Button size="small" sx={{ textTransform: 'uppercase' }} onClick={(e) => handleClickUsers(e, row.id)}>
              {/* <span className={`bg-transparent ${tableVariables.buttonsColorText} text-nowrap`}>{t('data', { ns: ['emailTemplate'] })}</span> */}
              <HtmlIcon />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'supplier',
      label: t('supplier', { ns: ['site'] }),
      type: 'string',
      component: (row) => {
        if (row.supplier && row.supplier.name) {
          return <span>{row.supplier.name}</span>;
        } else {
          return <></>;
        }
      },
    },
  ];
  return { dataColumns };
};
