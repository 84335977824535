import React, { useCallback, useMemo } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { usePermissions } from '../../../logics/usePermissions';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PeopleIcon from '@mui/icons-material/People';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import EmailIcon from '@mui/icons-material/Email';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { FIXIP_CUSTOMER, FIXIP_SUPPLIER, FIXIP_SUPPORT } from '../../../utils/variables';

export const useSideMenuItems = () => {
  const { t } = useTranslation(['sidebar']);
  const { isSupportUser, isSupplierUser, isCustomerUser } = usePermissions();

  const fixIp = useMemo(() => {
    if (isSupportUser) return FIXIP_SUPPORT;
    else if (isSupplierUser) return FIXIP_SUPPLIER;
    else if (isCustomerUser) return FIXIP_CUSTOMER;
  }, [isCustomerUser, isSupplierUser, isSupportUser]);

  const onClick = useCallback(() => {
    console.log('');
  }, []);

  const supportUserSideMenuItems = useMemo(
    () => [
      {
        name: 'home',
        label: t('home', { ns: ['sidebar'] }),
        href: '/login',
        functionProp: onClick,
        Icon: HomeIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'suppliers',
        label: t('suppliers', { ns: ['sidebar'] }),
        href: `${fixIp}/suppliers`,
        functionProp: onClick,
        Icon: MonetizationOnIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      // {
      //   name: 'site',
      //   label: t('sites', { ns: ['sidebar'] }),
      //   href: `${fixIp}/sites`,
      //   functionProp: onClick,
      //   Icon: GroupWorkIcon,
      //   iconType: 'mui',
      // },
      // { name: 'divider' },
      {
        name: 'partners',
        label: t('partners', { ns: ['sidebar'] }),
        href: `${fixIp}/partners`,
        functionProp: onClick,
        Icon: ShoppingCartIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'users',
        label: t('users', { ns: ['sidebar'] }),
        href: `${fixIp}/users`,
        functionProp: onClick,
        Icon: PeopleIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'invoices',
        label: t('invoices', { ns: ['sidebar'] }),
        href: `${fixIp}/invoices`,
        functionProp: onClick,
        Icon: ReceiptIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'emails',
        label: t('emails', { ns: ['sidebar'] }),
        href: `${fixIp}/emails`,
        functionProp: onClick,
        Icon: EmailIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'emailtemplates',
        label: t('emailTemplates', { ns: ['sidebar'] }),
        href: `${fixIp}/emailtemplates`,
        functionProp: onClick,
        Icon: ColorLensIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'invoicestores',
        label: t('invoiceStores', { ns: ['sidebar'] }),
        href: `${fixIp}/invoicestores`,
        functionProp: onClick,
        Icon: DonutSmallIcon,
        iconType: 'mui',
      },
      { name: 'divider' },

      // {
      //   name: 'bankTransactions',
      //   label: t('bankTransactions', { ns: ['sidebar'] }),
      //   functionProp: onClick,
      //   Icon: LocalAtmIcon,
      //   items: [
      //     { name: 'bankPayments', label: t('bankPayments', { ns: ['sidebar'] }), functionProp: onClick },
      //     { name: 'bankRefunds', label: t('bankRefunds', { ns: ['sidebar'] }), functionProp: onClick },
      //   ],
      // },
      // { name: 'divider' },
      // {
      //   name: 'navTransactions',
      //   label: t('navTransactions', { ns: ['sidebar'] }),
      //   functionProp: onClick,
      //   Icon: PaymentsIcon,
      //   items: [
      //     { name: 'navOutgoingInvoices', label: t('navOutgoingInvoices', { ns: ['sidebar'] }), functionProp: onClick },
      //     { name: 'navInvoicesList', label: t('navInvoicesList', { ns: ['sidebar'] }), functionProp: onClick },
      //   ],
      // },
      // { name: 'divider' },
      // {
      //   name: 'statistics',
      //   label: t('statistics', { ns: ['sidebar'] }),
      //   functionProp: onClick,
      //   Icon: QueryStatsIcon,
      //   items: [
      //     { name: 'sentInvoices', label: t('sentInvoices', { ns: ['sidebar'] }), functionProp: onClick },
      //     { name: 'partnerAnalytics', label: t('partnerAnalytics', { ns: ['sidebar'] }), functionProp: onClick },
      //   ],
      // },
      // { name: 'divider' },
      // {
      //   name: 'settings',
      //   label: t('settings', { ns: ['sidebar'] }),
      //   Icon: SettingsIcon,
      //   items: [
      //     { name: 'companyProfile', label: t('companyProfile', { ns: ['sidebar'] }), functionProp: onClick },
      //     { name: 'divider' },
      //     {
      //       name: 'notifications',
      //       label: t('notifications', { ns: ['sidebar'] }),
      //       Icon: NotificationsIcon,
      //       items: [
      //         { name: 'email', label: t('email', { ns: ['sidebar'] }), functionProp: onClick },
      //         { name: 'schedule', label: t('schedule', { ns: ['sidebar'] }), functionProp: onClick },
      //       ],
      //     },
      //     {
      //       name: 'desktop',
      //       label: t('desktop', { ns: ['sidebar'] }),
      //       Icon: DesktopWindowsIcon,
      //       items: [{ name: 'lightDarkMode', label: t('lightDarkMode', { ns: ['sidebar'] }), functionProp: onClick }],
      //     },
      //   ],
      // },
      // {
      //   name: 'divider',
      // },
      // {
      //   name: 'documentation',
      //   label: t('documentation', { ns: ['sidebar'] }),
      //   functionProp: onClick,
      //   Icon: ArticleIcon,
      //   items: [
      //     { name: 'faq', label: t('faq', { ns: ['sidebar'] }), functionProp: onClick },
      //     { name: 'usersGuide', label: t('usersGuide', { ns: ['sidebar'] }), functionProp: onClick },
      //   ],
      // },
    ],
    [fixIp, onClick, t],
  );

  const supplierUserSideMenuItems = useMemo(
    () => [
      // { name: 'home', label: t('home', { ns: ['sidebar'] }), Icon: HomeIcon, iconType: 'mui', functionProp: onClick },
      {
        name: 'invoices_',
        label: t('invoices', { ns: ['sidebar'] }),
        href: `${FIXIP_SUPPLIER}/invoices_`,
        functionProp: onClick,
        Icon: ReceiptIcon,
        iconType: 'mui',
        // items: [
        //   { name: 'outgoingInvoices', label: t('outgoingInvoices', { ns: ['sidebar'] }), functionProp: onClick },
        //   { name: 'asideInvoices', label: t('asideInvoices', { ns: ['sidebar'] }), functionProp: onClick },
        //   { name: 'newInvoice', label: t('newInvoice', { ns: ['sidebar'] }), functionProp: onClick },
        //   { name: 'incomingInvoices', label: t('incomingInvoices', { ns: ['sidebar'] }), functionProp: onClick },
        //   { name: 'invoicesStore', label: t('invoicesStore', { ns: ['sidebar'] }), functionProp: onClick },
        // ],
      },
      { name: 'divider' },
      // {
      //   name: 'sites_',
      //   label: t('sites', { ns: ['sidebar'] }),
      //   href: `${FIXIP_SUPPLIER}/sites_`,
      //   functionProp: onClick,
      //   Icon: PinDropOutlinedIcon,
      //   iconType: 'mui',
      // },
      // { name: 'divider' },
      {
        name: 'emails_',
        label: t('sentEmails', { ns: ['sidebar'] }),
        href: `${FIXIP_SUPPLIER}/emails_`,
        functionProp: onClick,
        Icon: EmailOutlinedIcon,
        iconType: 'mui',
      },
      {
        name: 'undeliveredEmails_',
        label: t('undeliveredEmails', { ns: ['sidebar'] }),
        href: `${FIXIP_SUPPLIER}/undeliveredemails_`,
        functionProp: onClick,
        Icon: ReplyAllIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'partners_',
        label: t('customers', { ns: ['sidebar'] }),
        href: `${FIXIP_SUPPLIER}/partners_`,
        functionProp: onClick,
        Icon: ShoppingCartOutlinedIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'emailtemplates_',
        label: t('emailTemplates', { ns: ['sidebar'] }),
        href: `${FIXIP_SUPPLIER}/emailtemplates_`,
        functionProp: onClick,
        Icon: ColorLensOutlinedIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'invoicestore_',
        label: t('invoiceStore', { ns: ['sidebar'] }),
        href: `${FIXIP_SUPPLIER}/invoicestores_`,
        functionProp: onClick,
        Icon: FolderOutlinedIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'users_',
        label: t('users', { ns: ['sidebar'] }),
        href: `${FIXIP_SUPPLIER}/users_`,
        functionProp: onClick,
        Icon: PeopleIcon,
        iconType: 'mui',
      },
      { name: 'divider' },
      {
        name: 'settings_',
        label: t('settings', { ns: ['sidebar'] }),
        href: `${FIXIP_SUPPLIER}/suppliers_`,
        functionProp: onClick,
        Icon: SettingsIcon,
        iconType: 'mui',
      },
    ],
    [onClick, t],
  );

  const customerUserSideMenuItems = useMemo(
    () => [
      {
        name: 'invoices_c',
        label: t('invoices', { ns: ['sidebar'] }),
        href: 'pdflink/invoices_c',
        functionProp: onClick,
        Icon: ReceiptIcon,
        iconType: 'mui',
      },
    ],
    [onClick, t],
  );

  const sideMenuItems = isSupportUser ? supportUserSideMenuItems : isSupplierUser ? supplierUserSideMenuItems : customerUserSideMenuItems;
  return sideMenuItems;
};
