import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../../../logics/useAxios';
import { usePermissions } from '../../../logics/usePermissions';
import useSWR from 'swr';
import { API_DATA, API_LIST_USERS, serverPagination } from '../../../utils/variables';
import { useReduxSelector } from '../../../store/Store';

type CreateSiteFieldsProps = {
  setLoadingSelectList: Dispatch<SetStateAction<boolean>>;
  supplierId?: string;
};

export const useCreateSiteFields = ({ setLoadingSelectList, supplierId }: CreateSiteFieldsProps) => {
  const { t } = useTranslation(['site']);
  const isSiteCreateModalOpen = useReduxSelector((state) => state.table.isSiteCreateModalOpen);
  const isSiteEditModalOpen = useReduxSelector((state) => state.table.isSiteEditModalOpen);
  const [selectOptionsUsers, setSelectOptionsUsers] = useState<{ [key: string]: string }>({});
  const { isSupportUser } = usePermissions();
  const { apiService } = useAxios();

  // const {
  //   data: fetchedDataUsers,
  //   error: isLoadingDataErrorUsers,
  //   isLoading: isLoadingDataUsers,
  //   isValidating: isFetchingDataUsers,
  // } = useSWR(isSiteCreateModalOpen || isSiteEditModalOpen ? [`${API_DATA}${API_LIST_USERS}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  // const dataUsers = useMemo(() => {
  //   if (fetchedDataUsers) {
  //     if (!serverPagination) return fetchedDataUsers;
  //     return fetchedDataUsers.results;
  //   } else return undefined;
  // }, [fetchedDataUsers]);

  useEffect(() => {
    // if (dataUsers) {
    //   setSelectOptionsUsers(dataUsers);
    //   setLoadingSelectList(false);
    // }
    setLoadingSelectList(false);
  }, [setLoadingSelectList]);

  const fields = [
    {
      label: t('baseSupplierData', { ns: ['site'] }),
      items: [
        { name: 'supplierName', type: 'text', nonEditable: true },
        { name: 'supplierTaxNumber', type: 'text', nonEditable: true },
        { name: 'supplierAddress', type: 'text', nonEditable: true },
      ],
    },
    {
      label: t('baseSiteData', { ns: ['site'] }),
      items: [
        { name: 'name', type: 'text' },
        { name: 'postalCode', type: 'text' },
        { name: 'city', type: 'text' },
        { name: 'address', type: 'text' },
        { name: 'uniqueCode', type: 'text' },
        { name: 'isAssigningUsers', type: 'boolean', required: false, default: false, visible: true },
      ],
    },
  ];

  return { fields };
};
