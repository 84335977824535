import React from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch } from '../../../store/Store';
import { DataColumnsProp } from '../../types/Table.type';
import { Button } from '@mui/material';
import { tableActions } from '../../../store/appSlices/TableSlice';
import { AxiosError } from 'axios';
import { useAxios } from '../../../logics/useAxios';
import { API_DATA, API_INVOICESTORE_PDF_FILEVIEW } from '../../../utils/variables';
import { toast } from 'react-toastify';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useColors } from '../../../logics/useColors';

export const useDataColumnsInvoiceStore = () => {
  const { t } = useTranslation(['invoiceStore']);
  const dispatch = useReduxDispatch();
  const { apiService } = useAxios();
  const { tableSettings } = useColors();

  const handleClickPdf = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowId: any) => {
    e.preventDefault();
    try {
      const response = await apiService({ url: API_DATA + `${API_INVOICESTORE_PDF_FILEVIEW}/${rowId}`, method: 'GET', data: undefined, responseType: 'blob' });
      const blob = new Blob([response], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(`${err.response?.statusText}`);
    }
  };

  const handleClickData = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: object) => {
    e.preventDefault();
    dispatch(tableActions.setRowCustom(data));
    dispatch(tableActions.setIsInvoiceStoreEmailDataViewModalOpen(true));
  };

  const handleClickAttachments = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: object) => {
    e.preventDefault();
    dispatch(tableActions.setRowCustom(data));
    dispatch(tableActions.setIsInvoiceStoreEmailAttachmentsViewModalOpen(true));
  };

  const dataColumns: DataColumnsProp[] = [
    { id: 'id', label: t('id', { ns: ['invoiceStore'] }), type: 'number' },
    { id: 'invoice_number', label: t('invoiceNumber', { ns: ['invoiceStore'] }), type: 'string' },
    { id: 'invoice_issue_date', label: t('invoiceIssueDate', { ns: ['invoiceStore'] }), type: 'string' },
    { id: 'invoice_seller_tax_number', label: t('invoiceSellerTaxnumber', { ns: ['invoiceStore'] }), type: 'string' },
    { id: 'invoice_seller_name', label: t('invoiceSellerName', { ns: ['invoiceStore'] }), type: 'string' },
    { id: 'invoice_customer_name', label: t('invoiceCustomerName', { ns: ['invoiceStore'] }), type: 'string' },
    {
      id: 'pdf_link',
      label: t('pdfLink', { ns: ['invoiceStore'] }),
      type: 'string',
      component: (row) => {
        if (row.pdf_link) {
          return (
            <Button size="small" sx={{ textTransform: 'uppercase' }} onClick={(e) => handleClickPdf(e, row.id)}>
              {/* <span className={`bg-transparent ${tableVariables.buttonsColorText} text-nowrap`}>{t('data', { ns: ['emailTemplate'] })}</span> */}
              <PictureAsPdfIcon />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'email_invoicestore_attachments',
      label: t('attachments', { ns: ['invoiceStore'] }),
      type: 'string',
      component: (row) => {
        if (row.email_invoicestore.attachments) {
          return (
            <Button size="small" sx={{ textTransform: 'uppercase' }} onClick={(e) => handleClickAttachments(e, row.email_invoicestore)}>
              <AttachmentIcon />
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'email_invoicestore',
      label: t('emailInvoiceStore', { ns: ['invoiceStore'] }),
      type: 'string',
      component: (row) => {
        if (row.email_invoicestore) {
          return (
            <Button size="small" sx={{ textTransform: 'uppercase' }} onClick={(e) => handleClickData(e, row.email_invoicestore)}>
              <span className={`bg-transparent ${tableSettings.buttonsColorText} text-nowrap`}>{t('data', { ns: ['invoiceStore'] })}</span>
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'supplier',
      label: t('supplier', { ns: ['invoiceStore'] }),
      type: 'string',
      component: (row) => {
        if (row.supplier && row.supplier.name) {
          return <span>{row.supplier.name}</span>;
        } else {
          return <></>;
        }
      },
    },
  ];
  return { dataColumns };
};
