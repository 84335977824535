import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTextToSpeech from '../../logics/useTextToSpeech';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grid, Slide, TextField, Tooltip, Zoom } from '@mui/material';
import { useReduxDispatch, useReduxSelector } from '../../store/Store';
import { TransitionProps } from '@mui/material/transitions';
import { tableActions } from '../../store/appSlices/TableSlice';
import { useAxios } from '../../logics/useAxios';
import useSWR from 'swr';
import { API_CREATE_EMAILTEMPLATE, API_DATA, API_LIST_EMAILTEMPLATES, API_SELECTLIST_SUPPLIERS, API_UPDATE_EMAILTEMPLATE, serverPagination, tableVariables } from '../../utils/variables';
import * as handlebars from 'handlebars';
import Editor from '@monaco-editor/react';
import { ALPHA_NUMERIC_DASH_REGEX } from '../../utils/common';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { EmailTemplateType } from '../types/EmailTemplate.type';
import DragDropSmall from './DragDropSmall';
import { OptionsProps, SelectSearch } from './SelectSearch';
import { usePermissions } from '../../logics/usePermissions';
import { useColors } from '../../logics/useColors';

type EditEmailTemplateModalProps = {
  mode: number;
  onSubmitCreateProp?: any;
  onSubmitEditProp?: any;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} onExited={() => {}} />;
});

export const EditEmailTemplateModal: React.FC<EditEmailTemplateModalProps> = ({ mode, onSubmitCreateProp, onSubmitEditProp }) => {
  const { t } = useTranslation(['emailTemplate']);
  const [open, setOpen] = useState(true);
  const rowEmailTemplateId = useReduxSelector((state) => state.table.rowEmailTemplateId);
  const [customLogo, setCustomLogo] = useState('');
  const [html, setHtml] = useState('');
  const [htmlName, setHtmlName] = useState('');
  const [editorLightTheme, setEditorLightTheme] = useState(true);
  const [currentEmailTemplate, setCurrentEmailTemplate] = useState<any>();
  const [isCompiled, setIsCompiled] = useState(true);
  const [data, setData] = useState('');
  const [init, setInit] = useState(true);
  const [isFileName, setIsFileName] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [isValidNewTemplateName, setIsValidNewTemplateName] = useState({ valid: true, errorText: '' });
  const dispatch = useReduxDispatch();
  const { handlePlay, handleStop } = useTextToSpeech();
  const rowCustom = useReduxSelector((state) => state.table.rowCustom);
  const [files, setFiles] = useState<File[]>([]);
  const [isHtmlCodeError, setIsHtmlCodeError] = useState(false);
  const { isSupportUser, isSupplierUser, userSupplierId, userSupplierName } = usePermissions();
  const [selectedSupplier, setSelectedSupplier] = useState<string>('');
  const [defaultSelectOptions, setDefaultSelectOptions] = useState<OptionsProps>();
  const { apiService } = useAxios();
  const { backgroundColor_, tableSettings } = useColors();

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
  } = useSWR(
    mode === 0 || mode === 1 ? [`${API_DATA}${API_LIST_EMAILTEMPLATES}`, 'GET', ''] : null, // [`${API_DATA}${API_LIST_EMAILTEMPLATE_BY_ID}/${parseInt(rowEmailTemplateId)}`, 'GET', '']
    ([url, method, body]) => apiService({ url: url, method: method, data: body }),
  );

  const emailTemplates_: EmailTemplateType[] = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  const emailTemplate = useMemo(() => {
    if (mode === 0 && emailTemplates_) {
      return emailTemplates_.find((item: any) => item.id === rowEmailTemplateId);
    } else if (mode === 1 && rowCustom) {
      return rowCustom as EmailTemplateType;
    } else return undefined;
  }, [emailTemplates_, mode, rowCustom, rowEmailTemplateId]);

  useEffect(() => {
    if (init) {
      if ((mode === 0 || mode === 1) && emailTemplate && emailTemplate.data && emailTemplate.sample && emailTemplate.name) {
        const logo = `src="${emailTemplate.logo}"`;
        const dataWithLogo = emailTemplate.data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
        const template = handlebars.compile(dataWithLogo);
        setHtml(template(emailTemplate.sample));
        setHtmlName(emailTemplate.name);
        setData(emailTemplate.data);
        setCurrentEmailTemplate(emailTemplate);
        if (emailTemplate.supplierId) {
          setDefaultSelectOptions({ id: emailTemplate.supplierId, label: (emailTemplate as any).supplier.name });
          setSelectedSupplier(emailTemplate.supplierId);
        }
        setInit(false);
      }
    }
  }, [emailTemplate, init, mode]);

  const language = useMemo(() => {
    if (emailTemplate?.name) {
      return emailTemplate?.name.substring(emailTemplate?.name.length - 3);
    } else return '';
  }, [emailTemplate?.name]);

  useEffect(() => {
    if (emailTemplate?.name) {
      setNewTemplateName(emailTemplate.name.substring(0, emailTemplate.name.length - 3));
    }
  }, [emailTemplate?.name]);

  const isOwnTemplate = useMemo(() => {
    if (newTemplateName + language === emailTemplate?.name) return true;
    else return false;
  }, [emailTemplate?.name, language, newTemplateName]);

  const isLogoWarn = useMemo(() => {
    if (emailTemplate?.type?.startsWith('base') && isOwnTemplate) return true;
    else return false;
  }, [emailTemplate?.type, isOwnTemplate]);

  const validateNewTemplateName = async (fileName: string) => {
    let valid = true;
    let errorText = '';

    if (fileName !== currentEmailTemplate.label) {
      for await (const item of emailTemplates_) {
        if (item.name === fileName && item.name !== emailTemplate?.name) {
          valid = false;
          errorText = t('errorFileNameExists', { ns: ['emailTemplate'] });
          break;
        }
      }
    }

    return { valid, errorText };
  };

  const handleChangeNewTemplateName = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value !== '' && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      return;
    } else {
      if (emailTemplate?.type?.startsWith('base') && value + language === emailTemplate?.name && files.length > 0) {
        toast.error(t('warningBaseLogo', { ns: ['emailTemlate'] }));
      }
      setNewTemplateName(value);
      setIsFileName(true);
      handleBlurNewTemplateName({ value: value });
    }
  };

  const handleBlurNewTemplateName = async ({ e, value }: { e?: any; value?: string }) => {
    const value_ = e ? (e.target.value as string) : value;
    const fileName = value_ + language;
    if (!value_) {
      setIsValidNewTemplateName({
        valid: false,
        errorText: t('errorNoTemplateName', { ns: ['emailTemplate'] }),
      });
    } else {
      const { valid, errorText } = await validateNewTemplateName(fileName);
      setIsValidNewTemplateName({
        valid: valid,
        errorText: errorText,
      });
    }
  };

  const handleClose = () => {
    switch (mode) {
      case 0: {
        dispatch(tableActions.setIsEmailTemplateViewModalOpen_(false));
        break;
      }
      case 1: {
        dispatch(tableActions.setIsEmailTemplateEditModalOpen_(false));
        break;
      }
      default:
    }
  };

  const handleCloseModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  const onSwitch = useCallback(() => {
    setEditorLightTheme(!editorLightTheme);
  }, [editorLightTheme]);

  const onChange = (newValue?: string) => {
    if (newValue) {
      if (newValue.indexOf('{{}}') !== -1) {
        toast.error(t('htmlCodeError', { ns: ['emailTemplate'] }));
        setIsHtmlCodeError(true);
      } else {
        if (isHtmlCodeError) setIsHtmlCodeError(false);
        setData(newValue);
        setIsFileName(true);
        if (isCompiled) {
          const logo = customLogo ? customLogo : `src="${currentEmailTemplate.logo}"`;
          const dataWithLogo = newValue.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
          const template = handlebars.compile(dataWithLogo);
          setHtml(template(currentEmailTemplate.sample));
        } else {
          setHtml(newValue);
        }
      }
    }
  };

  const onConfirm = async () => {
    const logo = customLogo ? (customLogo.startsWith('src="') ? customLogo.substring(5).substring(0, customLogo.substring(5).length - 1) : customLogo) : currentEmailTemplate.logo;
    const api = !isOwnTemplate ? `${API_DATA}${API_CREATE_EMAILTEMPLATE}` : `${API_DATA}${API_UPDATE_EMAILTEMPLATE}/${currentEmailTemplate.id}`;

    try {
      const newTemplate = {
        id: currentEmailTemplate.id,
        name: `${newTemplateName}${language}`,
        language: currentEmailTemplate.language,
        data: data,
        logo: logo,
        sample: currentEmailTemplate.sample,
        type: currentEmailTemplate.type,
        supplier_id: selectedSupplier,
      };

      const response = !isOwnTemplate
        ? await onSubmitCreateProp(api, {
            name: newTemplate.name,
            logo: newTemplate.logo,
            data: newTemplate.data,
            language: newTemplate.language,
            sample: newTemplate.sample,
            type: newTemplate.type,
            supplier_id: newTemplate.supplier_id,
          })
        : await onSubmitEditProp(api, newTemplate);

      if (response && response.success) {
        dispatch(tableActions.setRowCustom({}));
        handleClose();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(`${err.response?.statusText}`);
    }
  };

  const onCompile = () => {
    const logo = customLogo ? customLogo : `src="${currentEmailTemplate.logo}"`;
    const dataWithLogo = data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
    const template = handlebars.compile(dataWithLogo);
    setHtml(template(currentEmailTemplate.sample));
    setIsCompiled(true);
  };

  const onOriginal = () => {
    setHtml(data);
    setIsCompiled(false);
  };

  useEffect(() => {
    if (files.length === 0) {
      setCustomLogo('');
      if (currentEmailTemplate) {
        const logo = `src="${currentEmailTemplate.logo}"`;
        const dataWithLogo = currentEmailTemplate.data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
        const template = handlebars.compile(dataWithLogo);
        setHtml(template(currentEmailTemplate.sample));
        setHtmlName(currentEmailTemplate.label);
      }
    } else if (files && files[0] && currentEmailTemplate) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const base64 = reader.result;
        const logo = `src="${base64}"`;
        setCustomLogo(logo);
        const dataWithLogo = currentEmailTemplate.data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
        const template = handlebars.compile(dataWithLogo);
        setHtml(template(currentEmailTemplate.sample));
        setHtmlName(currentEmailTemplate.label);
      };
      reader.onerror = () => console.log('error');
    }
  }, [files, currentEmailTemplate]);

  return (
    <Fragment>
      {mode === 0 ? (
        <>
          <Dialog
            sx={{
              border: 1,
              borderColor: tableSettings.buttonsColor,
              '& .MuiDialog-container': {
                '& .MuiPaper-root': { width: '100%', maxWidth: 'md' },
              },
            }}
            open={open}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            keepMounted
            scroll="paper"
          >
            <DialogTitle className="flex items-center justify-center text-2xl py-5" onMouseEnter={() => handlePlay(t('emailTemplate', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
              {t('emailTemplate', { ns: ['emailTemplate'] })}
            </DialogTitle>
            <DialogContent dividers={true}>
              <Zoom in={true} style={{ transitionDelay: open ? '100ms' : '0ms' }} timeout={700}>
                <div className="bg-transparent mb-7 pb-1 px-4">
                  <DialogContentText
                    component={'div'}
                    sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                    onMouseEnter={() => handlePlay(t('templateName', { ns: ['emailTemplate'] }))}
                    onMouseLeave={() => handleStop()}
                  >
                    <div className="flex items-center justify-center pb-0">
                      <div className="flex flex-row">
                        <div className="mr-5">{`${t('templateName', { ns: ['emailTemplate'] })}: ${htmlName}`}</div>
                      </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </DialogContentText>
                </div>
              </Zoom>
            </DialogContent>
            <DialogActions>
              <div className="flex gap-9 mr-7 mb-7">
                {/* Cancel */}
                <Button onClick={handleClose} onMouseEnter={() => handlePlay(t('cancel', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
                  <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                    {t('cancel', { ns: ['emailTemplate'] })}
                  </span>
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </>
      ) : mode === 1 ? (
        <>
          <Dialog
            sx={{
              border: 1,
              borderColor: tableSettings.buttonsColor,
              '& .MuiDialog-container': {
                '& .MuiPaper-root': { width: '100%', maxWidth: '1500px' },
              },
            }}
            open={open}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            // keepMounted
            // scroll="paper"
          >
            <DialogTitle className="text-2xl py-1" onMouseEnter={() => handlePlay(t('templateName', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
              <div className="flex flex-col items-center justify-center pb-0">
                <div className="flex flex-row">
                  <div className="mr-5">{`${t('templateName', { ns: ['emailTemplate'] })}: ${emailTemplate?.name}`}</div>
                </div>
              </div>
            </DialogTitle>

            <DialogContent sx={{ display: 'flex', color: tableSettings.textColor, backgroundColor: backgroundColor_ }} dividers={true}>
              <Grid container spacing={2}>
                <Grid container item xs={6} direction="column" sx={{ height: '100%', overflowY: 'hidden' }}>
                  <Zoom in={true} style={{ transitionDelay: open ? '100ms' : '0ms' }} timeout={700}>
                    <div className="bg-transparent mb-1 pb-1 px-1">
                      <DialogContentText
                        component={'div'}
                        sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                        onMouseEnter={() => handlePlay(htmlName)}
                        onMouseLeave={() => handleStop()}
                      >
                        <div className="flex flex-row mb-5">
                          <div className="mr-7">
                            <DragDropSmall
                              onFilesSelected={setFiles}
                              text={t('dragDropText', { ns: ['emailTemplate'] })}
                              textSupported={t('dragDropTextSupported', { ns: ['emailTemplate'] })}
                              acceptFileTypes={'.png'}
                              multiple={false}
                              translation="emailTemplate"
                              isLogoWarn={isLogoWarn}
                            />
                          </div>
                          <div
                            className="flex items-center justify-center"
                            onMouseEnter={() => handlePlay(editorLightTheme ? t('lightTheme', { ns: ['emailTemplate'] }) : t('darkTheme', { ns: ['emailTemplate'] }))}
                            onMouseLeave={() => handleStop()}
                          >
                            <p className="mr-1 text-sm font-medium text-gray-900 dark:text-gray-300">
                              {editorLightTheme ? t('lightTheme', { ns: ['emailTemplate'] }) : t('darkTheme', { ns: ['emailTemplate'] })}
                            </p>

                            <label className="relative cursor-pointer">
                              <input type="checkbox" className="sr-only peer" checked={editorLightTheme} readOnly />
                              <div
                                onClick={onSwitch}
                                className="w-[53px] h-6 flex items-center bg-gray-300 rounded-full text-[9px] peer-checked:text-[#007bff] text-[#007bff] font-extrabold after:flex after:items-center after:justify-center peer after:content-['Off'] peer-checked:after:content-['On'] peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-[#007bff] after:bg-white after:border after:border-[#007bff] after:rounded-full after:size-6 after:transition-all peer-checked:bg-gray-300" // peer-checked:bg-[#007bff]
                              ></div>
                            </label>
                          </div>
                        </div>

                        <div className="mt-1">
                          <Editor
                            height="100vh"
                            language="handlebars"
                            value={data}
                            defaultValue="Please enter HTML code."
                            theme={editorLightTheme ? 'vs-light' : 'vs-dark'}
                            onChange={onChange}
                            options={{
                              selectOnLineNumbers: true,
                              roundedSelection: false,
                              readOnly: false,
                              cursorStyle: 'line' as 'line' | 'block' | 'underline' | 'line-thin' | 'block-outline' | 'underline-thin' | undefined,
                            }}
                          />
                        </div>
                      </DialogContentText>
                    </div>
                  </Zoom>
                </Grid>

                <Grid container item xs={6} direction="column" sx={{ height: '100%', overflowY: 'scroll' }}>
                  <DialogContentText
                    component={'div'}
                    sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                    onMouseEnter={() => handlePlay(htmlName)}
                    onMouseLeave={() => handleStop()}
                  >
                    <div className="flex flex-col items-center justify-center pb-0">
                      <div>{`${isCompiled ? t('compiled', { ns: ['emailTemplate'] }) : t('original', { ns: ['emailTemplate'] })}`}</div>
                      <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                  </DialogContentText>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <div className="flex gap-11 mr-7 mb-1">
                {/* Select Supplier */}
                {isSupportUser && defaultSelectOptions && !isOwnTemplate && (
                  <SelectSearch
                    selectLabel={t('supplier', { ns: ['invoice'] })}
                    selectValue={selectedSupplier}
                    onSelect={setSelectedSupplier}
                    rounded={true}
                    minWidth={250}
                    minHeight={tableVariables.selectHeight}
                    async
                    apiLink={API_SELECTLIST_SUPPLIERS}
                    // initSelectId={initSelectId}
                    initOpen={false}
                    // initSelectOptions={{ id: '0', label: t('allSuppliers', { ns: ['invoice'] }) }}
                    defaultSelectOptions={defaultSelectOptions}
                    nonEditable={isSupplierUser}
                    color={{
                      buttonsColor: tableSettings.buttonsColor,
                      selectTextColor: tableSettings.titleTextColor,
                      selectLabelTextColor: tableSettings.selectLabelTextColor,
                      buttonsColorBorder: tableSettings.buttonsColorBorder,
                      selectOptionsColor: tableSettings.selectOptionsColor,
                      backgroundColor: backgroundColor_,
                      hooverBackgroundColor: tableSettings.buttonsColor, // darkMode : 'white',
                      hooverBorderColor: tableSettings.selectBorderColor,
                      hooverButtonsColor: 'white',
                    }}
                  />
                )}

                {/* File name */}
                <span>
                  <TextField
                    id="new-template-name"
                    label={t('newTemplateName', { lang: language, ns: ['emailTemplate'] })}
                    variant="standard"
                    value={newTemplateName}
                    error={!isValidNewTemplateName.valid}
                    helperText={isValidNewTemplateName.errorText}
                    onChange={handleChangeNewTemplateName}
                    onBlur={(e) => handleBlurNewTemplateName({ e: e })}
                    fullWidth
                    size="small"
                    required
                    inputProps={{ maxLength: 30 }}
                  />
                </span>

                {/* Origin */}
                <span>
                  <Button onClick={onOriginal} onMouseEnter={() => handlePlay(t('original', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()} disabled={!isCompiled}>
                    <span className="bg-transparent text-green-600 px-4 border border-green-600 hover:bg-green-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                      {t('original', { ns: ['emailTemplate'] })}
                    </span>
                  </Button>
                </span>

                {/* Compile */}
                <span>
                  <Button onClick={onCompile} onMouseEnter={() => handlePlay(t('compiled', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()} disabled={isCompiled}>
                    <span className="bg-transparent text-green-600 px-4 border border-green-600 hover:bg-green-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                      {t('compiled', { ns: ['emailTemplate'] })}
                    </span>
                  </Button>
                </span>

                {/* Confirm */}
                <Tooltip
                  arrow
                  title={isHtmlCodeError ? t('htmlCodeError', { ns: ['emailTemplate'] }) : ''}
                  placement="top"
                  slots={{
                    transition: Zoom,
                  }}
                >
                  <span>
                    <Button
                      onClick={onConfirm}
                      onMouseEnter={() => handlePlay(!isOwnTemplate ? t('save', { ns: ['emailTemplate'] }) : t('overWrite', { ns: ['emailTemplate'] }))}
                      onMouseLeave={() => handleStop()}
                      disabled={(isOwnTemplate && !isFileName && files.length === 0) || (!isOwnTemplate && (!isFileName || !isValidNewTemplateName.valid))}
                    >
                      <span className="bg-transparent text-sky-600 px-4 border border-sky-600 hover:bg-sky-600 hover:text-white hover:border-transparent hover:scale-125 rounded-full">
                        {!isOwnTemplate ? t('save', { ns: ['emailTemplate'] }) : t('overWrite', { ns: ['emailTemplate'] })}
                      </span>
                    </Button>
                  </span>
                </Tooltip>

                {/* Delete */}
                {/* {deletableTemplate && (
                  <Button onClick={onDelete} onMouseEnter={() => handlePlay(t('delete', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
                    <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                      {t('delete', { ns: ['emailTemplate'] })}
                    </span>
                  </Button>
                )} */}

                {/* Cancel */}
                <span>
                  <Button onClick={handleClose} onMouseEnter={() => handlePlay(t('cancel', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
                    <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                      {t('cancel', { ns: ['emailTemplate'] })}
                    </span>
                  </Button>
                </span>
              </div>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </Fragment>
  );
};
