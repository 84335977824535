import React, { Dispatch, SetStateAction, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Collapse, ListItemButton, Drawer, Typography, Button, Tooltip } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSideMenuItems } from './menuItems/sideMenuItems';
import { isSideBarInitHoverEnable, sidebarVariables } from '../../utils/variables';
import { useReduxSelector } from '../../store/Store';
import { usePermissions } from '../../logics/usePermissions';
import { useNavigate } from 'react-router-dom';
import { useColors } from '../../logics/useColors';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type SideMenuProps = {
  stateDrawer: boolean;
  setStateDrawer: Dispatch<SetStateAction<boolean>>;
};

type SideMenuItemProps = {
  depthPadding: number;
  depth: number;
  item: any;
  stateDrawer: boolean;
  setStateDrawer: Dispatch<SetStateAction<boolean>>;
  isDrawerText: boolean;
  isHoverEnable: boolean;
};

const SidebarItem: React.FC<SideMenuItemProps> = ({ depthPadding, depth, item, stateDrawer, setStateDrawer, isDrawerText, isHoverEnable }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const pageName = useReduxSelector((state) => state.home.pageName);
  const { name, label, Icon, iconType, items, href, functionProp: functionProp } = item;
  const { sideMenuSettings } = useColors();

  const onClick = () => {
    if (Array.isArray(items)) {
      setOpen(!open);
    } else if (href) {
      navigate(href);
      setStateDrawer(false);
    } else if (functionProp) {
      setStateDrawer(false);
      functionProp();
    }
  };

  let expandIcon;
  if (Array.isArray(items) && items.length) {
    expandIcon = open ? <ExpandLess style={{ color: sideMenuSettings.iconColor }} /> : <ExpandMore />;
  }

  return (
    <>
      <Tooltip arrow title={label} placement="right-end" disableHoverListener={isHoverEnable || (!isHoverEnable && isDrawerText)}>
        <ListItemButton
          key={label}
          sx={{
            color: `${pageName === name ? sideMenuSettings.selectedIconColor : sideMenuSettings.iconColor}`,
            // '&.Mui-selected': {
            //   backgroundColor: sideMenuSettings.iconBackgroundColorHover,
            //   color: sideMenuSettings.iconColor,
            // },
            // '&.Mui-focusVisible': {
            //   backgroundColor: sideMenuSettings.iconBackgroundColorHover,
            //   color: 'white',
            // },
            ':hover': {
              backgroundColor: sideMenuSettings.iconBackgroundColorHover,
              color: sideMenuSettings.iconColorHover,
            },
            cursor: 'pointer',
          }}
          onClick={onClick}
        >
          <div
            style={{
              paddingLeft: depth * depthPadding,
              paddingBottom: depth === 1 ? sidebarVariables.listItemPadding : sidebarVariables.listItemPaddingDepth,
            }}
            className="flex"
          >
            {Icon ? (
              iconType && iconType === 'svg' ? (
                <>
                  <img src={Icon.default} alt="mySvgImage" />
                </>
              ) : iconType && iconType === 'mui' ? (
                <Icon
                  fontSize="medium"
                  // style={{ color: sideMenuSettings.iconColor }}
                />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {isDrawerText && (
              <Typography
                sx={{
                  paddingLeft: 1,
                  // color: sideMenuSettings.textColor
                }}
                noWrap
                component="p"
              >
                {label}
              </Typography>
            )}
          </div>
          {isDrawerText && expandIcon}
        </ListItemButton>
      </Tooltip>

      <Collapse in={open}>
        {Array.isArray(items) && (
          <List component="div" disablePadding dense>
            {items.map((subItem: any, index: number) => (
              <React.Fragment key={`${subItem.name}${index}`}>
                {subItem === 'divider' ? (
                  <Divider style={{ color: sideMenuSettings.textColor, margin: '6px 0' }} />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthPadding={depthPadding}
                    item={subItem}
                    stateDrawer={stateDrawer}
                    setStateDrawer={setStateDrawer}
                    isDrawerText={isDrawerText}
                    isHoverEnable={isHoverEnable}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        )}
      </Collapse>
    </>
  );
};

const SideMenu: React.FC<SideMenuProps> = ({ stateDrawer, setStateDrawer }) => {
  const user = useReduxSelector((state) => state.auth.user);
  const { userProfile } = usePermissions();
  const sideMenuItems = useSideMenuItems();
  const depthPadding = sidebarVariables.depthPadding;
  const depth = 0;
  const [isDrawerText, setIsDrawerText] = useState(false);
  const [isHoverEnable, setIsHoverEnable] = useState(isSideBarInitHoverEnable);
  const { sideMenuSettings } = useColors();

  const handlerDrawerClose = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setStateDrawer(open);
  };

  const showDrawerText = (isShown: boolean) => {
    if (isShown) {
      if (!isDrawerText) setIsDrawerText(true);
    } else {
      if (isDrawerText) setIsDrawerText(false);
    }
  };

  const handleSetHover = () => {
    setIsHoverEnable((prev) => !prev);
  };

  const list = () => (
    <Box
      sx={{
        width: isDrawerText ? sidebarVariables.sidebarWidth : sidebarVariables.sidebarWidthIcons,
        // '&:hover': {
        //   width: sidebarVariables.sidebarWidth,
        // },
        cursor: 'pointer',
        transition: 'width 0.5s ease-in-out',
        background: sideMenuSettings.backGroundColor,
      }}
      onMouseEnter={() => (isHoverEnable ? showDrawerText(true) : {})}
      onMouseLeave={() => showDrawerText(false)}
      role="presentation"
    >
      {/* <div>
        <Grid
          sx={{
            backgroundColor: sidebarVariables.backgrounColor,
            backgroundImage: sidebarVariables.backgroundImage,
            backgroundSize: 'cover',
            overflow: 'hidden',
            height: sidebarVariables.gridHeight,
            color: '#fff',
            padding: '15px',
          }}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar sx={{ color: sidebarVariables.avatarColor, backgroundColor: sidebarVariables.avatarBackgroundColor }}>JD</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={userProfile.firstName && userProfile.lastName ? userProfile.firstName + ' ' + userProfile.lastName : user.name ? user.name : ''}
              secondary={
                <>
                  <Typography sx={{ display: 'block' }} component="div" variant="body2" color="white">
                    {user.email ? user.email : ''}
                  </Typography>
                </>
              }
            />
          </ListItem>
        </Grid>
      </div> */}

      <List sx={{ top: sidebarVariables.sidebarIconsTop, width: '100%', background: sideMenuSettings.backGroundColor }} disablePadding dense>
        <Button
          sx={{
            color: `${sideMenuSettings.iconColor}`,
            ':hover': {
              backgroundColor: sideMenuSettings.iconBackgroundColorHover,
              color: sideMenuSettings.iconColorHover,
            },
            cursor: 'pointer',
            marginBottom: 3,
          }}
          onClick={handleSetHover}
        >
          <div className="flex">{!isHoverEnable ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}</div>
        </Button>

        {sideMenuItems.map((item: any, index: number) => {
          return (
            <React.Fragment key={`${item.name}${index}`}>
              {item.name === 'divider' ? (
                <Divider style={{ color: sideMenuSettings.textColor, margin: '6px 0' }} />
              ) : (
                <SidebarItem
                  depthPadding={depthPadding}
                  depth={depth}
                  item={item}
                  stateDrawer={stateDrawer}
                  setStateDrawer={setStateDrawer}
                  isDrawerText={isDrawerText}
                  isHoverEnable={isHoverEnable}
                />
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: sideMenuSettings.backGroundColor,
        },
      }}
      sx={{ position: 'fixed', zIndex: 100 }}
      slotProps={{ backdrop: { invisible: true } }}
      variant="permanent"
      anchor={'left'}
      open={stateDrawer}
      onClose={handlerDrawerClose(false)}
    >
      {list()}
    </Drawer>
  );
};

export default SideMenu;
