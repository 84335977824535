import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardActions, CardContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Slide, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { homeVariables, API, API_PDFLINK, serverPagination, PDFLINK_INVALID_PIN_INPUT_COUNT, authVariables, INTRO_PAGE, API_PDFLINK_CREATE_USER, ENCRYPT_SALT_PIN } from '../../../utils/variables';
import { PinInput } from 'react-input-pin-code';
import { useAxios } from '../../../logics/useAxios';
import bcrypt from 'bcryptjs';
import axios from 'axios';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { useLogo } from '../../../logics/useLogo';
import { useColors } from '../../../logics/useColors';
import { useReduxDispatch } from '../../../store/Store';
import { homeActions } from '../../../store/appSlices/HomeSlice';
import useTextToSpeech from '../../../logics/useTextToSpeech';
import CloseIcon from '@mui/icons-material/Close';
import { landingPageColors } from '../../../utils/colorVariables';
import backgroundImage from '../../../static/background.jpg';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PdfLink: React.FC<{ showLogo: boolean; setShowLogo: Dispatch<SetStateAction<boolean>> }> = (props) => {
  const { t, i18n } = useTranslation(['pdflink', 'user']);
  const [isInit, setIsInit] = useState(true);
  const [pin, setPin] = useState(['', '', '', '']);
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [isSendHash, setIsSendHash] = useState(false);
  const [isLoadInit, setIsLoadInit] = useState(true);
  const [initPinInputCount, setInitPinInputCount] = useState<number | undefined>(undefined);
  const [initLastPinInputTime, setInitLastPinInputTime] = useState<string | undefined>(undefined);
  const [isOnComplete, SetIsOnComplete] = useState(false);
  const [fetchedData, setFetchedData] = useState<any>(undefined);
  const [invalidPinCount, setInvalidPinCount] = useState(0);
  const [invalidPinCountLimit, setInvalidPinCountLimit] = useState<number | undefined>(undefined);
  const [isPinDisabled, setIsPinDisabled] = useState(false);
  const [enableRegistration, setEnableRegistration] = useState(false);
  const [isRegistration, setIsRegistration] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordRe, setPasswordRe] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRe, setShowPasswordRe] = useState(false);
  const [email, setEmail] = useState('');
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [successCreatedUser, setSuccessCreatedUser] = useState(false);
  const [login, setLogin] = useState('');

  const [searchParams] = useSearchParams();
  const { hash } = useParams();
  const { apiService } = useAxios();
  const dispatch = useReduxDispatch();
  const { handlePlay, handleStop } = useTextToSpeech();
  const { backgroundColor_, backgroundImage_ } = useColors();

  const query = useMemo(() => {
    return searchParams.get('n');
  }, [searchParams]);

  const canvasLogo = useRef<HTMLCanvasElement | null>(null);
  const { canvasAnimate } = useLogo(canvasLogo, props.setShowLogo);

  const {
    mixins: { toolbar },
  } = useTheme();
  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const { tableSettings } = useColors();

  useEffect(() => {
    if (props.showLogo) canvasAnimate();
  }, [canvasAnimate, props.showLogo]);

  useEffect(() => {
    dispatch(homeActions.setPageName('pdflink'));
  }, [dispatch]);

  const onCompletePin = () => {
    if (!isOnComplete) {
      SetIsOnComplete(true);
      setIsSendHash(true);
    }
  };

  useEffect(() => {
    const sendHash = async () => {
      const code = await bcrypt.hash(pin[0] + pin[1] + pin[2] + pin[3], ENCRYPT_SALT_PIN);
      try {
        const response = await axios({
          method: 'GET',
          url: `${API}${API_PDFLINK}/hash/${hash}?n=${query}`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            code: code,
          },
        });

        const data = await response.data;

        if (data.data.error) {
          setIsError(true);
          if (data.data.invalidPinCount) {
            setInvalidPinCount(data.data.invalidPinCount);
          }
          if (data.data.invalidPinCountLimit) {
            setInvalidPinCountLimit(data.data.invalidPinCountLimit);
          }
          if (data.data.isPinDisabled) {
            setIsPinDisabled(data.data.isPinDisabled);
          }
        } else {
          setShowButtons(true);
          const registration = data.data.enableRegistration ? true : false;
          setEnableRegistration(registration);
          setEmail(data.data.email);
          if (data.data.login) setLogin(data.data.login);
        }
        setFetchedData(data);
        if (i18n.language !== data.data.language.toLowerCase()) i18n.changeLanguage(data.data.language.toLowerCase());
      } catch (err: any) {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else if (err.response.data.error) {
          toast.error(err.response.data.error);
        } else if (err.message) {
          toast.error(err.message);
        } else if (err.request) {
          toast.error(err.request.responseText);
        } else {
          throw new Error(`${t('axiosFailed', { ns: ['user'] })}`);
        }
      }
      return true;
    };

    if (isSendHash) {
      if (invalidPinCount < PDFLINK_INVALID_PIN_INPUT_COUNT) {
        sendHash();
        setIsSendHash(false);
      } else {
        toast.error('You have reached view limit of pin input count! Please ask new pdf link!');
      }
    }
  }, [fetchedData, hash, i18n, invalidPinCount, isSendHash, pin, query, t]);

  useEffect(() => {
    if (isCreateUser) {
      const createUser = async () => {
        const pinCode = await bcrypt.hash(pin[0] + pin[1] + pin[2] + pin[3], ENCRYPT_SALT_PIN);
        try {
          const response = await axios({
            method: 'POST',
            url: `${API}${API_PDFLINK_CREATE_USER}`,
            data: { data: password, login: login, profile: { first_name: firstName, last_name: lastName } },
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              code: pinCode,
            },
          });

          const data = await response.data;

          if (!data.data.error) {
            setSuccessCreatedUser(true);
          }
        } catch (err: any) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.response.data.error) {
            toast.error(err.response.data.error);
          } else if (err.message) {
            toast.error(err.message);
          } else if (err.request) {
            toast.error(err.request.responseText);
          } else {
            throw new Error(`${t('axiosFailed', { ns: ['user'] })}`);
          }
        }
        return true;
      };

      createUser();
      setIsCreateUser(false);
    }
  }, [isCreateUser, password, pin, t]);

  const {
    data: loadData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate: mutateInvoices,
  } = useSWR(isLoadInit ? [API + `${API_PDFLINK}/hash/${hash}?n=${query}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  useEffect(() => {
    if (isLoadInit && loadData) {
      if (!serverPagination) {
        setInitPinInputCount(loadData.data.pinInputCount);
        setInitLastPinInputTime(loadData.data.lastPinInputTime);
        setInvalidPinCount(loadData.data.invalidPinCount);
        setInvalidPinCountLimit(loadData.data.invalidPinCountLimit);
        setIsPinDisabled(loadData.data.isPinDisabled);
        if (i18n.language !== loadData.data.language.toLowerCase()) i18n.changeLanguage(loadData.data.language.toLowerCase());
      } else {
        setInitPinInputCount(loadData.results.data.pinInputCount);
        setInitLastPinInputTime(loadData.results.data.lastPinInputTime);
        setInvalidPinCount(loadData.results.data.invalidPinCount);
        setInvalidPinCountLimit(loadData.results.data.invalidPinCountLimit);
        setIsPinDisabled(loadData.results.data.isPinDisabled);
        if (i18n.language !== loadData.results.data.language.toLowerCase()) i18n.changeLanguage(loadData.results.data.language.toLowerCase());
      }
      setIsLoadInit(false);
    }
  }, [i18n, i18n.language, isLoadInit, loadData]);

  const lastPinInputTime = useMemo(() => {
    const time = fetchedData ? fetchedData.data.lastPinInputTime : initLastPinInputTime ? initLastPinInputTime : '';
    const timeFormat = time ? (i18n.language === 'hu' ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : dayjs(time).format('DD/MM/YYYY HH:mm:ss')) : '';
    return timeFormat;
  }, [fetchedData, i18n.language, initLastPinInputTime]);

  const receivedAtTime = useMemo(() => {
    const time = fetchedData && fetchedData.data.receivedAt ? fetchedData.data.receivedAt : '';
    const timeFormat = time ? (i18n.language === 'hu' ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : dayjs(time).format('DD/MM/YYYY HH:mm:ss')) : '';
    return timeFormat;
  }, [fetchedData, i18n.language]);

  const changePin = (values: string[]) => {
    setPin(values);
    SetIsOnComplete(false);
    values.forEach((item) => {
      if (item === '' && isError) {
        setIsError(false);
      }
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const onSubmit = () => {
    if (!password) toast.error(t('pleaseFillPassword', { ns: ['pdflink'] }));
    else if (!passwordRe) toast.error(t('pleaseFillPasswordRe', { ns: ['pdflink'] }));
    else if (password !== passwordRe) toast.error(t('passwordsMustEqual', { nas: ['pdflink'] }));
    else {
      setIsCreateUser(true);
    }
  };

  const handleRegistrationOn = () => {
    setIsRegistration(true);
  };
  const handleRegistrationOff = () => {
    setPassword('');
    setPasswordRe('');
    setIsRegistration(false);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleChangePasswordRe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordRe(event.target.value);
  };
  const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleClickShowPasswordRe = () => {
    setShowPasswordRe((show) => !show);
  };

  const viewPdf = async () => {
    const pdf = await fetch(fetchedData.data.pdf);
    const blobFromFetch = await pdf.blob();
    const blob = new Blob([blobFromFetch], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  };

  const savePdf = async () => {
    const linkSource = await fetchedData.data.pdf;
    const link = document.createElement('a');
    link.href = linkSource;
    const fileName = `pdf_${fetchedData.data.invoiceNumber}.pdf`;
    link.download = fileName;
    link.click();
  };

  return (
    <>
      {props.showLogo ? (
        <div className={'absolute size-full'}>
          <div className={'absolute size-full left-[50%] top-[50%]  -translate-x-1/2  -translate-y-1/2'} id="canvasWrapper">
            <canvas ref={canvasLogo} />
          </div>
        </div>
      ) : (
        <div
          className={'flex flex-col w-full min-h-screen bg-no-repeat bg-cover bg-center bg-fixed '}
          style={{
            backgroundImage: `linear-gradient(${landingPageColors.panelColors.supplierPanel.backgroundColor.lightMode}), url(${backgroundImage})`,
          }}
        >
          <div className={'absolute left-[50%] top-[100px] -translate-x-1/2 '}>
            <Slide
              direction="up"
              in={isInit}
              timeout={homeVariables.signInCardTimeout}
              easing={{
                enter: 'cubic-bezier(0, 1.6, .8, 1)',
              }}
              mountOnEnter
              unmountOnExit
              onExited={() => {}}
            >
              <Card
                sx={{
                  border: 1,
                  borderColor: tableSettings.buttonsColor,
                  width: '100%',
                  maxWidth: !isRegistration ? '700px' : '1500px',
                  minWidth: !isRegistration ? '700px' : '1300px',
                  zIndex: 'tooltip',
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    container
                    item
                    xs={isRegistration ? 6 : 12}
                    direction="column"
                    sx={{ height: '100%', width: '100%', overflowY: 'scroll', borderRight: 1, borderColor: tableSettings.buttonsColor }}
                  >
                    <CardContent>
                      <div className="flex items-center justify-center text-3xl pb-5">Közszámla</div>
                      <div className="flex items-center justify-center text-xl pb-1">{`${t('welcome', { ns: ['pdflink'] })} ${fetchedData && fetchedData.data.partnerName ? fetchedData.data.partnerName : ''} !`}</div>
                      {!isPinDisabled ? (
                        <>
                          <div className="flex items-center justify-center">{showButtons ? t('pleaseSelect', { ns: ['pdflink'] }) : t('pleaseEnterPin', { ns: ['pdflink'] })}</div>
                          <div className={`flex items-center justify-center ${isError ? 'pt-0 text-red-500' : 'pt-6'}`}>{isError ? t('errorPin', { ns: ['pdflink'] }) : ''}</div>
                        </>
                      ) : (
                        <div className="flex items-center justify-center text-red-500">{t('disabledPin', { ns: ['pdflink'] })}</div>
                      )}
                    </CardContent>

                    <CardActions>
                      {!showButtons ? (
                        !isPinDisabled && (
                          <div className="flex mx-auto gap-7 mb-1">
                            <PinInput values={pin} onChange={(value, index, values) => changePin(values)} onComplete={() => onCompletePin()} />
                          </div>
                        )
                      ) : (
                        <div className="flex mx-auto gap-7 mb-1">
                          <button
                            className="bg-transparent text-sky-600 px-4 border border-sky-600 hover:bg-sky-600 hover:text-white hover:border-transparent py-2 hover:scale-110 rounded-full"
                            onClick={viewPdf}
                          >
                            {t('viewPdf', { ns: ['pdflink'] })}
                          </button>
                          <button
                            className="bg-transparent text-sky-600 px-4 border border-sky-600 hover:bg-sky-600 hover:text-white hover:border-transparent py-2 hover:scale-110 rounded-full"
                            onClick={savePdf}
                          >
                            {t('savePdf', { ns: ['pdflink'] })}
                          </button>
                        </div>
                      )}
                    </CardActions>

                    <CardContent>
                      <div className={'flex items-center justify-center '}>
                        {`${t('pinInputCount', { ns: ['pdflink'] })}: ${fetchedData ? fetchedData.data.pinInputCount : initPinInputCount ? initPinInputCount : '0'} ${t('times', { ns: ['pdflink'] })}`}
                      </div>
                      {invalidPinCountLimit && <div className={'flex items-center justify-center '}>{`${t('remainingPinCount', { ns: ['pdflink'] })}: ${invalidPinCountLimit - invalidPinCount}`}</div>}

                      {((fetchedData && fetchedData.data.lastPinInputTime && fetchedData.data.pinInputCount > 0) || (!fetchedData && initPinInputCount && initPinInputCount > 0)) && (
                        <div className="flex items-center justify-center ">{`${t('lastPinInputTime', { ns: ['pdflink'] })}: ${lastPinInputTime}`}</div>
                      )}

                      {receivedAtTime && <div className={'flex items-center justify-center pb-5'}>{`${t('receivedAt', { ns: ['pdflink'] })}: ${receivedAtTime}`}</div>}

                      {enableRegistration && (
                        <div className="flex flex-col items-center justify-center">
                          <div className="text-center text-xl pb-3">{t('registrationText', { ns: ['pdflink'] })}</div>
                          <div className="text-center text-m pb-3">{t('registrationText_', { ns: ['pdflink'] })}</div>
                          <Button onClick={handleRegistrationOn} onMouseEnter={() => handlePlay(t('registration', { ns: ['pdflink'] }))} onMouseLeave={() => handleStop()}>
                            <span className="bg-transparent text-sky-600 px-4 border border-sky-600 hover:bg-sky-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                              {t('registration', { ns: ['pdflink'] })}
                            </span>
                          </Button>
                        </div>
                      )}
                    </CardContent>
                  </Grid>

                  {isRegistration && (
                    <Grid container item xs={6} direction="column" sx={{ height: '100%', overflowY: 'scroll' }}>
                      <div className="flex items-center justify-end " onMouseEnter={() => handlePlay(t('cancel', { ns: ['home'] }))} onMouseLeave={() => handleStop()}>
                        <CloseIcon className="mt-5 mr-5 hover:bg-sky-600 hover:text-white hover:scale-110 rounded-full cursor-pointer" onClick={handleRegistrationOff} />
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-xl pb-5">{t('registration', { ns: ['pdflink'] })}</span>
                        {!successCreatedUser && (
                          <Stack component="form" sx={{ paddingBottom: 1 }} spacing={2} noValidate autoComplete="off">
                            <div className="flex flex-col items-center justify-center">
                              <span className="pb-1 font-semibold">
                                {t('login', { ns: ['pdflink'] })}: {login}
                              </span>

                              <span className="pb-1 font-semibold">
                                {t('loginEmail', { ns: ['pdflink'] })}: {email}
                              </span>
                              {/* <TextField id="filled-hidden-label-small" sx={{ width: '40ch' }} label="LoginEmail" defaultValue={email} size="small" disabled={true} /> */}
                              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">{t('password', { ns: ['pdflink'] })}</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  type={showPassword ? 'text' : 'password'}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton aria-label={showPassword ? 'hide the password' : 'display the password'} onClick={handleClickShowPassword} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label={t('password', { ns: ['pdflink'] })}
                                  value={password}
                                  onChange={handleChangePassword}
                                  size="small"
                                />
                              </FormControl>
                              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">{t('passwordRe', { ns: ['pdflink'] })}</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  type={showPasswordRe ? 'text' : 'password'}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton aria-label={showPasswordRe ? 'hide the password' : 'display the password'} onClick={handleClickShowPasswordRe} edge="end">
                                        {showPasswordRe ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label={t('passwordRe', { ns: ['pdflink'] })}
                                  value={passwordRe}
                                  onChange={handleChangePasswordRe}
                                  size="small"
                                />
                              </FormControl>
                              <TextField
                                id="filled-hidden-label-small"
                                sx={{ m: 1, width: '40ch' }}
                                label={t('firstName', { ns: ['pdflink'] })}
                                defaultValue={firstName}
                                onChange={handleChangeFirstName}
                                size="small"
                              />
                              <TextField
                                id="filled-hidden-label-small"
                                sx={{ m: 1, width: '40ch' }}
                                label={t('lastName', { ns: ['pdflink'] })}
                                defaultValue={lastName}
                                onChange={handleChangeLastName}
                                size="small"
                              />

                              <span className="text-l">
                                {t('toCreateUser', {
                                  minChar: authVariables.userPasswordMinCharacter,
                                  minDigit: authVariables.userPasswordMinDigit,
                                  minUpper: authVariables.userPasswordMinUpper,
                                  ns: ['user'],
                                })}
                              </span>
                            </div>
                          </Stack>
                        )}

                        {!successCreatedUser && (
                          <CardActions sx={{ paddingBottom: 3, color: tableSettings.titleTextColor, backgroundColor: backgroundColor_ }}>
                            <Typography component={'div'} sx={{ color: tableSettings.titleTextColor, backgroundColor: backgroundColor_ }}>
                              <Button onClick={onSubmit} onMouseEnter={() => handlePlay(t('submit', { ns: ['pdflink'] }))} onMouseLeave={() => handleStop()}>
                                <span className="bg-transparent text-sky-600 px-4 border border-sky-600 hover:bg-sky-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                                  {t('submit', { ns: ['pdflink'] })}
                                </span>
                              </Button>
                            </Typography>
                          </CardActions>
                        )}
                        {successCreatedUser && (
                          <>
                            <span className="text-xl pb-3">{t('successfullRegistration', { ns: ['pdflink'] })}</span>
                            <span className="text-xl pb-3">{t('registrationContent', { ns: ['pdflink'] })}</span>
                            <span className="pb-5 text-xl ">{email}</span>
                            <a className="text-xl text-sky-600" href={INTRO_PAGE} target="_blank" rel="noopener noreferrer">
                              {INTRO_PAGE}
                            </a>
                          </>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Slide>
          </div>
        </div>
      )}

      {isValid ? (
        <div className="bg-transparent h-full">
          <div className="mt-2 flex gap-5 items-center">
            <div className="m-auto" style={{ color: tableSettings.titleTextColor }}>
              <div> {t('pdfLink', { ns: ['pdflink'] })}</div>
              <div>{hash}</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PdfLink;
