import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterItemType } from '../../../types/Table.type';
import { usePermissions } from '../../../../logics/usePermissions';
import useTextToSpeech from '../../../../logics/useTextToSpeech';
import { useReduxDispatch, useReduxSelector } from '../../../../store/Store';
import { useAxios } from '../../../../logics/useAxios';
import { useColors } from '../../../../logics/useColors';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import useSWR from 'swr';
import { API_DATA, API_LIST_EMAILTEMPLATES_S, serverPagination, tableVariables } from '../../../../utils/variables';
import { useFilters } from '../../../../logics/useFilters';
import { tableActions } from '../../../../store/appSlices/TableSlice';
import { homeActions } from '../../../../store/appSlices/HomeSlice';
import { Refresh } from '@mui/icons-material';
import { useEmailTemplate } from '../../../../logics/useEmailTemplate';
import { TableMain } from '../../../UI/Table/TableMain';
import ConfirmModal from '../../../UI/ConfirmModal';
import { EditEmailTemplateModal } from '../../../UI/EditEmailTemplateModal';
import { ListJsonModal } from '../../../UI/ListJsonModal';
import EmailTemplateViewLogoModal from '../../../UI/EmailTemplateViewLogoModal';
import { useDataColumnsEmailTemplate_ } from '../../../fields/supplierPagesFields/emailTemplate/dataColumns';

const EmailTemplates_: React.FC = () => {
  const { i18n, t } = useTranslation(['emailTemplates']);
  const [Filters, setFilters] = useState<FilterItemType[]>([]);
  const isEmailTemplateEditModalOpen_ = useReduxSelector((state) => state.table.isEmailTemplateEditModalOpen_);
  const isEmailTemplateCreateModalOpen_ = useReduxSelector((state) => state.table.isEmailTemplateCreateModalOpen_);
  const isEmailTemplateDeleteModalOpen_ = useReduxSelector((state) => state.table.isEmailTemplateDeleteModalOpen_);
  const isEmailTemplateViewModalOpen_ = useReduxSelector((state) => state.table.isEmailTemplateViewModalOpen_);
  const isEmailTemplateSamleViewModalOpen_ = useReduxSelector((state) => state.table.isEmailTemplateSampleViewModalOpen_);
  const isEmailTemplateViewLogoModalOpen = useReduxSelector((state) => state.table.isEmailTemplateViewLogoModalOpen);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const { isSupplierUser, userSupplierId, userSupplierName } = usePermissions();
  const dispatch = useReduxDispatch();
  const { handlePlay, handleStop } = useTextToSpeech();

  const { apiService } = useAxios();
  const rowId = useReduxSelector((state) => state.table.rowId);

  const {
    mixins: { toolbar },
  } = useTheme();

  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const { tableSettings } = useColors();

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate: mutateEmailTemplates,
  } = useSWR(isSupplierUser ? [`${API_DATA}${API_LIST_EMAILTEMPLATES_S}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const data = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  const { dataColumns } = useDataColumnsEmailTemplate_();

  const { fetchedData_filtered } = useFilters({ Filters: Filters, Columns: dataColumns, fetchedData: data });
  const {
    handleOpenCreateModal,
    handleCloseCreate,
    handleCloseCreateModal,
    handleCancelCreate,
    onSubmitCreateProp,
    handleOpenEditModal,
    handleCloseEdit,
    handleCloseEditModal,
    handleCancelEdit,
    onSubmitEditProp,
    handleOpenDeleteModal,
    handleCloseDelete,
    handleCloseDeleteModal,
    handleCancelDelete,
    onDelete,
  } = useEmailTemplate({ mutateEmailTemplates });

  useEffect(() => {
    dispatch(tableActions.setIsEmailTemplateCreateModalOpen_(false));
    dispatch(tableActions.setIsEmailTemplateEditModalOpen_(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(homeActions.setPageName('emailtemplates_'));
  }, [dispatch]);

  const contentText = useMemo(() => {
    if (rowId) {
      const data_obj = data.find((item: any) => item.id === rowId);
      if (data_obj) {
        if (data_obj.supplier && data_obj.supplier.configEmail) {
          if (data_obj.supplier.configEmail.default_config_template_hu === rowId) return t('toDeleteEmailTemplateBaseHu', { ns: ['emailTemplate'] });
          if (data_obj.supplier.configEmail.default_config_template_en === rowId) return t('toDeleteEmailTemplateBaseEn', { ns: ['emailTemplate'] });
          return t('toDeleteEmailTemplate', { ns: ['emailTemplate'] });
        } else return t('toDeleteEmailTemplate', { ns: ['emailTemplate'] });
      } else return t('toDeleteEmailTemplate', { ns: ['emailTemplate'] });
    } else return t('toDeleteEmailTemplate', { ns: ['emailTemplate'] });
  }, [data, rowId, t]);

  return isSupplierUser ? (
    <>
      <div className="bg-transparent h-full" style={{ marginLeft: isUserSignedIn && isSupplierUser ? 50 : '' }}>
        <div className="mt-2 flex gap-5 items-center">
          <Tooltip arrow title={t('refreshData', { ns: ['table'] })}>
            <IconButton onClick={() => mutateEmailTemplates()} onMouseEnter={() => handlePlay(t('refreshData', { ns: ['table'] }))} onMouseLeave={() => handleStop()}>
              <Refresh sx={{ fontSize: 30, color: tableSettings.buttonsColor, '&:hover': { scale: '1.5' } }} />
            </IconButton>
          </Tooltip>

          {/* <SelectAutoWidth selectLabel={selectLabel!} selectValue={invoiceType} onSelect={setInvoiceType} selectOptions={selectOptions!} minHeight={tableVariables.selectHeight} />

            {isSupportUser && invoiceSupplier && (
              <SelectSearch
                selectLabel={t('supplier', { ns: ['invoice'] })}
                selectValue={invoiceSupplier}
                onSelect={setInvoiceSupplier}
                rounded={true}
                minWidth={250}
                minHeight={tableVariables.selectHeight}
                async
                apiLink={API_SELECTLIST_SUPPLIERS}
                initSelectOptions={{ id: '0', label: t('allSuppliers', { ns: ['invoice'] }) }}
                defaultSelectOptions={userSupplierId && userSupplierName ? { id: userSupplierId, label: userSupplierName } : { id: '0', label: t('allSuppliers', { ns: ['invoice'] }) }}
                nonEditable={isSupplierUser}
              />
            )}

            {(isSupportUser || isSupplierUser) && invoiceSupplier && (
              <SelectSearch
                selectLabel={t('supplierPartner', { ns: ['invoice'] })}
                selectValue={invoicePartner}
                onSelect={setInvoicePartner}
                rounded={true}
                minWidth={250}
                minHeight={tableVariables.selectHeight}
                async
                apiLink={`${API_SELECTLIST_SUPPLIER_PARTNER}/${invoiceSupplier}`}
                initSelectOptions={{ id: '0', label: t('allPartners', { ns: ['invoice'] }) }}
                defaultSelectOptions={{ id: '0', label: t('allPartners', { ns: ['invoice'] }) }}
              />
            )} */}

          {/* <TableFilter Columns={dataColumns} Filters={Filters} setFilters={setFilters} /> */}

          <div className="m-auto" style={{ color: tableSettings.titleTextColor }}>
            {t('emailTemplatesTitle', { ns: ['emailTemplate'] })}
          </div>
        </div>
        <>
          <div className={` flex justify-items-center h-[calc(100%-${appHeight}px)]`}>
            <div className={`w-[100%] absolute left-[50%] top-[calc(${appHeight}px)] -translate-x-1/2`}>
              <TableMain
                rows={fetchedData_filtered}
                isLoadingData={isLoadingData}
                isFetchingData={isFetchingData}
                dataColumns={dataColumns}
                selectId={'id'}
                handleOpenEditModal={handleOpenEditModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
                mutate={mutateEmailTemplates}
                apiValue="emailtemplates_"
                translation={'emailTemplate'}
              />
            </div>
          </div>

          {isEmailTemplateEditModalOpen_ && <EditEmailTemplateModal mode={1} onSubmitCreateProp={onSubmitCreateProp} onSubmitEditProp={onSubmitEditProp} />}

          {isEmailTemplateDeleteModalOpen_ && (
            <ConfirmModal
              openModal={isEmailTemplateDeleteModalOpen_}
              handleCloseModal={handleCloseDeleteModal}
              handleClose={handleCloseDelete}
              handleCancel={handleCancelDelete}
              onConfirmProp={onDelete}
              modalTitle={t('deleteEmailTemplateTitle', { ns: ['emailTemplate'] })}
              contentText={contentText}
              confirmText={t('delete', { ns: ['emailTemplate'] })}
              translation="emailTemplate"
            />
          )}

          {isEmailTemplateViewModalOpen_ && <EditEmailTemplateModal mode={0} />}
          {isEmailTemplateSamleViewModalOpen_ && <ListJsonModal modal={1} title={t('listSampleTitle', { ns: ['emailTemplate'] })} translation="emailTemplate" />}
          {isEmailTemplateViewLogoModalOpen && <EmailTemplateViewLogoModal />}
        </>
      </div>
    </>
  ) : (
    <></>
  );
};

export default EmailTemplates_;
