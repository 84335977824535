import React, { MouseEvent, useMemo } from 'react';
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Typography, styled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { DataColumnsProp } from '../../types/Table.type';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from '../../../store/Store';
import { tableVariables } from '../../../utils/variables';
import { useColors } from '../../../logics/useColors';

type Order = 'asc' | 'desc';

type TableHeadComponentProps = {
  dataColumns: DataColumnsProp[];
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  hasMenu: boolean;
  hasSelect: boolean;
};

export const TableHeadComponent: React.FC<TableHeadComponentProps> = ({ dataColumns, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, hasMenu, hasSelect }) => {
  const { t } = useTranslation(['table']);
  const { backgroundColor_, tableSettings } = useColors();

  const createSortHandler = (property: string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const StyledTableCell = styled(TableCell)({
    paddingTop: 0,
    paddingBottom: 0,
    borderBottom: '1px solid grey',
  });

  return (
    <TableHead sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_, border: '0px solid grey' }}>
      <TableRow sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}>
        {hasMenu && (
          <StyledTableCell sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}>
            <Typography></Typography>
          </StyledTableCell>
        )}
        {hasSelect && (
          <StyledTableCell padding="checkbox" style={{ position: 'sticky', left: 0, zIndex: '9999', color: tableSettings.textColor, backgroundColor: backgroundColor_ }}>
            <Checkbox
              sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
              // color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all rows',
              }}
            />
          </StyledTableCell>
        )}
        {dataColumns.map(
          (item) =>
            !item.hidden && (
              // <Tooltip sx={{ color: textColor, backgroundColor: backgroundColor }} key={item.id} title={t('clickToSort', { ns: ['table'] })}>
              <StyledTableCell
                key={item.id}
                sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                align={item.type === 'number' ? 'right' : 'left'}
                sortDirection={orderBy === item.id ? order : false}
                style={item.width ? { minWidth: item.width } : {}}
              >
                <TableSortLabel
                  sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                  active={orderBy === item.id}
                  direction={orderBy === item.id ? order : 'asc'}
                  onClick={createSortHandler(item.id)}
                >
                  <Typography sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_, fontSize: '15px', fontWeight: 600 }}>{item.label}</Typography>
                  {/* <span className="font-semibold">{item.label}</span> */}
                  {orderBy === item.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
              // </Tooltip>
            ),
        )}
      </TableRow>
    </TableHead>
  );
};
