import React, { useMemo } from 'react';
import { useColors } from '../logics/useColors';
import { sidebarVariables } from '../utils/variables';
import { useReduxSelector } from '../store/Store';
import { usePermissions } from '../logics/usePermissions';

export type LayoutProps = {
  showLogo: boolean;
  children?: React.ReactNode;
};

export const Layout: React.FunctionComponent<LayoutProps> = ({ showLogo, children }) => {
  const { backgroundColor_, backgroundImage_ } = useColors();
  const backgroundColor = useMemo(() => (showLogo ? '#ffffff' : backgroundColor_), [backgroundColor_, showLogo]);
  const backgroundImage = useMemo(() => (showLogo ? undefined : backgroundImage_), [backgroundImage_, showLogo]);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const { isSupportUser, isSupplierUser, isCustomerUser } = usePermissions();

  return (
    <div
      className={`flex flex-col w-full min-h-screen bg-no-repeat bg-cover bg-center bg-fixed ${!showLogo ? 'mt-[50px]' : ''}`}
      style={{
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage,
        marginLeft: isUserSignedIn && (isSupplierUser || isCustomerUser) ? sidebarVariables.marginLeft : '',
        position: 'absolute',
      }}
    >
      <main className="">{children}</main>
    </div>
  );
};
