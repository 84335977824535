import React, { useMemo } from 'react';
import { authVariables, regExpVariables } from '../../../../utils/variables';
import { useTranslation } from 'react-i18next';
import { validateContainsDigit, validateContainsUpper, validateMinLen, validateMinMaxLen } from '../../../../utils/common';

export const useCreateUserFields_ = () => {
  const { t } = useTranslation(['user']);
  const roleSelectsSupplier = useMemo(
    () => [
      { id: '0', label: t('manager', { ns: ['user'] }) },
      { id: '1', label: t('administrator', { ns: ['user'] }) },
    ],
    [t],
  );

  const fields = [
    {
      label: t('baseUserData', { ns: ['user'] }),
      items: [
        {
          name: 'name',
          type: 'text',
          validate: {
            minLen: validateMinMaxLen(
              authVariables.userNameMinCharacter,
              authVariables.userNameMaxCharacter,
              t('userMinMaxLen', { min: authVariables.userNameMinCharacter, max: authVariables.userNameMaxCharacter, ns: ['user'] }),
            ),
          },
        },
        { name: 'email', type: 'text', pattern: regExpVariables.emailPattern },
        {
          name: 'password',
          type: 'password',
          validate: {
            minLen: validateMinLen(authVariables.userPasswordMinCharacter, t('passwordMinLen', { min: authVariables.userPasswordMinCharacter, ns: ['user'] })),
            containsDigit: validateContainsDigit(authVariables.userPasswordMinDigit, t('passwordDigit', { min: authVariables.userPasswordMinDigit, ns: ['user'] })),
            containsUpper: validateContainsUpper(authVariables.userPasswordMinUpper, t('passwordUpper', { min: authVariables.userPasswordMinUpper, ns: ['user'] })),
          },
        },
        {
          name: 'passwordRe',
          type: 'password',
          validate: {
            equalField: ['password', t('passwordEqual', { ns: ['user'] })],
          },
        },
        { name: 'isActive', type: 'boolean', required: false },
      ],
    },
  ];

  const supplierUserProfileFields = [
    {
      label: t('supplierProfileUserData', { ns: ['user'] }),
      items: [
        {
          name: 'firstName',
          type: 'text',
          required: false,
        },
        {
          name: 'lastName',
          type: 'text',
          required: false,
        },
        {
          name: 'customEmail',
          type: 'text',
          pattern: regExpVariables.emailPattern,
          required: false,
        },
        { name: 'role', type: 'select', selectOptions: roleSelectsSupplier, selectSearch: true, nonEditable: false },
      ],
    },
  ];
  return { fields, supplierUserProfileFields };
};
