import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { IS_CUSTOM_EMAIL_BOX, IS_USE_NAV_CONNECTOR } from '../../../utils/variables';
import { useReduxDispatch } from '../../../store/Store';
import { tableActions } from '../../../store/appSlices/TableSlice';
import { DataColumnsProp } from '../../types/Table.type';
import { useColors } from '../../../logics/useColors';

export const useDataColumnsSupplier = () => {
  const { t } = useTranslation(['supplier', 'partner']);
  const dispatch = useReduxDispatch();
  const { tableSettings } = useColors();

  const handleClickPartners = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowId: string) => {
    e.preventDefault();
    dispatch(tableActions.setRowId(rowId));
    dispatch(tableActions.setIsPartnersListModalOpen(true));
  };

  const handleClickEmailTemplate = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, customLogo: string) => {
    e.preventDefault();
    dispatch(tableActions.setRowEmailTemplateId(id));
    dispatch(tableActions.setIsEmailTemplateViewModalOpen(true));
    dispatch(tableActions.setRowCustomLogo(customLogo));
  };

  const handleClickLogo = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, logo: object) => {
    e.preventDefault();
    dispatch(tableActions.setRowCustom({ src: logo }));
    dispatch(tableActions.setIsEmailTemplateViewLogoModalOpen(true));
  };

  const selectOptions = [{ id: '0', label: t('allSuppliers', { ns: ['supplier'] }) }];

  const dataColumns: DataColumnsProp[] = [
    { id: 'id', label: t('id', { ns: ['supplier'] }), type: 'number' },
    { id: 'name', label: t('name', { ns: ['supplier'] }), type: 'string' },
    { id: 'address', label: t('address', { ns: ['supplier'] }), type: 'string' },
    { id: 'email', label: t('email', { ns: ['supplier'] }), type: 'string' },
    { id: 'tax_number', label: t('taxNumber', { ns: ['supplier'] }), type: 'string' },
    { id: 'eu_tax_number', label: t('euTaxNumber', { ns: ['supplier'] }), type: 'string' },
    { id: 'group_tax_number', label: t('groupTaxNumber', { ns: ['supplier'] }), type: 'string' },
    {
      id: 'supplier_partner',
      label: t('supplierPartner_', { ns: ['supplier'] }),
      type: 'string',
      component: (row) => {
        if (row.number_of_invoices > 0) {
          return (
            <Button size="small" sx={{ textTransform: 'capitalize' }} onClick={(e) => handleClickPartners(e, row.id.toString())}>
              <span className={`bg-transparent ${tableSettings.buttonsColorText}`}>{t('supplierPartner_', { ns: ['supplier'] }).toUpperCase()}</span>
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    // {
    //   id: 'supplier_invoicenumber',
    //   label: t('supplierInvoiceNumber', { ns: ['supplier'] }),
    //   type: 'string',
    //   component: (row) => {
    //     return (
    //       <Button size="small" sx={{ textTransform: 'capitalize' }} onClick={(e) => handleClick(e, row.id.toString())}>
    //         <span className={`bg-transparent ${tableVariables.buttonsColorText} text-nowrap`}>{t('invoiceNumbers', { ns: ['supplier'] }).toUpperCase()}</span>
    //       </Button>
    //     );
    //   },
    // },
    { id: 'number_of_invoices', label: t('numberOfInvoices', { ns: ['supplier'] }), type: 'number' },
    { id: 'code', label: t('code', { ns: ['supplier'] }), type: 'string' },
  ];

  const DataColumnsConfigEmail_: DataColumnsProp[] = [
    { id: 'id', label: t('id', { ns: ['supplier'] }), type: 'number', hidden: true },
    { id: 'is_use_config_email', label: t('isUseConfigEmail_', { ns: ['supplier'] }), type: 'boolean' },
    { id: 'config_email', label: t('configEmail_', { ns: ['supplier'] }), type: 'string' },
    { id: 'config_password', label: t('configPassword_', { ns: ['supplier'] }), type: 'string' },
    { id: 'config_sender', label: t('configSender_', { ns: ['supplier'] }), type: 'string' },
    { id: 'is_use_config_template', label: t('isUseConfigTemplate_', { ns: ['supplier'] }), type: 'boolean' },
    {
      id: 'default_config_template_hu',
      label: t('defaultConfigTemplateHu_', { ns: ['supplier'] }),
      type: 'string',
      component: (row) => {
        return (
          <Button size="small" sx={{ textTransform: 'capitalize' }} onClick={(e) => handleClickEmailTemplate(e, row.default_config_template_hu, row.logo)}>
            <span className={`bg-transparent ${tableSettings.buttonsColorText} text-nowrap`}>{row.default_config_template_hu_name.toUpperCase()}</span>
          </Button>
        );
      },
    },
    {
      id: 'default_config_template_en',
      label: t('defaultConfigTemplateEn_', { ns: ['supplier'] }),
      type: 'string',
      component: (row) => {
        return (
          <Button size="small" sx={{ textTransform: 'capitalize' }} onClick={(e) => handleClickEmailTemplate(e, row.default_config_template_en, row.logo)}>
            <span className={`bg-transparent ${tableSettings.buttonsColorText} text-nowrap`}>{row.default_config_template_en_name.toUpperCase()}</span>
          </Button>
        );
      },
    },
    { id: 'is_use_custom_logo', label: t('isUseCustomLogo_', { ns: ['supplier'] }), type: 'boolean' },
    {
      id: 'logo',
      label: t('logo', { ns: ['supplier'] }),
      type: 'string',
      component: (row) => {
        if (row.logo) {
          return (
            <Button size="small" sx={{ textTransform: 'uppercase' }} onClick={(e) => handleClickLogo(e, row.logo)}>
              <img className="m-auto h-auto max-w-[100px] transition-all duration-300 hover:cursor-zoom-in" src={row.logo} alt="logo"></img>;
              {/* <img className="m-auto h-auto max-w-[100px] transition-all duration-300 hover:grayscale-0 hover:max-w-xs hover:cursor-zoom-in" src={row.logo} alt="logo"></img>; */}
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    { id: 'is_send_undelivered_info_email', label: t('isSendUndeliveredInfoEmail_', { ns: ['supplier'] }), type: 'boolean' },
    { id: 'info_undelivered_email', label: t('infoUndeliveredEmail_', { ns: ['supplier'] }), type: 'string' },
    { id: 'default_config_template_hu_name', label: t('defaultConfigTemplateHuName_', { ns: ['supplier'] }), type: 'string', hidden: true },
    { id: 'default_config_template_en_name', label: t('defaultConfigTemplateEnName_', { ns: ['supplier'] }), type: 'string', hidden: true },

    { id: 'is_use_invoice_store', label: t('isUseInvoiceStore_', { ns: ['supplier'] }), type: 'boolean' },
    { id: 'registered_invoice_store_sender_email', label: t('registeredInvoiceStoreSenderEmail_', { ns: ['supplier'] }), type: 'string' },
    { id: 'is_send_invoice_store_info_email', label: t('isSendInvoiceStoreInfoEmail_', { ns: ['supplier'] }), type: 'boolean' },
    { id: 'info_invoice_store_email', label: t('infoInvoiceStoreEmail_', { ns: ['supplier'] }), type: 'string' },

    { id: 'is_use_email_not_receiving', label: t('isUseEmailNotReceiving_', { ns: ['supplier'] }), type: 'boolean' },
    { id: 'send_days_from_email_not_receiving', label: t('sendDaysFromEmailNotReceiving_', { ns: ['supplier'] }), type: 'string' },
    { id: 'max_number_of_sent_email_not_receiving', label: t('maxNumberOfSentEmailNotReceiving_', { ns: ['supplier'] }), type: 'string' },
    { id: 'email_not_receiving_sending_days', label: t('emailNotReceivingSendingDays_', { ns: ['supplier'] }), type: 'string' },

    { id: 'is_use_nav_connector', label: t('isUseNavConnector_', { ns: ['supplier'] }), type: 'boolean' },
    { id: 'nav_user_login', label: t('navUserLogin_', { ns: ['supplier'] }), type: 'string' },
    { id: 'nav_user_password', label: t('navUserPassword_', { ns: ['supplier'] }), type: 'string' },
    { id: 'nav_user_tax_number', label: t('navUserTaxNumber_', { ns: ['supplier'] }), type: 'string' },
    { id: 'nav_user_signature_key', label: t('navUserSignatureKey_', { ns: ['supplier'] }), type: 'string' },
    { id: 'nav_user_exchange_key', label: t('navUserExchangeKey_', { ns: ['supplier'] }), type: 'string' },
    { id: 'supplier_id', label: t('supplierId', { ns: ['supplier'] }), type: 'string', hidden: true },
  ];

  const navConnectorFields = ['is_use_nav_connector', 'nav_user_login', 'nav_user_password', 'nav_user_tax_number', 'nav_user_signature_key', 'nav_user_exchange_key'];
  const customEmailBoxFields = ['is_use_config_email', 'config_email', 'config_password', 'config_sender'];
  const DataColumnsConfigEmail = [];

  for (const item of DataColumnsConfigEmail_) {
    if (navConnectorFields.includes(item.id)) {
      if (IS_USE_NAV_CONNECTOR) DataColumnsConfigEmail.push(item);
    } else {
      if (customEmailBoxFields.includes(item.id)) {
        if (IS_CUSTOM_EMAIL_BOX) DataColumnsConfigEmail.push(item);
      } else {
        DataColumnsConfigEmail.push(item);
      }
    }
  }

  return { selectOptions, dataColumns, DataColumnsConfigEmail };
};
