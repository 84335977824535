import React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { SvgIconPropsSizeOverrides } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';

export const sidebarVariables = {
  depthPadding: 20,
  sidebarWidth: 200,
  sidebarWidthIcons: 45,
  sidebarIconsTop: 80,
  gridHeight: 130,
  listItemPadding: 1,
  listItemPaddingDepth: 5,
  iconsColor: 'primary',

  backgrounColor: '#2196f3',
  backgroundImage: "url('https://images.unsplash.com/photo-1531315630201-bb15abeb1653?ixlib=rb-1.2.1&auto=format&fit=crop&w=375&q=80')",
  avatarBackgroundColor: '#7e57c2',
  avatarColor: '#fff',
  marginLeft: '50',
};

export const appbarVariables = {
  userIconsize: 'large' as OverridableStringUnion<'large' | 'small' | 'inherit' | 'medium', SvgIconPropsSizeOverrides> | undefined,
  selectedIconColor: 'yellow',
  border: 1,
};

export const homeVariables = {
  signInCardWidth: 450,
  signInCardTimeout: 1500,
  supportPanelCardWidth: 345,
};

export const authVariables = {
  userNameMinCharacter: 5,
  userNameMaxCharacter: 16,
  userPasswordMinCharacter: 7,
  userPasswordMinDigit: 1,
  userPasswordMinUpper: 1,
};

export const regExpVariables = {
  emailPattern: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9.-]+\.)+[A-Za-z]{2,}$/,
  postalCodePattern: /^[0-9]{4}$/,
  taxNumberPattern: /^[0-9]{8}-{0,1}[0-9]{1}-{0,1}[0-9]{2}$/,
  euTaxNumberPattern: /^[A-Z]{2}[0-9]{8}$/,
  invoiceNumberTextPattern: /^[a-zA-Z]{0,7}$/,
  numberPattern: /^\d*$/,
  numberNoFirstZeroPattern: /^[1-9][0-9]*$/,
};

export const INIT_LIGHT_MODE = true;
export const mobileSizeWidth = 600;
export const szamlakozpontSupplierId = '1';
export const serverPagination = false;
export const serverPageSize = 10;

export const SUPPORT_USER_TYPE = 'support'; // support user type name in db
export const SUPPLIER_USER_TYPE = 'supplier'; // supplier user type name in db
export const CUSTOMER_USER_TYPE = 'customer'; // customer user type name in db
export const PDFLINK_INVALID_PIN_INPUT_COUNT = 3;

export const NESTJS_HTTPS = process.env.REACT_APP_NESTJS_HTTPS === 'true';

// FIX IP
export const FIXIP_SUPPORT = process.env.REACT_APP_FIXIP_SUPPORT;
export const FIXIP_SUPPLIER = process.env.REACT_APP_FIXIP_SUPPLIER;
export const FIXIP_CUSTOMER = process.env.REACT_APP_FIXIP_CUSTOMER;

// API
export const API =
  `${process.env.REACT_APP_NESTJS_HTTPS === 'true' ? 'https://' : 'http://'}${process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true' ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API}` as string;
export const API_AUTH =
  `${process.env.REACT_APP_NESTJS_HTTPS === 'true' ? 'https://' : 'http://'}${process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true' ? process.env.REACT_APP_DEV_API_AUTH : process.env.REACT_APP_PROD_API_AUTH}` as string;
export const API_DATA =
  `${process.env.REACT_APP_NESTJS_HTTPS === 'true' ? 'https://' : 'http://'}${process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true' ? process.env.REACT_APP_DEV_API_DATA : process.env.REACT_APP_PROD_API_DATA}` as string;

// AUTH
export const API_USER_SIGN_IN = process.env.REACT_APP_USER_SIGN_IN;
export const API_USER_SIGN_IN_TFA = process.env.REACT_APP_USER_SIGN_IN_TFA;
export const API_USER_SIGN_OUT = process.env.REACT_APP_USER_SIGN_OUT;

// SUPPLIERS
export const API_LIST_SUPPLIERS = process.env.REACT_APP_LIST_SUPPLIERS;
export const API_CREATE_SUPPLIER = process.env.REACT_APP_CREATE_SUPPLIER;
export const API_LIST_PARTNERS_OF_SUPPLIER = process.env.REACT_APP_LIST_PARTNERS_OF_SUPPLIER;
export const API_UPDATE_SUPPLIER = process.env.REACT_APP_UPDATE_SUPPLIER;
export const API_DELETE_SUPPLIER = process.env.REACT_APP_DELETE_SUPPLIER;
export const API_LIST_SUPPLIER = process.env.REACT_APP_LIST_SUPPLIER;
export const API_SELECTLIST_SUPPLIERS = process.env.REACT_APP_SELECTLIST_SUPPLIERS;
export const API_SELECTLIST_SUPPLIER_PARTNERS = process.env.REACT_APP_SELECTLIST_SUPPLIER_PARTNERS;
export const API_LIST_SUPPLIER_S = process.env.REACT_APP_LIST_SUPPLIER_S;
export const API_SELECTLIST_SUPPLIER_PARTNERS_S = process.env.REACT_APP_SELECTLIST_SUPPLIER_PARTNERS_S;
export const API_LIST_SUPPLIERS_S = process.env.REACT_APP_LIST_SUPPLIERS_S;
export const API_UPDATE_SUPPLIER_S = process.env.REACT_APP_UPDATE_SUPPLIER_S;

// INVOICES
export const API_LIST_INVOICES = process.env.REACT_APP_LIST_INVOICES;
export const API_DELETE_INVOICE = process.env.REACT_APP_DELETE_INVOICE;
export const API_UPDATE_INVOICE = process.env.REACT_APP_UPDATE_INVOICE;
export const API_CREATE_INVOICE = process.env.REACT_APP_CREATE_INVOICE;
export const API_LIST_INVOICES_S = process.env.REACT_APP_LIST_INVOICES_S;

// FILEVIEW
export const API_PDF_FILEVIEW = process.env.REACT_APP_PDF_FILEVIEW;
export const API_ATTACHMENT_FILEVIEW = process.env.REACT_APP_ATTACHMENT_FILEVIEW;

// PARTNERS
export const API_LIST_PARTNERS = process.env.REACT_APP_LIST_PARTNERS;
export const API_LIST_INVOICES_OF_PARTNER = process.env.REACT_APP_LIST_INVOICES_OF_PARTNER;
export const API_LIST_PARTNERS_S = process.env.REACT_APP_LIST_PARTNERS_S;

// USERS
export const API_LIST_USERS = process.env.REACT_APP_LIST_USERS;
export const API_CREATE_USER = process.env.REACT_APP_CREATE_USER;
export const API_DELETE_USER = process.env.REACT_APP_DELETE_USER;
export const API_LIST_SUPPLIER_USERS = process.env.REACT_APP_LIST_SUPPLIER_USERS;
export const API_LIST_SUPPLIER_USERS_S = process.env.REACT_APP_LIST_SUPPLIER_USERS_S;
export const API_LIST_USERS_S = process.env.REACT_APP_LIST_USERS_S;
export const API_CREATE_USER_S = process.env.REACT_APP_CREATE_USER_S;

// PDFLINK
export const API_PDFLINK = process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true' ? process.env.REACT_APP_PDFLINK_DEV_LOCAL : (process.env.REACT_APP_PDFLINK as string);
export const API_PDFLINK_CREATE_USER = process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true' ? process.env.REACT_APP_PDFLINK_CREATE_USER_DEV_LOCAL : (process.env.REACT_APP_PDFLINK_CREATE_USER as string);
export const API_PDFLINK_LIST_INVOICES_C =
  process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true' ? process.env.REACT_APP_PDFLINK_LIST_INVOICES_C_DEV_LOCAL : (process.env.REACT_APP_PDFLINK_LIST_INVOICES_C as string);
export const API_PDFLINK_PDF_FILEVIEW =
  process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true' ? process.env.REACT_APP_PDFLINK_PDF_FILEVIEW_DEV_LOCAL : (process.env.REACT_APP_PDFLINK_PDF_FILEVIEW as string);
export const API_PDFLINK_ATTACHMENT_FILEVIEW =
  process.env.REACT_APP_LOCAL_DEVELOPMENT === 'true' ? process.env.REACT_APP_PDFLINK_ATTACHMENT_FILEVIEW_DEV_LOCAL : (process.env.REACT_APP_PDFLINK_ATTACHMENT_FILEVIEW as string);

// EMAILS
export const API_LIST_EMAILS = process.env.REACT_APP_LIST_EMAILS;
export const API_LIST_EMAILS_S = process.env.REACT_APP_LIST_EMAILS_S;

// BULKDELETE
export const API_BULKDELETE = process.env.REACT_APP_BULKDELETE;

// INVOICENUMBERS
export const API_SELECTLIST_INVOICENUMBERS = process.env.REACT_APP_SELECTLIST_INVOICENUMBERS;

// PDFSCHEMAS
export const API_SELECTLIST_PDFSCHEMAS = process.env.REACT_APP_SELECTLIST_PDFSCHEMAS;

// EMAILTEMPLATS
export const API_SELECTLIST_EMAILTEMPLATES = process.env.REACT_APP_SELECTLIST_EMAILTEMPLATES;
export const API_LIST_EMAILTEMPLATES = process.env.REACT_APP_LIST_EMAILTEMPLATES;
export const API_LIST_EMAILTEMPLATE_BY_ID = process.env.REACT_APP_LIST_EMAILTEMPLATE_BY_ID;
export const API_DELETE_EMAILTEMPLATE = process.env.REACT_APP_DELETE_EMAILTEMPLATE;
export const API_CREATE_EMAILTEMPLATE = process.env.REACT_APP_CREATE_EMAILTEMPLATE;
export const API_UPDATE_EMAILTEMPLATE = process.env.REACT_APP_UPDATE_EMAILTEMPLATE;
export const API_LIST_EMAILTEMPLATES_S = process.env.REACT_APP_LIST_EMAILTEMPLATES_S;

// INVOICESTORES
export const API_LIST_INVOICESTORES = process.env.REACT_APP_LIST_INVOICESTORES;
export const API_INVOICESTORE_PDF_FILEVIEW = process.env.REACT_APP_INVOICESTORE_PDF_FILEVIEW;
export const API_INVOICESTORE_ATTACHMENT_FILEVIEW = process.env.REACT_APP_INVOICESTORE_ATTACHMENT_FILEVIEW;
export const API_LIST_INVOICESTORES_S = process.env.REACT_APP_LIST_INVOICESTORES_S;

// NAVCONNECTOR
export const API_TEST_NAV_CONNECTOR = process.env.REACT_APP_TEST_NAV_CONNECTOR;

// SITES
export const API_LIST_SITES = process.env.REACT_APP_LIST_SITES;
export const API_LIST_SITE_BY_ID = process.env.REACT_APP_LIST_SITE_BY_ID;
export const API_CREATE_SITE = process.env.REACT_APP_CREATE_SITE;
export const API_UPDATE_SITE = process.env.REACT_APP_UPDATE_SITE;
export const API_DELETE_SITE = process.env.REACT_APP_DELETE_SITE;
export const API_LIST_SITES_S = process.env.REACT_APP_LIST_SITES_S;

// UNDELIVERED_EMAILS
export const API_LIST_UNDELIVERED_EMAILS = process.env.REACT_APP_LIST_UNDELIVERED_EMAILS;
export const API_LIST_UNDELIVERED_EMAILS_S = process.env.REACT_APP_LIST_UNDELIVERED_EMAILS_S;

export const JsonNoDataModals = [9];

export const LANGUAGES = [
  { flag: <ReactCountryFlag className="flag__attributes" countryCode={'HU'} svg />, name: 'Magyar', countryCode: 'HU', langCode: 'hu' },
  { flag: <ReactCountryFlag className="flag__attributes" countryCode={'GB'} svg />, name: 'English', countryCode: 'GB', langCode: 'en' },
  { flag: <ReactCountryFlag className="flag__attributes" countryCode={'ES'} svg />, name: 'Español', countryCode: 'ES', langCode: 'es' },
];

export const EDITABLE_TABLES: string[] = ['emailtemplates', 'suppliers', 'emailtemplates_'];
export const DELETABLE_TABLES: string[] = ['emailtemplates', 'emailtemplates_'];

export const IS_USE_NAV_CONNECTOR = process.env.REACT_APP_IS_USE_NAV_CONNECTOR === 'true';
export const IS_CUSTOM_EMAIL_BOX = process.env.REACT_APP_IS_CUSTOM_EMAIL_BOX === 'true';

export const tableVariables = {
  buttonsHeight: 'h-[35px]',
  selectHeight: 35,
  noDataFontSize: 35,
};
export const INTRO_PAGE = process.env.REACT_APP_INTRO_PAGE;

export const ENCRYPT_SALT_PIN = '$2a$10$tm2zBjdMhPjITOqUCJw2fO';
export const ENCRYPT_SALT_PASSWORD = '$2a$10$0tFEWzYUbThOIVdF7SPDWO';

export const isSideBarInitHoverEnable = false;
