import React from 'react';
import BoardSectionList, { Task } from './DragDropColumns/BoardSectionList';
import { useTranslation } from 'react-i18next';

type ColumnsDragDropProps = {
  users: any;
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ColumnsDragDrop: React.FC<ColumnsDragDropProps> = ({ users, setSelectedUsers }) => {
  const { t } = useTranslation(['site']);

  const BOARD_SECTIONS = {
    column1: t('users', { ns: ['site'] }),
    column2: t('site', { ns: ['site'] }),
  };

  const INITIAL_TASKS = [] as Task[];
  users.forEach((item: any) => {
    INITIAL_TASKS.push({
      id: item.id,
      name: item.name,
      email: item.email,
      role: item.supplierUser.role,
      status: 'column1', // must be in BOARD_SECTIONS
    });
  });

  const TASK_FIELDS = [
    {
      id: 'name',
      label: t('name', { ns: ['user'] }),
      component: (x: Task) => (x.name ? <span className="text-nowrap">{x.name}</span> : <></>),
    },
    {
      id: 'email',
      label: t('email', { ns: ['user'] }),
      component: (x: Task) => (x.email ? <span className="text-nowrap">{x.email}</span> : <></>),
    },
    {
      id: 'role',
      label: t('role', { ns: ['user'] }),
      component: (x: Task) => (x.role ? <span className="text-nowrap">{x.role === '0' ? t('manager', { ns: ['user'] }) : t('administrator', { ns: ['user'] })}</span> : <></>),
    },
  ];
  return <BoardSectionList BOARD_SECTIONS={BOARD_SECTIONS} INITIAL_TASKS={INITIAL_TASKS} TASK_FIELDS={TASK_FIELDS} />;
};
