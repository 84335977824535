import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch, useReduxSelector } from '../../../../store/Store';
import { usePermissions } from '../../../../logics/usePermissions';
import { useAxios } from '../../../../logics/useAxios';
import { API_DATA, API_LIST_SUPPLIERS_S } from '../../../../utils/variables';
import { useSupplier } from '../../../../logics/useSupplier';
import { useFilters } from '../../../../logics/useFilters';
import { FilterItemType } from '../../../types/Table.type';
import { homeActions } from '../../../../store/appSlices/HomeSlice';
import { EmailTemplateModal } from '../../../UI/EmailTemplateModal';
import EmailTemplateViewLogoModal from '../../../UI/EmailTemplateViewLogoModal';
import EditModalSupplier from '../../../UI/EditModalSupplier';
import { tableActions } from '../../../../store/appSlices/TableSlice';
import { useNavigate } from 'react-router-dom';
import { keyConvert } from '../../../../utils/common';
import { useSideMenuItems } from '../../../menus/menuItems/sideMenuItems';
import { useCreateSupplierFields } from '../../../fields/supplier/createFields';
import { useDataColumnsSupplier } from '../../../fields/supplier/dataColumns';
import useSWR from 'swr';

const Suppliers_: React.FC = () => {
  const { t } = useTranslation(['supplier']);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const isSupplierEditModalOpen = useReduxSelector((state) => state.table.isSupplierEditModalOpen);
  const isEmailTemplateViewModalOpen = useReduxSelector((state) => state.table.isEmailTemplateViewModalOpen);
  const [Filters, setFilters] = useState<FilterItemType[]>([]);
  const isEmailTemplateEditModalOpen = useReduxSelector((state) => state.table.isEmailTemplateEditModalOpen);
  const isEmailTemplateViewLogoModalOpen = useReduxSelector((state) => state.table.isEmailTemplateViewLogoModalOpen);
  const { isSupplierUser } = usePermissions();
  const [loadingSelectList, setLoadingSelectList] = useState(true);
  const [init, setInit] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useReduxDispatch();
  const { apiService } = useAxios();
  const navigate = useNavigate();
  const sideMenuItems = useSideMenuItems();

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate: mutateSuppliers,
  } = useSWR(isUserSignedIn && isSupplierUser ? [API_DATA + API_LIST_SUPPLIERS_S, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const handleCancelEdit = () => {
    dispatch(tableActions.setIsSupplierEditModalOpen(false));
    dispatch(tableActions.setRowCustom({}));
    setOpenModal(false);
    const href = sideMenuItems[0].href;
    if (href) navigate(href);
  };

  const { selectOptions, dataColumns, DataColumnsConfigEmail } = useDataColumnsSupplier();

  const { fetchedData_filtered } = useFilters({ Filters: Filters, Columns: dataColumns, fetchedData: fetchedData });

  const data = useMemo(() => {
    if (fetchedData_filtered[0]) {
      const row = fetchedData_filtered[0];
      const newData = {} as any;
      Object.keys(row).map((key: string) => {
        newData[keyConvert(key)] = row[key as keyof typeof row];
      });
      return newData;
    }
    return undefined;
  }, [fetchedData_filtered]);

  const { onSubmitEditProp } = useSupplier({ mutateSuppliers });

  const { fields: createFields, configEmailFields, selectOptionsEmailTemplatesHU, selectOptionsEmailTemplatesEN, booleanFields } = useCreateSupplierFields({ setLoadingSelectList });

  useEffect(() => {
    dispatch(homeActions.setPageName('suppliers_'));
  }, [dispatch]);

  useEffect(() => {
    if (init && data && !isSupplierEditModalOpen) {
      dispatch(tableActions.setIsSupplierEditModalOpen(true));
    }
  }, [data, dispatch, init, isSupplierEditModalOpen]);

  useEffect(() => {
    if (init && !loadingSelectList && isSupplierEditModalOpen) {
      setInit(false);
      setOpenModal(true);
    }
  }, [data, dispatch, init, isSupplierEditModalOpen, loadingSelectList]);

  return isUserSignedIn && isSupplierUser ? (
    <>
      <div className="bg-transparent h-full">
        <div className="mt-2 flex gap-5 items-center">
          {openModal && (
            <EditModalSupplier
              openModal={openModal}
              handleCloseModal={handleCancelEdit}
              handleClose={handleCancelEdit}
              handleCancel={handleCancelEdit}
              onSubmitProp={onSubmitEditProp}
              resetOnSubmit
              modalTitle={t('editSupplierTitle', { ns: ['supplier'] })}
              contentText={t('toEditSupplier', { ns: ['supplier'] })}
              submitText={t('update', { ns: ['supplier'] })}
              configEmailFields={configEmailFields}
              fields={createFields}
              translation="supplier"
              defaultValues={data}
              edit
              selectOptionsEmailTemplatesHu={selectOptionsEmailTemplatesHU}
              selectOptionsEmailTemplatesEn={selectOptionsEmailTemplatesEN}
              booleanFields={booleanFields}
            />
          )}

          {isEmailTemplateViewModalOpen && <EmailTemplateModal mode={0} />}
          {isEmailTemplateEditModalOpen && <EmailTemplateModal mode={1} />}
          {isEmailTemplateViewLogoModalOpen && <EmailTemplateViewLogoModal />}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default Suppliers_;
