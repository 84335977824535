import { Typography } from '@mui/material';
import React from 'react';
import { useColors } from '../../logics/useColors';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation(['appbar']);
  const { footerTextColor } = useColors();

  return (
    <>
      <Typography component="div" sx={{ position: 'fixed', bottom: 0, width: '100%', color: footerTextColor, paddingX: 3, paddingY: 1, display: 'flex', justifyContent: 'center', zIndex: 0 }}>
        <div className="font-OpenSans">{t('griffSoft', { ns: ['appbar'] })}</div>
      </Typography>
      {/* <div className="fixed bottom-0 flex px-6 py-3 w-full text-white justify-center">© 2024 GriffSoft Zrt. Minden jog fenntartva.</div> */}
    </>
  );
};

export default Footer;
