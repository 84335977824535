import React from 'react';
import { green, red, yellow } from '@mui/material/colors';

export const landingPageColors = {
  isBackgroundImage: false,
  titleTextColor: {
    lightMode: '#37474f', // grey
    darkMode: '#ffffff', // white
  },
  footerTextColor: {
    lightMode: '#37474f', // grey
    darkMode: '#ffffff', // white
  },
  backgroundColor: {
    lightMode: '#ffffff', // white
    darkMode: '#37474f', // grey
  },
  backgroundColorWithImage: {
    lightMode: '#ffffff', // white
    darkMode: '#212121', // light black
  },
  panelColors: {
    supportPanel: {
      backgroundColor: {
        lightMode: 'rgba(178, 65, 48, 0.3), rgba(178, 65, 48, 0.1)',
        darkMode: 'rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)',
      },
      color: {
        lightMode: [red[100], yellow[100], green[100]],
        darkMode: [red[100], yellow[100], green[100]],
      },
      borderColor: {
        lightMode: '#e65730', // orange
        darkMode: '#e65730', // orange
      },
    },
    supplierPanel: {
      backgroundColor: {
        lightMode: 'rgba(9, 148, 143, 0.3), rgba(9, 148, 143, 0.1)',
        darkMode: 'rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)',
      },
      color: {
        lightMode: [red[100], yellow[100], green[100]],
        darkMode: [red[100], yellow[100], green[100]],
      },
      borderColor: {
        lightMode: '#e65730', // orange
        darkMode: '#e65730', // orange
      },
    },
    customerPanel: {
      backgroundColor: {
        lightMode: 'rgba(178, 165, 48, 0.3), rgba(178, 165, 48, 0.1)',
        darkMode: 'rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)',
      },
      color: {
        lightMode: [red[100], yellow[100], green[100]],
        darkMode: [red[100], yellow[100], green[100]],
      },
      borderColor: {
        lightMode: '#e65730', // orange
        darkMode: '#e65730', // orange
      },
    },
  },
  appMenuColors: {
    backgroundColor: {
      lightMode: '#ffffff', // white
      darkMode: '#37474f', // grey
    },
    borderColor: {
      lightMode: '#e65730', // orange
      darkMode: '#e65730', // orange
    },
    iconsColor: {
      lightMode: '#37474f', // grey
      darkMode: '#ffffff', // white
    },
  },
  loginPanel: {
    backgroundColor: {
      lightMode: '#ffffff', // white
      darkMode: '#ffffff', // white
    },
    borderColor: {
      lightMode: '#e65730', // orange
      darkMode: '#e65730', // orange
    },
    textColor: {
      lightMode: '#37474f', // grey
      darkMode: '#ffffff', // white
    },
  },
  error: { text: '' },
};

export const pageColors = {
  isBackgroundImage: false,
  footerTextColor: {
    lightMode: '#37474f', // grey
    darkMode: '#e65730', // orange
  },
  backgroundColor: {
    lightMode: '#ffffff', // white
    darkMode: '#212121', // light black, 37474f grey
  },
  backgroundColorWithImage: {
    lightMode: '#ffffff', // white
    darkMode: '#212121', // light black
  },
  appMenuColors: {
    support: {
      backgroundColor: {
        lightMode: '#e65730', // orange
        darkMode: '#e65730', // orange
      },
      borderColor: {
        lightMode: '#e65730', // orange
        darkMode: '#e65730', // orange
      },
      iconsColor: {
        lightMode: '#ffffff', // white
        darkMode: '#ffffff', // white
      },
      selectedIconColor: {
        lightMode: 'yellow',
        darkMode: 'yellow',
      },
      textColor: {
        lightMode: '#ffffff', // white
        darkMode: '#ffffff', // white
      },
    },
    other: {
      backgroundColor: {
        lightMode: '#ffffff', // white,
        darkMode: '#212121', // light black
      },
      borderColor: {
        lightMode: '#e65730', // orange
        darkMode: '#e65730', // orange
      },
      iconsColor: {
        lightMode: '#e65730', // orange
        darkMode: '#e65730', // orange
      },
      selectedIconColor: {
        lightMode: 'red',
        darkMode: 'yellow',
      },
      textColor: {
        lightMode: '#212121', // light black
        darkMode: '#e65730', // orange
      },
    },
  },
  error: { text: '' },
};

export const sideMenuColors = {
  backgroundColor: {
    lightMode: '#ffffff', // white
    darkMode: '#212121', // light black
  },
  textColor: {
    lightMode: '#e65730', // light black
    darkMode: '#e65730', // white
    hover: {
      lightMode: '#e65730', // orange
      darkMode: '#e65730', // orange
    },
  },
  iconColor: {
    lightMode: '#212121', // light black
    darkMode: '#e65730', // white
    hover: {
      lightMode: '#ffffff', // white
      darkMode: '#ffffff', // white
      backgroundColor: {
        lightMode: '#e65730', // orange
        darkMode: '#e65730', // orange
      },
    },
  },
  selectedIconColor: {
    lightMode: '#e65730', // orange
    darkMode: '#ffffff', // white
  },
};

export const tableColors = {
  buttonsColor: '#0284c7',
  buttonsColorText: 'text-sky-600',
  buttonsColorTextBackground: 'text-white',
  buttonsColorBorder: 'border-sky-600',
  buttonsColorBorderBackground: 'border-[#fff3e6]',
  buttonsColorTextError: 'text-red-600',
  buttonsColorHover: 'hover:bg-sky-600',
  buttonsColorGroupHover: 'group-hover:bg-sky-600',
  buttonsColorGroupHoverError: 'group-hover:bg-red-600',
  groupHoverColorText: 'group-hover:text-sky-600',
  groupHoverColorTextError: 'group-hover:text-red-600',
  selectBorderColor: '#0284c7', // blue
  selectBorderColorBackground: '#fff3e6', // light cream
  titleTextColor: '#000000', // black
  titleTextColorDark: '#fff3e6', // light cream
  titleTextColorBackground: '#ffffff', // white
  selectLabelTextColor: '#0284c7', // blue
  selectLabelTextColorBackground: '#ffffff', // white
  backgroundColorBackGround: '#fff3e6', // light cream
  backgroundColorLight: '#ffffff', // white
  backgroundColorLightMode: '#ffffff', // '#c6a3aa'
  backgroundColorBackGroundLight: '#fff3e6', // light cream
  selectOptionsColor: '#e1f5fe', // light blue
  selectOptionsColorBackGround: '#ffccbc', // light orange
};
