import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Typography, Zoom } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { convertKey } from '../../utils/common';
import { SelectAutoWidthAnimate } from './Select';
import { SelectSearch } from './SelectSearch';
import { DateTimeSelect } from './DateTimeSelect';
import 'dayjs/locale';
import { API_DATA, API_TEST_NAV_CONNECTOR, tableVariables } from '../../utils/variables';
import { useReduxDispatch, useReduxSelector } from '../../store/Store';
import useTextToSpeech from '../../logics/useTextToSpeech';
import { FieldsProfileProps, FieldsProps, SetDefaultValueProps, TextFieldGeneratorProps } from './InputModal';
import * as handlebars from 'handlebars';
import DragDrop from './DragDrop';
import { tableActions } from '../../store/appSlices/TableSlice';
import { SelectOptionsEmailTemplateType } from '../types/EmailTemplate.type';
import { useAxios } from '../../logics/useAxios';
import { AxiosError } from 'axios';
import { useColors } from '../../logics/useColors';

type FormInputsProps = {
  [key: string]: any;
};

type InputModalSupplierProps = {
  openModal: boolean;
  handleCloseModal: any;
  handleClose: () => void;
  handleCancel: () => void;
  onSubmitFunc?: (data: any) => Promise<any>;
  onSubmitSuccess?: string;
  resetOnSubmit?: boolean;
  onSubmitProp?: any; // (data: any, profileFields: FieldsProfileProps | undefined, customLogoBase64: string | undefined) => Promise<any>;
  modalTitle: string;
  contentText: string;
  submitText: string;
  fields: FieldsProfileProps;
  defaultValues?: any;
  translation?: string;
  configEmailFields: FieldsProfileProps;
  edit?: boolean;
  selectOptionsEmailTemplatesHu: SelectOptionsEmailTemplateType[];
  selectOptionsEmailTemplatesEn: SelectOptionsEmailTemplateType[];
  booleanFields: string[];
};

type ShowPasswordProps = {
  label: string;
  items: boolean[];
};

const InputModalSupplier: React.FC<InputModalSupplierProps> = ({
  openModal,
  handleCloseModal,
  handleClose,
  handleCancel,
  onSubmitFunc,
  onSubmitSuccess,
  resetOnSubmit = false,
  onSubmitProp,
  modalTitle,
  contentText,
  submitText,
  fields,
  defaultValues,
  translation = 'user',
  configEmailFields = undefined,
  edit = false,
  selectOptionsEmailTemplatesHu,
  selectOptionsEmailTemplatesEn,
  booleanFields,
}) => {
  const { i18n, t } = useTranslation([translation]);
  const [fields_, setFields_] = useState<FieldsProfileProps>(fields);
  const [init, setInit] = useState(true);
  const { handleSubmit, reset, control, getValues, watch, setValue } = useForm<FormInputsProps>();
  const { handlePlay, handleStop } = useTextToSpeech();
  const [html, setHtml] = useState('');
  const [isViewHtml, setIsViewHtml] = useState(false);
  const [htmlName, setHtmlName] = useState('');
  const [isCustomLogo, setIsCustomLogo] = useState(false);
  const dispatch = useReduxDispatch();
  const selectOptionsEmailTemplatesHu_ = useRef(selectOptionsEmailTemplatesHu);
  const selectOptionsEmailTemplatesEn_ = useRef(selectOptionsEmailTemplatesEn);
  const { backgroundColor_, tableSettings } = useColors();

  const { apiService } = useAxios();

  const initShowPassword = useMemo(
    () =>
      fields_.map((key) => {
        return {
          label: key.label,
          items: new Array(key.items.length).fill(false) as boolean[],
        };
      }),
    [fields_],
  );

  const [showPassword, setShowPassword] = useState<any>([]);
  const [warningToasters, setWarningToasters] = useState<string[]>([]);
  const scrollEndRef = useRef<null | HTMLDivElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const parseCount = useRef<number>(0);
  const [currentEmailTemplate, setCurrentEmailTemplate] = useState<any>();
  const [customLogoBase64, setCustomLogoBase64] = useState('');
  const emailTemplateHtml = useReduxSelector((state) => state.table.emailTemplateHtml);
  const isNewEmailTemplateHtml = useReduxSelector((state) => state.table.isNewEmailTemplateHtml);
  const newEmailTemplate = useReduxSelector((state) => state.table.newEmailTemplate) as any;
  const isEmailTemplateEditModalOpen = useReduxSelector((state) => state.table.isEmailTemplateEditModalOpen);
  const dataEmailTemplates_ = useReduxSelector((state) => state.table.dataEmailTemplates) as SelectOptionsEmailTemplateType[];
  const [initDefaultValues, setInitDefaultValues] = useState<any>({});

  useEffect(() => {
    if (selectOptionsEmailTemplatesHu && selectOptionsEmailTemplatesEn && init) {
      dispatch(tableActions.setDataEmailTemplates([...selectOptionsEmailTemplatesHu, ...selectOptionsEmailTemplatesEn]));
    }
  }, [dispatch, init, selectOptionsEmailTemplatesEn, selectOptionsEmailTemplatesHu]);

  const baseEmailTemplateHu = useMemo(() => {
    const baseEmailHu = selectOptionsEmailTemplatesHu.find((item) => item.type === 'baseEmailHU');
    return baseEmailHu ? baseEmailHu : selectOptionsEmailTemplatesHu[0];
  }, [selectOptionsEmailTemplatesHu]);

  const baseEmailTemplateEn = useMemo(() => {
    const baseEmailEn = selectOptionsEmailTemplatesEn.find((item) => item.type === 'baseEmailEN');
    return baseEmailEn ? baseEmailEn : selectOptionsEmailTemplatesEn[0];
  }, [selectOptionsEmailTemplatesEn]);

  useEffect(() => {
    if (initShowPassword) setShowPassword(initShowPassword);
  }, [initShowPassword]);

  // const showTestNavConnector = useMemo(() => {
  //   if (configEmailFields && configEmailFields[0].items) {
  //     const value = configEmailFields[0].items.find((item) => item.name === 'isUseNavConnector')?.default || false;
  //     return value;
  //   } else return false;
  // }, [configEmailFields]);

  const filterField = useCallback(
    ({ initialize, value }: { initialize: boolean; value: any }) => {
      if (!edit && ((configEmailFields && initialize) || (!initialize && fields_ && fields_[1] && fields_[1].items))) {
        const fieldsX = [] as FieldsProfileProps;
        const items_ = [] as any;
        const obj = initialize && configEmailFields ? Object.entries(configEmailFields[0].items) : Object.entries(fields_[1].items);
        obj.forEach(([k, v]) => {
          if (v.group) {
            const v_ = v;
            v_.visible = value[v.group];
            v_.required = ![
              'defaultConfigTemplateHu',
              'defaultConfigTemplateEn',
              'isMonday',
              'isTuesday',
              'isWednesday',
              'isThursday',
              'isFriday',
              'isSaturday',
              'isSunday',
              'emailSendingDays',
              'emailSendingDays_',
            ].includes(v.name)
              ? value[v.group]
              : v_.required; // not required fields in group
            const groupItem = items_.find((i: any) => i.name === v.group);
            if (groupItem.group) {
              const upperGroupItem = items_.find((group: any) => group.name === groupItem.name);
              const visible = upperGroupItem ? upperGroupItem.visible && value[v.group] : v_.visible;
              v_.visible = visible;
            }
            items_.push(v_);
            if (!value[v.group]) {
              switch (v.name) {
                case 'defaultConfigTemplateHu': {
                  setValue('defaultConfigTemplateHu', baseEmailTemplateHu.id, { shouldValidate: true });
                  break;
                }
                case 'defaultConfigTemplateEn': {
                  setValue('defaultConfigTemplateEn', baseEmailTemplateEn.id, { shouldValidate: true });
                  break;
                }
                default: {
                  setValue(v_.name, v_.type === 'boolean' ? false : '');
                  break;
                }
              }
            }
          } else {
            items_.push(v);
          }
          // }
        });

        fieldsX[0] = fields_[0];
        fieldsX[1] = {
          label: initialize && configEmailFields ? configEmailFields[0].label : fields_[1].label,
          items: items_,
        };
        return fieldsX;
      } else return undefined;
    },
    [baseEmailTemplateEn.id, baseEmailTemplateHu.id, configEmailFields, edit, fields_, setValue],
  );

  const clickShowPassword = (fieldLabel: string, fieldIndex: number) => {
    if (showPassword.length > 0) {
      const fields_ = [] as any;
      showPassword.forEach((field: any) => {
        const item = {
          label: field.label,
          items: field.items.map((value: any, index: any) => {
            if (index === fieldIndex) {
              return !value;
            }
            return value;
          }),
        };
        fields_.push(item);
      });
      setShowPassword(fields_);
    }
  };

  const handleCancelModal = () => {
    dispatch(tableActions.setEmailTemplateHtml(''));
    dispatch(tableActions.setEmailTemplateHtmlName(''));
    dispatch(tableActions.setRowCustomLogo(''));
    dispatch(tableActions.setIsNewEmailTemplateHtml(false));
    dispatch(tableActions.setEmailTemplate({}));
    dispatch(tableActions.setDataEmailTemplates([...selectOptionsEmailTemplatesHu, ...selectOptionsEmailTemplatesEn]));
    reset();
    handleCancel();
  };

  const handleEditTemplateModal = () => {
    dispatch(tableActions.setEmailTemplateHtml(html));
    dispatch(tableActions.setEmailTemplateHtmlName(htmlName));
    dispatch(tableActions.setRowCustomLogo(customLogoBase64));
    dispatch(tableActions.setIsNewEmailTemplateHtml(false));
    dispatch(tableActions.setEmailTemplate(currentEmailTemplate));
    dispatch(tableActions.setIsEmailTemplateEditModalOpen(true));
  };

  const onSubmit = async (data: FormInputsProps) => {
    let isNavConnectorValid = false as boolean | undefined;
    // const isNavConnectorValid = await handleTestNavConnector();
    if (getValues('isUseNavConnector')) {
      isNavConnectorValid = await handleTestNavConnector();
    } else {
      isNavConnectorValid = true;
    }

    if (isNavConnectorValid && configEmailFields) {
      const weekDays = ['isSunday', 'isMonday', 'isTuesday', 'isWednesday', 'isThursday', 'isFriday', 'isSaturday'];
      let validWeekDays = false;
      for await (const [index, value] of weekDays.entries()) {
        if (await getValues(value)) validWeekDays = true;
      }
      if (!validWeekDays && (await getValues('isUseEmailNotReceiving'))) {
        toast.error(t('notValidWeekDays', { ns: [translation] }));
      } else {
        const configEmailFields_ = [
          {
            label: configEmailFields[0].label,
            items: configEmailFields[0].items.filter((i) => i.type !== 'lineText'),
          },
        ];

        const data_ = {} as any;
        Object.entries(data).forEach(([k, v]) => {
          if (k !== 'emailSendingDays' && k !== 'emailSendingDays_') {
            data_[k] = v;
          }
        });

        if (onSubmitProp) {
          const profileFields = configEmailFields_;
          const newTemplates = [];
          for await (const i of dataEmailTemplates_) {
            if (i.id[0] === 'x') newTemplates.push(i);
          }
          const response = await onSubmitProp(data_, profileFields, customLogoBase64, true, newTemplates);

          if (response && response.success) {
            if (resetOnSubmit) reset();
            handleClose();
          }
        } else {
          const newData = {} as FormInputsProps;
          Object.keys(data_).map((key: string) => {
            newData[convertKey(key) as keyof typeof newData] = data_[key as keyof typeof data_];
          });
          try {
            const response = await onSubmitFunc!(newData);
            if (onSubmitSuccess) toast.success(onSubmitSuccess);
          } catch (err) {
            return err;
          }
          handleClose();
          if (resetOnSubmit) reset();
        }
      }
    }
  };

  const handleTestNavConnector = async (): Promise<boolean | undefined> => {
    try {
      const navUser = {
        login: await getValues('navUserLogin'),
        password: await getValues('navUserPassword'),
        taxNumber: getValues('navUserTaxNumber'),
        signatureKey: await getValues('navUserSignatureKey'),
        exchangeKey: await getValues('navUserExchangeKey'),
      };
      const response = await apiService({ url: `${API_DATA}${API_TEST_NAV_CONNECTOR}`, method: 'POST', data: JSON.stringify(navUser) });
      if (response && response.message) {
        if (response.message === 'Valid') {
          toast.success('Nav connector user is valid');
          return true;
        } else {
          toast.error(`Nav ${response.message}`);
          return false;
        }
      } else return false;
    } catch (error) {
      const err = error as AxiosError;
      return false;
    }
  };

  const isNavConnector = useCallback(() => {
    if (
      getValues('isUseNavConnector') &&
      getValues('navUserLogin') &&
      getValues('navUserPassword') &&
      getValues('navUserSignatureKey') &&
      getValues('navUserExchangeKey') &&
      getValues('navUserTaxNumber')
    ) {
      return true;
    } else return false;
  }, [getValues]);

  const fieldGroupLabel = (translateLabel: string) => {
    return (
      <Typography sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}>
        <label className=" -translate-y-7 text-sky-600 font-semibold text-sm">{translateLabel}</label>
      </Typography>
    );
  };

  useEffect(() => {
    if (isCustomLogo) {
      scrollEndRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [isCustomLogo]);

  const setDefaultValue = ({ defaultValues, fieldName, fieldType, fieldSelectOptions }: SetDefaultValueProps) => {
    if (defaultValues && defaultValues[fieldName as keyof typeof defaultValues]) {
      if (fieldType === 'select') {
        return (defaultValues[fieldName as keyof typeof defaultValues] as any).toString();
      } else return defaultValues[fieldName as keyof typeof defaultValues];
    }
    if (fieldType === 'select' && fieldSelectOptions) return fieldSelectOptions[0].id.toString();
    if (fieldType === 'boolean') return false;
    return undefined;
  };

  const setTemplate = useCallback(
    (id: string) => {
      if (dataEmailTemplates_) {
        const emailTemplate = dataEmailTemplates_.find((item: any) => item.id === id);
        if (emailTemplate && emailTemplate.data && emailTemplate.sample) {
          const logo = customLogoBase64 ? customLogoBase64 : `src="${emailTemplate.logo}"`;

          setCurrentEmailTemplate(emailTemplate);
          const dataWithLogo = emailTemplate.data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');

          if (dataWithLogo) {
            const template = handlebars.compile(dataWithLogo);
            setHtml(template(emailTemplate.sample));
            setHtmlName(emailTemplate.label);
          }
        }
      }
    },
    [customLogoBase64, dataEmailTemplates_],
  );

  const getBase64 = useCallback(async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }, []);

  useEffect(() => {
    if (files.length === 0 && !isNewEmailTemplateHtml) {
      setCustomLogoBase64('');
      if (currentEmailTemplate) {
        const logo = `src="${currentEmailTemplate.logo}"`;
        const dataWithLogo = currentEmailTemplate.data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
        const template = handlebars.compile(dataWithLogo);
        setHtml(template(currentEmailTemplate.sample));
        setHtmlName(currentEmailTemplate.label);
      }
    } else if (files && files[0] && currentEmailTemplate && !isNewEmailTemplateHtml) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        const base64 = reader.result;
        const logo = `src="${base64}"`;
        setCustomLogoBase64(logo);

        const dataWithLogo = currentEmailTemplate.data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
        const template = handlebars.compile(dataWithLogo);
        setHtml(template(currentEmailTemplate.sample));
        setHtmlName(currentEmailTemplate.label);
      };
      reader.onerror = () => console.log('error');
    }
  }, [files, isNewEmailTemplateHtml, currentEmailTemplate]);

  useEffect(() => {
    if (!edit && configEmailFields && dataEmailTemplates_ && init) {
      const initValues = {} as any;
      configEmailFields[0].items.forEach((item) => {
        const initValue = item.name === 'defaultConfigTemplateHu' ? baseEmailTemplateHu.id : item.name === 'defaultConfigTemplateEn' ? baseEmailTemplateEn.id : item.default || false;
        initValues[item.name] = initValue;
        setValue(item.name, initValue);
      });
      setInitDefaultValues(initValues);

      // booleanFields.forEach((i) => {
      //   const initValue = configEmailFields[0].items.find((item) => item.name === i)?.default || false;
      //   initValues[i] = initValue;
      //   setValue(i, initValue);
      // });
      // setValue('defaultConfigTemplateHu', baseEmailTemplateHu.id);
      // setValue('defaultConfigTemplateEn', baseEmailTemplateEn.id);

      const newFields_ = filterField({ initialize: true, value: initValues });
      if (newFields_) {
        setFields_(newFields_);
        setInit(false);
      }
      setIsViewHtml(initValues.isUseConfigTemplate);
      setTemplate(dataEmailTemplates_[0].id);
      setIsCustomLogo(initValues.isUseCustomLogo);
    }
  }, [
    fields,
    configEmailFields,
    edit,
    init,
    setValue,
    filterField,
    setTemplate,
    dataEmailTemplates_,
    selectOptionsEmailTemplatesHu,
    selectOptionsEmailTemplatesEn,
    baseEmailTemplateHu.id,
    baseEmailTemplateEn.id,
    booleanFields,
  ]);

  useEffect(() => {
    if (fields_) {
      const subscription = watch((value, { name, type }) => {
        if (name && type === 'change') {
          Object.entries(fields_).forEach(([k, v]) => {
            Object.entries(v).forEach(([x, y]) => {
              if (x === 'items' && typeof y === 'object') {
                const fieldWarn = y.find((s) => s.name === name && s.warning && s.warningText);

                if (fieldWarn && !warningToasters.includes(name)) {
                  toast.warning(fieldWarn.warningText);
                  fieldWarn.warning = false;
                  setWarningToasters((o) => [...o, name]);
                }

                const fieldCloneOf = y.find((c) => c.name === name && c.clone);
                if (fieldCloneOf && fieldCloneOf.clone) setValue(`${fieldCloneOf.clone}`, value[fieldCloneOf.name], { shouldValidate: true });

                // if (name === 'defaultConfigTemplateHU' || name === 'defaultConfigTemplateEN') setTemplate(value[name]);

                if (name === 'isUseConfigTemplate') setIsViewHtml(value[name]);

                if (name === 'isUseCustomLogo') setIsCustomLogo(value[name]);

                if (name === 'isUseConfigTemplate' && !value[name]) setTemplate(dataEmailTemplates_[0].id);

                if (name === 'isUseConfigTemplate') {
                  setValue('defaultConfigTemplateHu', baseEmailTemplateHu.id, { shouldValidate: true });
                  setValue('defaultConfigTemplateEn', baseEmailTemplateEn.id, { shouldValidate: true });
                }

                if (name === 'isUseNavConnector' && value[name] && value['taxNumber']) {
                  const taxNumber = value['taxNumber'].slice(0, 8);
                  setValue('navUserTaxNumber', taxNumber);
                }

                if (name === 'taxNumber' && value['isUseNavConnector']) {
                  const taxNumber = value[name].slice(0, 8);
                  setValue('navUserTaxNumber', taxNumber);
                }
              }
            });
          });
        }
      });
      return () => subscription.unsubscribe();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, fields_, setValue, warningToasters, isViewHtml, setTemplate]);

  useEffect(() => {
    if (!edit && fields_) {
      const subscriptionCheck = watch((value, { name, type }) => {
        if (type === 'change') {
          const newFields_ = filterField({ initialize: false, value: value });
          if (newFields_) setFields_(newFields_);
        }
      });
      return () => subscriptionCheck.unsubscribe();
    }
  }, [configEmailFields, edit, fields_, filterField, init, watch]);

  useEffect(() => {
    if (!isEmailTemplateEditModalOpen && emailTemplateHtml && isNewEmailTemplateHtml) {
      const fieldName = newEmailTemplate.language === 'HU' ? 'defaultConfigTemplateHu' : 'defaultConfigTemplateEn';
      if (newEmailTemplate.language === 'HU') {
        selectOptionsEmailTemplatesHu_.current = dataEmailTemplates_.filter((item) => item.language === newEmailTemplate.language);
      } else {
        selectOptionsEmailTemplatesEn_.current = dataEmailTemplates_.filter((item) => item.language === newEmailTemplate.language);
      }

      const fieldsX = [] as FieldsProfileProps;
      const items_ = [] as any;
      fields_[1].items.forEach((x: any) => {
        switch (x.name) {
          case fieldName: {
            items_.push({
              ...x,
              selectOptions: newEmailTemplate.language === 'HU' ? selectOptionsEmailTemplatesHu_.current : selectOptionsEmailTemplatesEn_.current,
            });
            break;
          }
          default: {
            items_.push(x);
            break;
          }
        }
      });
      fieldsX[0] = fields_[0];
      fieldsX[1] = {
        label: fields_[1].label,
        items: items_,
      };
      setFields_(fieldsX);
      setValue(fieldName, newEmailTemplate.id);

      setHtml(emailTemplateHtml);
      setHtmlName(newEmailTemplate.label);
      setCurrentEmailTemplate(newEmailTemplate);
      dispatch(tableActions.setIsNewEmailTemplateHtml(false));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentEmailTemplate,
    dispatch,
    emailTemplateHtml,
    isEmailTemplateEditModalOpen,
    isNewEmailTemplateHtml,
    newEmailTemplate,
    selectOptionsEmailTemplatesEn,
    selectOptionsEmailTemplatesHu,
    setValue,
  ]); // do not use fields_ dependency

  const textFieldGenerator = ({
    fieldLabel,
    index,
    fieldName,
    requiredField = true,
    fieldType,
    fieldSelectOptions,
    patternField,
    validateField,
    disabled = false,
    fieldSelectSearch = false,
    nonEditableField = false,
    fieldDefault,
    visible = true,
    group,
    helperText = '',
    inline = false,
    labelPlacement,
  }: TextFieldGeneratorProps) => {
    return (
      <Fragment key={index}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n?.language}>
          <Controller
            name={fieldName}
            control={control}
            // defaultValue={initDefaultValues[fieldName]}
            // defaultValue={!edit && fieldDefault ? fieldDefault : setDefaultValue({ defaultValues: defaultValues, fieldName: fieldName, fieldType: fieldType, fieldSelectOptions: fieldSelectOptions })}
            rules={{
              required: requiredField ? t(`${fieldName}Required`, { ns: [translation] }) : undefined,
              pattern: patternField
                ? {
                    value: new RegExp(patternField),
                    message: t(`${fieldName}Pattern`, { ns: [translation] }),
                  }
                : undefined,

              validate: validateField
                ? (value) => {
                    let response = '';
                    Object.keys(validateField).map((key: any) => {
                      if (key === 'equalField') {
                        if (value !== getValues(validateField[key][0])) response += validateField[key][1] + ' ';
                      } else if (validateField[key](value) !== true) response += validateField[key](value) + ' ';
                    });
                    if (response) return response;
                    else return true;
                  }
                : undefined,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) =>
              visible ? (
                <span className={`${group && !inline ? 'ml-3' : ''}`}>
                  {fieldType === 'boolean' ? (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                            checked={!!value}
                            onChange={(e) => {
                              onChange(e.target.checked);
                            }}
                            disabled={disabled}
                          />
                        }
                        label={t(`${fieldName}`, { ns: [translation] })}
                        labelPlacement={labelPlacement ? (labelPlacement as 'bottom' | 'top' | 'end' | 'start') : 'end'}
                      />
                      {!inline && <br />}
                    </>
                  ) : fieldType === 'date' ? (
                    <DateTimeSelect
                      selectLabel={t(`${fieldName}`, { ns: [translation] })}
                      selectValue={value ? dayjs(value) : dayjs('')}
                      disabled={disabled}
                      onChange={onChange}
                      rounded={false}
                      minWidth={100}
                      nonEditable={edit && nonEditableField}
                      error={error}
                      dateWithTime={false}
                    />
                  ) : fieldType === 'select' ? (
                    fieldSelectSearch ? (
                      <SelectSearch
                        selectLabel={t(`${fieldName}`, { ns: [translation] })}
                        selectValue={value}
                        onSelect={onChange}
                        selectOptions={fieldSelectOptions}
                        rounded={false}
                        minWidth={200}
                        minHeight={tableVariables.selectHeight}
                        nonEditable={edit && nonEditableField}
                        disabled={disabled}
                      />
                    ) : (
                      <SelectAutoWidthAnimate
                        selectLabel={t(`${fieldName}`, { ns: [translation] })}
                        selectValue={value}
                        onSelect={onChange}
                        selectOptions={fieldSelectOptions}
                        rounded={false}
                        minWidth={200}
                        minHeight={tableVariables.selectHeight}
                        nonEditable={edit && nonEditableField}
                        disabled={disabled}
                        color={{
                          buttonsColor: tableSettings.buttonsColor,
                          selectTextColor: tableSettings.textColor,
                          selectLabelTextColor: tableSettings.selectLabelTextColor,
                          buttonsColorBorder: tableSettings.buttonsColorBorder,
                          selectOptionsColor: tableSettings.selectOptionsColor,
                          backgroundColor: backgroundColor_,
                        }}
                        setTemplate={setTemplate}
                      />
                    )
                  ) : fieldType === 'lineText' ? (
                    <div className={`my-3 ${group ? 'ml-3' : ''}`}>{t(`${fieldName}`, { ns: [translation] })}</div>
                  ) : (
                    <TextField
                      sx={{ backgroundColor: backgroundColor_, width: '95%' }}
                      error={!!error}
                      helperText={error ? error['message'] : helperText ? '' : ''}
                      margin="dense"
                      size="small"
                      value={value ? value : ''}
                      label={t(`${fieldName}`, { ns: [translation] })}
                      type={fieldType === 'password' ? (showPassword.find(({ label }: any) => label === fieldLabel)?.items[index] ? 'text' : 'password') : fieldType}
                      onChange={onChange}
                      fullWidth
                      variant="standard"
                      disabled={(edit && nonEditableField) || disabled}
                      autoComplete="new-password"
                      InputProps={
                        fieldType === 'password'
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="toggle password visibility" onClick={() => clickShowPassword(fieldLabel, index)} edge="end" disabled={!value}>
                                    {showPassword.find(({ label }: any) => label === fieldLabel)?.items[index] ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {}
                      }
                      InputLabelProps={{ required: requiredField, shrink: true, style: { color: '#4b5563', fontSize: '20px' } }}
                    />
                  )}
                </span>
              ) : (
                <></>
              )
            }
          />
        </LocalizationProvider>
      </Fragment>
    );
  };

  return (
    <>
      <Dialog
        sx={{
          border: 1,
          borderColor: tableSettings.buttonsColor,
          '& .MuiDialog-container': {
            '& .MuiPaper-root': isViewHtml
              ? {
                  width: '100%',
                  maxWidth: '1500px',
                }
              : { width: '100%', minWidth: '700px' },
          },
        }}
        open={openModal}
        onClose={handleCloseModal}
        // scroll="paper"
      >
        <DialogTitle
          sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_, padding: 3 }}
          className="flex items-center justify-center text-2xl py-5"
          onMouseEnter={() => handlePlay(modalTitle)}
          onMouseLeave={() => handleStop()}
        >
          {modalTitle}
        </DialogTitle>
        {init || !fields || !fields_[1] ? (
          <div className="">...Loading</div>
        ) : (
          <DialogContent sx={{ display: 'flex', color: tableSettings.textColor, backgroundColor: backgroundColor_, padding: 3 }} dividers={true}>
            <Grid container spacing={2}>
              <Grid container item xs={isViewHtml ? 6 : 12} direction="column" sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                <Zoom in={true} style={{ transitionDelay: openModal ? '100ms' : '0ms' }} timeout={700}>
                  <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
                    <DialogContentText
                      component={'div'}
                      sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                      onMouseEnter={() => handlePlay(contentText)}
                      onMouseLeave={() => handleStop()}
                    >
                      <div className="flex items-center justify-center pb-5">{contentText}</div>
                    </DialogContentText>

                    {fields_.map((item, index) => {
                      return (
                        <Fragment key={item.label + index}>
                          {fieldGroupLabel(item.label)}
                          <div className="bg-transparent mb-7 pb-1 px-4 border border-sky-600">
                            {item.items.map((field: FieldsProps, sIndex) => {
                              return (
                                <span key={sIndex} onMouseEnter={() => handlePlay(field.name)} onMouseLeave={() => handleStop()}>
                                  {textFieldGenerator({
                                    fieldLabel: item.label,
                                    index: sIndex,
                                    fieldName: field.name,
                                    requiredField: field.required,
                                    fieldType: field.type,
                                    fieldSelectOptions: field.selectOptions,
                                    fieldSelectSearch: field.selectSearch,
                                    patternField: field.pattern,
                                    validateField: field.validate,
                                    nonEditableField: field.nonEditable,
                                    disabled: field.disabled,
                                    fieldDefault: field.default,
                                    visible: field.visible,
                                    group: field.group,
                                    helperText: field.helperText,
                                    inline: field.inline,
                                    labelPlacement: field.labelPlacement,
                                  } as TextFieldGeneratorProps)}
                                </span>
                              );
                            })}
                          </div>
                        </Fragment>
                      );
                    })}
                    {isCustomLogo && (
                      <>
                        {fieldGroupLabel('Upload custom logo')}
                        <div className="bg-transparent mb-7 pb-1 px-4 border border-sky-600">
                          <DialogContent>
                            <DragDrop
                              onFilesSelected={setFiles}
                              text={t('dragDropText', { ns: [translation] })}
                              textSupported={t('dragDropTextSupported', { ns: [translation] })}
                              acceptFileTypes={'.png'}
                              multiple={false}
                            />
                            <div className="mt-1">
                              {parseCount.current > 0 && (
                                <DialogContentText component={'div'} id="dialog-slide-description">
                                  {t('importedRows', { ns: [translation] })} {parseCount.current}
                                </DialogContentText>
                              )}
                            </div>
                          </DialogContent>
                        </div>
                        <div ref={scrollEndRef} />
                      </>
                    )}
                  </form>
                </Zoom>
              </Grid>
              {isViewHtml && (
                <Grid container item xs={6} direction="column" sx={{ height: '100%', overflowY: 'scroll' }}>
                  <DialogContentText
                    component={'div'}
                    sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                    onMouseEnter={() => handlePlay(htmlName)}
                    onMouseLeave={() => handleStop()}
                  >
                    <div className="flex items-center justify-center pb-0">{`${t('templateName', { ns: [translation] })}: ${htmlName}`}</div>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </DialogContentText>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        )}
        <DialogActions sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}>
          <Typography component={'div'} sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}>
            <div className="flex gap-9 pr-7 pb-7">
              {isNavConnector() && (
                <Button onClick={handleTestNavConnector} onMouseEnter={() => handlePlay(t('testNavConnector', { ns: [translation] }))} onMouseLeave={() => handleStop()}>
                  <span className="bg-transparent text-orange-600 px-4 border border-orange-600 hover:bg-orange-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                    {t('testNavConnector', { ns: [translation] })}
                  </span>
                </Button>
              )}
              {isViewHtml && (
                <Button onClick={handleEditTemplateModal} onMouseEnter={() => handlePlay(t('editTemplate', { ns: [translation] }))} onMouseLeave={() => handleStop()}>
                  <span className="bg-transparent text-green-600 px-4 border border-green-600 hover:bg-green-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                    {t('editTemplate', { ns: [translation] })}
                  </span>
                </Button>
              )}
              <Button onClick={handleCancelModal} onMouseEnter={() => handlePlay(t('cancel', { ns: [translation] }))} onMouseLeave={() => handleStop()}>
                <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                  {t('cancel', { ns: [translation] })}
                </span>
              </Button>
              <Button type="submit" form="hook-form" onMouseEnter={() => handlePlay(submitText)} onMouseLeave={() => handleStop()}>
                <span className="bg-transparent text-sky-600 px-4 border border-sky-600 hover:bg-sky-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                  {submitText}
                </span>
              </Button>
            </div>
          </Typography>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InputModalSupplier;
