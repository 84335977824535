import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTextToSpeech from '../../logics/useTextToSpeech';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grid, Slide, TextField, Zoom } from '@mui/material';
import { useReduxDispatch, useReduxSelector } from '../../store/Store';
import { TransitionProps } from '@mui/material/transitions';
import { tableActions } from '../../store/appSlices/TableSlice';
import { useAxios } from '../../logics/useAxios';
import useSWR from 'swr';
import { API_DATA, API_LIST_EMAILTEMPLATES, serverPagination } from '../../utils/variables';
import * as handlebars from 'handlebars';
import Editor from '@monaco-editor/react';
import { ALPHA_NUMERIC_DASH_REGEX } from '../../utils/common';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { EmailTemplateType, SelectOptionsEmailTemplateType } from '../types/EmailTemplate.type';
import { useColors } from '../../logics/useColors';

type EmailModalProps = {
  mode: number;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} onExited={() => {}} />;
});

export const EmailTemplateModal: React.FC<EmailModalProps> = ({ mode }) => {
  const { t } = useTranslation(['emailTemplate']);
  const [open, setOpen] = useState(true);
  const isEmailTemplateViewModalOpen = useReduxSelector((state) => state.table.isEmailTemplateViewModalOpen);
  const isEmailTemplateEditModalOpen = useReduxSelector((state) => state.table.isEmailTemplateEditModalOpen);
  const rowEmailTemplateId = useReduxSelector((state) => state.table.rowEmailTemplateId);
  const rowCustomLogo = useReduxSelector((state) => state.table.rowCustomLogo);
  const [html, setHtml] = useState('');
  const [htmlName, setHtmlName] = useState('');
  const [htmlEmailTemplateType, setHtmlEmailTemplateType] = useState('');
  const emailTemplateHtml = useReduxSelector((state) => state.table.emailTemplateHtml);
  const emailTemplateHtmlName = useReduxSelector((state) => state.table.emailTemplateHtmlName);
  const emailTemplateStored = useReduxSelector((state) => state.table.emailTemplate) as any;
  const [editorLightTheme, setEditorLightTheme] = useState(true);
  const [currentEmailTemplate, setCurrentEmailTemplate] = useState<any>();
  const [isCompiled, setIsCompiled] = useState(true);
  const [data, setData] = useState('');
  const [init, setInit] = useState(true);
  const [isFileName, setIsFileName] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [isValidNewTemplateName, setIsValidNewTemplateName] = useState({ valid: true, errorText: '' });
  const dispatch = useReduxDispatch();
  const { handlePlay, handleStop } = useTextToSpeech();
  const dataEmailTemplates_ = useReduxSelector((state) => state.table.dataEmailTemplates) as SelectOptionsEmailTemplateType[];
  const emailTemplates = useReduxSelector((state) => state.table.dataEmailTemplates) as SelectOptionsEmailTemplateType[];

  const { apiService } = useAxios();
  const { backgroundColor_, tableSettings } = useColors();

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
  } = useSWR(
    isEmailTemplateViewModalOpen && rowEmailTemplateId && mode === 0 ? [`${API_DATA}${API_LIST_EMAILTEMPLATES}`, 'GET', ''] : null, // [`${API_DATA}${API_LIST_EMAILTEMPLATE_BY_ID}/${parseInt(rowEmailTemplateId)}`, 'GET', '']
    ([url, method, body]) => apiService({ url: url, method: method, data: body }),
  );

  const emailTemplates_: EmailTemplateType[] = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  const emailTemplate = useMemo(() => {
    if (mode === 0 && emailTemplates_) {
      return emailTemplates_.find((item: any) => item.id === rowEmailTemplateId);
    } else if (mode === 1 && emailTemplates) {
      return emailTemplates.find((item: any) => item.id === emailTemplateStored.id);
    } else return undefined;
  }, [emailTemplateStored.id, emailTemplates, emailTemplates_, mode, rowEmailTemplateId]);

  useEffect(() => {
    if (init) {
      if (mode === 0 && emailTemplate && emailTemplate.data && emailTemplate.sample && emailTemplate.name) {
        const logo = rowCustomLogo ? `src="${rowCustomLogo}"` : `src="${emailTemplate.logo}"`;
        const dataWithLogo = emailTemplate.data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
        const template = handlebars.compile(dataWithLogo);
        setHtml(template(emailTemplate.sample));
        setHtmlName(emailTemplate.name);
        setHtmlEmailTemplateType(emailTemplate.type ? emailTemplate.type : '');
        setInit(false);
      } else if (mode === 1 && emailTemplateStored && emailTemplateStored.data && emailTemplateStored.sample) {
        setCurrentEmailTemplate(emailTemplateStored);
        // setHtml(emailTemplateStored.data);
        setHtml(emailTemplateHtml);
        setData(emailTemplateStored.data);
        setHtmlName(emailTemplateHtmlName);
        setHtmlEmailTemplateType(emailTemplateStored.type ? emailTemplateStored.type : '');
        setInit(false);
      }
    }
  }, [emailTemplate, emailTemplateHtml, emailTemplateHtmlName, emailTemplateStored, init, mode, rowCustomLogo]);

  const language = useMemo(() => {
    if (emailTemplateHtmlName) {
      return emailTemplateHtmlName.substring(emailTemplateHtmlName.length - 3);
    } else return '';
  }, [emailTemplateHtmlName]);

  useEffect(() => {
    if (currentEmailTemplate && currentEmailTemplate.id && currentEmailTemplate.id[0] === 'x') {
      setNewTemplateName(currentEmailTemplate.label.substring(0, currentEmailTemplate.label.length - 3));
    }
  }, [currentEmailTemplate]);

  const isOwnTemplate = useMemo(() => {
    if (currentEmailTemplate && currentEmailTemplate.id && currentEmailTemplate.label && currentEmailTemplate.id[0] === 'x' && newTemplateName + language === currentEmailTemplate.label) {
      return true;
    } else return false;
  }, [currentEmailTemplate, language, newTemplateName]);

  const deletableTemplate = useMemo(() => {
    if (currentEmailTemplate && currentEmailTemplate.id && currentEmailTemplate.id[0] === 'x') {
      return true;
    } else return false;
  }, [currentEmailTemplate]);

  const validateNewTemplateName = async (fileName: string) => {
    let valid = true;
    let errorText = '';

    if (!(currentEmailTemplate.id[0] === 'x' && fileName === currentEmailTemplate.label)) {
      for await (const item of emailTemplates) {
        if (item.label === fileName) {
          valid = false;
          errorText = t('errorFileNameExists', { ns: ['emailTemplate'] });
          break;
        }
      }
    }

    return { valid, errorText };
  };

  const handleChangeNewTemplateName = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value !== '' && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      return;
    } else {
      setNewTemplateName(value);
      handleBlurNewTemplateName({ value: value });
    }
  };

  const handleBlurNewTemplateName = async ({ e, value }: { e?: any; value?: string }) => {
    const value_ = e ? (e.target.value as string) : value;
    const fileName = value_ + language;
    if (!value_) {
      setIsValidNewTemplateName({
        valid: false,
        errorText: t('errorNoTemplateName', { ns: ['emailTemplate'] }),
      });
    } else {
      const { valid, errorText } = await validateNewTemplateName(fileName);
      setIsValidNewTemplateName({
        valid: valid,
        errorText: errorText,
      });
    }
  };

  const handleClose = () => {
    switch (mode) {
      case 0: {
        dispatch(tableActions.setIsEmailTemplateViewModalOpen(false));
        break;
      }
      case 1: {
        dispatch(tableActions.setIsEmailTemplateEditModalOpen(false));
        break;
      }
      default:
    }
  };

  const handleCloseModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  const onSwitch = useCallback(() => {
    setEditorLightTheme(!editorLightTheme);
  }, [editorLightTheme]);

  const onChange = (newValue?: string) => {
    if (newValue) {
      setData(newValue);
      setIsFileName(true);
      if (isCompiled) {
        const logo = rowCustomLogo ? rowCustomLogo : `src="${currentEmailTemplate.logo}"`;
        const dataWithLogo = newValue.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
        const template = handlebars.compile(dataWithLogo);
        setHtml(template(currentEmailTemplate.sample));
      } else {
        setHtml(newValue);
      }
    }
  };

  const onDelete = async () => {
    const array = [...dataEmailTemplates_];
    const index = array.findIndex((item) => item.id === currentEmailTemplate.id);
    array.splice(index, 1);
    dispatch(tableActions.setDataEmailTemplates(array));

    const logo = `src="${emailTemplates[0].logo}"`;
    const dataWithLogo = emailTemplates[0].data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
    const template = handlebars.compile(dataWithLogo);
    dispatch(tableActions.setEmailTemplateHtml(template(emailTemplates[0].sample)));
    dispatch(tableActions.setEmailTemplateHtmlName(emailTemplates[0].label));
    dispatch(tableActions.setIsNewEmailTemplateHtml(true));
    dispatch(tableActions.setNewEmailTemplate(emailTemplates[0]));
    handleClose();
  };

  const onConfirm = async () => {
    const logo = rowCustomLogo ? (rowCustomLogo.startsWith('src="') ? rowCustomLogo.substring(5).substring(0, rowCustomLogo.substring(5).length - 1) : rowCustomLogo) : currentEmailTemplate.logo;
    // const api = `${API_DATA}${API_CREATE_EMAILTEMPLATE}`;

    try {
      const existingNewIdsOfEmailTemplates = emailTemplates
        .map((item) => item.id)
        .filter((id) => id[0] === 'x')
        .map((i) => parseInt(i.substring(1)));
      const highestExistingNewId = existingNewIdsOfEmailTemplates.length > 0 ? Math.max(...existingNewIdsOfEmailTemplates) : undefined;

      const newTemplate = {
        id: isOwnTemplate ? currentEmailTemplate.id : highestExistingNewId ? 'x' + (highestExistingNewId + 1).toString() : 'x1',
        label: `${newTemplateName}${language}`, // name: `${newTemplateName}${language}`,
        language: currentEmailTemplate.language,
        data: data,
        logo: logo,
        sample: currentEmailTemplate.sample,
        type: `customEmail${currentEmailTemplate.language}`,
      };
      // const response = await createData(api, { name: newTemplate.label, company_logo: newTemplate.logo, data: newTemplate.data, language: newTemplate.language, sample: newTemplate.sample });

      dispatch(tableActions.setEmailTemplateHtml(html));
      dispatch(tableActions.setEmailTemplateHtmlName(htmlName));
      dispatch(tableActions.setIsNewEmailTemplateHtml(true));
      dispatch(tableActions.setNewEmailTemplate(newTemplate));

      if (isOwnTemplate) {
        const array = [...dataEmailTemplates_];
        const index = array.findIndex((item) => item.id === newTemplate.id);
        array.splice(index, 1, newTemplate);
        dispatch(tableActions.setDataEmailTemplates(array));
      } else {
        dispatch(tableActions.setDataEmailTemplates([...dataEmailTemplates_, newTemplate]));
      }

      // dispatch(
      //   tableActions.setNewEmailTemplate({
      //     ...newTemplate,
      //     id: response.data.id
      //   }),
      // );
      handleClose();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(`${err.response?.statusText}`);
    }
  };

  const onCompile = () => {
    const logo = rowCustomLogo ? rowCustomLogo : `src="${currentEmailTemplate.logo}"`;
    const dataWithLogo = data.replace("src='cid:companyLogo'", logo).replace('href="{{link}}', 'href="javascript:void(0)"');
    const template = handlebars.compile(dataWithLogo);
    setHtml(template(currentEmailTemplate.sample));
    setIsCompiled(true);
  };

  const onOriginal = () => {
    setHtml(data);
    setIsCompiled(false);
  };

  return (
    <Fragment>
      {mode === 0 ? (
        <>
          <Dialog
            sx={{
              border: 1,
              borderColor: tableSettings.buttonsColor,
              '& .MuiDialog-container': {
                '& .MuiPaper-root': { width: '100%', maxWidth: 'md' },
              },
            }}
            open={open}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            keepMounted
            scroll="paper"
          >
            <DialogTitle className="flex items-center justify-center text-2xl py-5" onMouseEnter={() => handlePlay(t('emailTemplate', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
              {t('emailTemplate', { ns: ['emailTemplate'] })}
            </DialogTitle>
            <DialogContent dividers={true}>
              <Zoom in={true} style={{ transitionDelay: open ? '100ms' : '0ms' }} timeout={700}>
                <div className="bg-transparent mb-7 pb-1 px-4">
                  <DialogContentText
                    component={'div'}
                    sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                    onMouseEnter={() => handlePlay(t('templateName', { ns: ['emailTemplate'] }))}
                    onMouseLeave={() => handleStop()}
                  >
                    <div className="flex items-center justify-center pb-0">
                      <div className="flex flex-row">
                        <div className="mr-5">{`${t('templateName', { ns: ['emailTemplate'] })}: ${htmlName}`}</div>
                        <div>{`${t('templateType', { ns: ['emailTemplate'] })}: ${htmlEmailTemplateType}`}</div>
                      </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </DialogContentText>
                </div>
              </Zoom>
            </DialogContent>
            <DialogActions>
              <div className="flex gap-9 mr-7 mb-7">
                {/* Cancel */}
                <Button onClick={handleClose} onMouseEnter={() => handlePlay(t('cancel', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
                  <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                    {t('cancel', { ns: ['emailTemplate'] })}
                  </span>
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </>
      ) : mode === 1 ? (
        <>
          <Dialog
            sx={{
              border: 1,
              borderColor: tableSettings.buttonsColor,
              '& .MuiDialog-container': {
                '& .MuiPaper-root': { width: '100%', maxWidth: '1500px' },
              },
            }}
            open={open}
            onClose={handleCloseModal}
            TransitionComponent={Transition}
            // keepMounted
            // scroll="paper"
          >
            <DialogTitle className="text-2xl py-1" onMouseEnter={() => handlePlay(t('templateName', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
              <div className="flex flex-col items-center justify-center pb-0">
                <div className="flex flex-row">
                  <div className="mr-5">{`${t('templateName', { ns: ['emailTemplate'] })}: ${htmlName}`}</div>
                  <div>{`${t('templateType', { ns: ['emailTemplate'] })}: ${htmlEmailTemplateType}`}</div>
                </div>
              </div>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', color: tableSettings.textColor, backgroundColor: backgroundColor_ }} dividers={true}>
              <Grid container spacing={2}>
                <Grid container item xs={6} direction="column" sx={{ height: '100%', overflowY: 'hidden' }}>
                  <Zoom in={true} style={{ transitionDelay: open ? '100ms' : '0ms' }} timeout={700}>
                    <div className="bg-transparent mb-1 pb-1 px-1">
                      <DialogContentText
                        component={'div'}
                        sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                        onMouseEnter={() => handlePlay(htmlName)}
                        onMouseLeave={() => handleStop()}
                      >
                        <div
                          className="flex items-center justify-center"
                          onMouseEnter={() => handlePlay(editorLightTheme ? t('lightTheme', { ns: ['emailTemplate'] }) : t('darkTheme', { ns: ['emailTemplate'] }))}
                          onMouseLeave={() => handleStop()}
                        >
                          <p className="mr-1 text-sm font-medium text-gray-900 dark:text-gray-300">
                            {editorLightTheme ? t('lightTheme', { ns: ['emailTemplate'] }) : t('darkTheme', { ns: ['emailTemplate'] })}
                          </p>
                          <label className="relative cursor-pointer">
                            <input type="checkbox" className="sr-only peer" checked={editorLightTheme} readOnly />
                            <div
                              onClick={onSwitch}
                              className="w-[53px] h-6 flex items-center bg-gray-300 rounded-full text-[9px] peer-checked:text-[#007bff] text-[#007bff] font-extrabold after:flex after:items-center after:justify-center peer after:content-['Off'] peer-checked:after:content-['On'] peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-[#007bff] after:bg-white after:border after:border-[#007bff] after:rounded-full after:size-6 after:transition-all peer-checked:bg-gray-300" // peer-checked:bg-[#007bff]
                            ></div>
                          </label>
                        </div>

                        <div className="mt-1">
                          <Editor
                            height="100vh"
                            language="handlebars"
                            value={data}
                            defaultValue="Please enter HTML code."
                            theme={editorLightTheme ? 'vs-light' : 'vs-dark'}
                            onChange={onChange}
                            options={{
                              selectOnLineNumbers: true,
                              roundedSelection: false,
                              readOnly: false,
                              cursorStyle: 'line' as 'line' | 'block' | 'underline' | 'line-thin' | 'block-outline' | 'underline-thin' | undefined,
                            }}
                          />
                        </div>
                      </DialogContentText>
                    </div>
                  </Zoom>
                </Grid>

                <Grid container item xs={6} direction="column" sx={{ height: '100%', overflowY: 'scroll' }}>
                  <DialogContentText
                    component={'div'}
                    sx={{ color: tableSettings.textColor, backgroundColor: backgroundColor_ }}
                    onMouseEnter={() => handlePlay(htmlName)}
                    onMouseLeave={() => handleStop()}
                  >
                    <div className="flex flex-col items-center justify-center pb-0">
                      <div>{`${isCompiled ? t('compiled', { ns: ['emailTemplate'] }) : t('original', { ns: ['emailTemplate'] })}`}</div>
                      <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                  </DialogContentText>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <div className="flex gap-9 mr-7 mb-1">
                {/* File name */}
                {isFileName && (
                  <TextField
                    id="new-template-name"
                    label={t('newTemplateName', { lang: language, ns: ['emailTemplate'] })}
                    variant="standard"
                    value={newTemplateName}
                    error={!isValidNewTemplateName.valid}
                    helperText={isValidNewTemplateName.errorText}
                    onChange={handleChangeNewTemplateName}
                    onBlur={(e) => handleBlurNewTemplateName({ e: e })}
                    fullWidth
                    size="small"
                    required
                    inputProps={{ maxLength: 30 }}
                  />
                )}
                {/* Origin */}
                <Button onClick={onOriginal} onMouseEnter={() => handlePlay(t('original', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()} disabled={!isCompiled}>
                  <span className="bg-transparent text-green-600 px-4 border border-green-600 hover:bg-green-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                    {t('original', { ns: ['emailTemplate'] })}
                  </span>
                </Button>

                {/* Compile */}
                <Button onClick={onCompile} onMouseEnter={() => handlePlay(t('compiled', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()} disabled={isCompiled}>
                  <span className="bg-transparent text-green-600 px-4 border border-green-600 hover:bg-green-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                    {t('compiled', { ns: ['emailTemplate'] })}
                  </span>
                </Button>

                {/* Confirm */}
                <Button
                  onClick={onConfirm}
                  onMouseEnter={() => handlePlay(!isOwnTemplate ? t('save', { ns: ['emailTemplate'] }) : t('overWrite', { ns: ['emailTemplate'] }))}
                  onMouseLeave={() => handleStop()}
                  disabled={(isOwnTemplate && !isFileName) || (!isOwnTemplate && (!isFileName || !newTemplateName || !isValidNewTemplateName.valid))}
                >
                  <span className="bg-transparent text-sky-600 px-4 border border-sky-600 hover:bg-sky-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                    {!isOwnTemplate ? t('save', { ns: ['emailTemplate'] }) : t('overWrite', { ns: ['emailTemplate'] })}
                  </span>
                </Button>

                {/* Delete */}
                {deletableTemplate && (
                  <Button onClick={onDelete} onMouseEnter={() => handlePlay(t('delete', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
                    <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                      {t('delete', { ns: ['emailTemplate'] })}
                    </span>
                  </Button>
                )}

                {/* Cancel */}
                <Button onClick={handleClose} onMouseEnter={() => handlePlay(t('cancel', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
                  <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                    {t('cancel', { ns: ['emailTemplate'] })}
                  </span>
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </Fragment>
  );
};
