import React from 'react';
import { useTranslation } from 'react-i18next';
import PanelCard from '../../../UI/PanelCards';
import { mobileSizeWidth } from '../../../../utils/variables';
import { useReduxSelector } from '../../../../store/Store';
import { Typography } from '@mui/material';
import useTextToSpeech from '../../../../logics/useTextToSpeech';
import { useColors } from '../../../../logics/useColors';

type InfoPanelPublicProps = {
  appHeight: number;
};

const InfoPanelPublic: React.FC<InfoPanelPublicProps> = ({ appHeight }) => {
  const { t } = useTranslation(['griff']);
  const numberOfPanels = 3;
  const windowWidth = useReduxSelector((state) => state.home.windowWidth);
  const windowHeight = useReduxSelector((state) => state.home.windowHeight);
  const gapWidth = windowWidth * 0.03;
  const panelWidth = windowWidth >= mobileSizeWidth ? (windowWidth - gapWidth * (numberOfPanels + 1)) / numberOfPanels : windowWidth - gapWidth * 2 > 300 ? 300 : windowWidth * 0.9 - gapWidth * 2;
  const panelHeight = panelWidth > 400 ? panelWidth * 1.2 : panelWidth > 250 ? panelWidth * 2.1 : panelWidth > 100 ? panelWidth * 1.3 : windowHeight * 0.3;
  const cardMediaHeight = panelWidth > 400 ? panelHeight * 0.7 : panelWidth > 250 ? panelHeight * 0.75 : panelHeight * 0.7;

  const { handlePlay, handleStop } = useTextToSpeech();
  const { landingPageSettings } = useColors();

  return (
    <div className="overflow-y-scroll" style={{ height: windowHeight - (appHeight + Math.min(windowHeight * 0.1, 50)) }}>
      <div className={`${windowWidth > mobileSizeWidth ? 'flex items-center justify-center text-white p-5' : 'flex flex-col items-center text-orange-500 p-5'}`}>
        {/* <img src={require('../../../../static/construction.png')} alt="ConstructionIcon"></img>
        <span className={`${windowWidth > mobileSizeWidth ? 'text-xl' : 'text-l'}`}>{t('underConstruction', { ns: ['griff'] })}</span> */}

        <Typography
          gutterBottom
          // variant={windowWidth > 600 ? 'h4' : 'h6'}
          align="center"
          color={landingPageSettings.titleTextColor}
          onMouseEnter={() => handlePlay(t('titleText', { ns: ['home'] }))}
          onMouseLeave={() => handleStop()}
        >
          <span className="font-OpenSansExtraBold md:text-2xl lg:text-3xl">{t('titleText', { ns: ['home'] })}</span>
        </Typography>
      </div>
      <div className={`${windowWidth > mobileSizeWidth ? ' flex flex-row justify-items-between' : 'flex flex-col items-center'} `}>
        <PanelCard
          linkto=""
          // title={'Szolgáltatásaink'}
          // subheader=""
          backgroundColor={landingPageSettings.panelColor[0]}
          image={require('../../../../static/szolgaltatasaink.png')}
          panelWidth={panelWidth}
          panelHeight={panelHeight}
          cardMediaHeight={cardMediaHeight}
          marginLeft={`${windowWidth > mobileSizeWidth ? gapWidth : 0}px`}
          marginRight={`${windowWidth > mobileSizeWidth ? gapWidth : 0}px`}
          marginTop={`${windowWidth <= mobileSizeWidth ? gapWidth : 0}px`}
          marginBottom={`${windowWidth <= mobileSizeWidth ? gapWidth : 0}px`}
          modal="1"
          content={
            <div
            // onMouseEnter={() => handlePlay(t('contentServicesText', { ns: ['home'] }))}
            // onMouseLeave={() => handleStop()}
            >
              <div className="text-center">
                <div className="font-OpenSansBold">{t('servicesTitle', { ns: ['home'] })}</div>
                <div className="font-OpenSansRegular text-gray-600">{t('contentServices', { ns: ['home'] })}</div>
              </div>
            </div>
          }
          contentText={t('contentServicesText', { ns: ['home'] })}
        />
        <PanelCard
          linkto=""
          // title={'Rólunk'}
          // subheader=""
          backgroundColor={landingPageSettings.panelColor[1]}
          image={require('../../../../static/rolunk.png')}
          panelWidth={panelWidth}
          panelHeight={panelHeight}
          cardMediaHeight={cardMediaHeight}
          marginRight={`${windowWidth > mobileSizeWidth ? gapWidth : 0}px`}
          marginBottom={`${windowWidth <= mobileSizeWidth ? gapWidth : 0}px`}
          modal="2"
          content={
            <div
            // onMouseEnter={() => handlePlay(t('contentAboutText', { ns: ['home'] }))}
            // onMouseLeave={() => handleStop()}
            >
              <div className="text-center">
                <div className="font-OpenSansBold">{t('aboutTitle', { ns: ['home'] })}</div>
                <span className="font-OpenSansRegular text-gray-600">{t('contentAbout1', { ns: ['home'] })}</span>
                <a href="https://www.griffsoft.hu/" target="_blank" rel="noreferrer">
                  <span className="text-orange-500">GriffSoft</span>
                </a>
                <span className="text-gray-600">{t('contentAbout2', { ns: ['home'] })}</span>
              </div>
            </div>
          }
          contentText={t('contentAboutText', { ns: ['home'] })}
        />
        <PanelCard
          linkto=""
          // title={'Kapcsolat'}
          // subheader=""
          backgroundColor={landingPageSettings.panelColor[2]}
          image={require('../../../../static/kapcsolat.png')}
          panelWidth={panelWidth}
          panelHeight={panelHeight}
          cardMediaHeight={cardMediaHeight}
          marginRight={`${windowWidth > mobileSizeWidth ? gapWidth : 0}px`}
          marginBottom={`${windowWidth <= mobileSizeWidth ? gapWidth : 0}px`}
          modal="3"
          modalClick
          content={
            <div
            // onMouseEnter={() => handlePlay(t('contentConnectionText', { ns: ['home'] }))}
            // onMouseLeave={() => handleStop()}
            >
              <div className="text-center">
                <div className="font-OpenSansBold">{t('connectionTitle', { ns: ['home'] })}</div>
                <div className="font-OpenSansRegular text-gray-600">{t('contentConnection', { ns: ['home'] })}</div>
              </div>
            </div>
          }
          contentText={t('contentConnectionText', { ns: ['home'] })}
        />
      </div>
    </div>
  );
};

export default InfoPanelPublic;
