import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxDispatch, useReduxSelector } from '../../../../store/Store';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { usePermissions } from '../../../../logics/usePermissions';
import { useAxios } from '../../../../logics/useAxios';
import { API, API_PDFLINK_LIST_INVOICES_C, serverPagination } from '../../../../utils/variables';
import { Refresh } from '@mui/icons-material';
import { useFilters } from '../../../../logics/useFilters';
import { FilterItemType } from '../../../types/Table.type';
import { ItemModal } from '../../../UI/ItemModal';
import useSWR from 'swr';
import { TableMain } from '../../../UI/Table/TableMain';
import { ListModal } from '../../../UI/ListModal';
import { useColors } from '../../../../logics/useColors';
import { homeActions } from '../../../../store/appSlices/HomeSlice';
import useTextToSpeech from '../../../../logics/useTextToSpeech';
import { useDataColumnsInvoice_c } from '../../../fields/customerPagesFields/invoice/dataColumns';

const Invoices_c: React.FC = () => {
  const { i18n, t } = useTranslation(['invoice']);
  const isItemModalOpen = useReduxSelector((state) => state.table.isItemModalOpen);
  const isInvoiceCreateModalOpen = useReduxSelector((state) => state.table.isInvoiceCreateModalOpen);
  const isInvoiceEditModalOpen = useReduxSelector((state) => state.table.isInvoiceEditModalOpen);
  const isInvoiceJsonListModalOpen = useReduxSelector((state) => state.table.isInvoiceJsonListModalOpen);
  const isInvoiceRecipientsListModalOpen = useReduxSelector((state) => state.table.isInvoiceRecipientsListModalOpen);
  const isInvoiceAttachmentsListModalOpen = useReduxSelector((state) => state.table.isInvoiceAttachmentsListModalOpen);
  const isInvoiceAttachmentSignatureListModalOpen = useReduxSelector((state) => state.table.isInvoceAttachmentSignatureListModalOpen);
  const [Filters, setFilters] = useState<FilterItemType[]>([]);
  const isUserSignedIn = useReduxSelector((state) => state.auth.isSignedIn);
  const { isCustomerUser } = usePermissions();
  const [invoicePartner, setInvoicePartner] = useState<string>('0');
  const [loadingSelectList, setLoadingSelectList] = useState(true);

  const { handlePlay, handleStop } = useTextToSpeech();

  const dispatch = useReduxDispatch();
  const { apiService } = useAxios();

  const {
    mixins: { toolbar },
  } = useTheme();

  const appHeight = parseInt(toolbar?.minHeight as string) + 8;

  const { tableSettings } = useColors();

  const {
    data: fetchedData,
    error: isLoadingDataError,
    isLoading: isLoadingData,
    isValidating: isFetchingData,
    mutate: mutateInvoices,
  } = useSWR(isCustomerUser ? [API + `${API_PDFLINK_LIST_INVOICES_C}`, 'GET', ''] : null, ([url, method, body]) => apiService({ url: url, method: method, data: body }));

  const data = useMemo(() => {
    if (fetchedData) {
      if (!serverPagination) return fetchedData;
      return fetchedData.results;
    } else return undefined;
  }, [fetchedData]);

  const { selectOptions, dataColumns, dataColumnsJson, dataColumnsSums, dataColumnsRecipients, dataColumnsAttachments, dataColumnsSignatureInfo } = useDataColumnsInvoice_c();

  const dataColumns_ = useMemo(() => [...dataColumns], [dataColumns]);

  const { fetchedData_filtered } = useFilters({ Filters: Filters, Columns: dataColumns_, fetchedData: data });

  useEffect(() => {
    dispatch(homeActions.setPageName('invoices_'));
  }, [dispatch]);

  return isCustomerUser ? (
    <>
      {!isInvoiceCreateModalOpen && !isInvoiceEditModalOpen && (
        <div className="bg-transparent h-full" style={{ marginLeft: isUserSignedIn && isCustomerUser ? 50 : '' }}>
          <div className="mt-2 flex gap-5 items-center">
            <Tooltip arrow title={t('refreshData', { ns: ['table'] })}>
              <IconButton onClick={() => mutateInvoices()} onMouseEnter={() => handlePlay(t('refreshData', { ns: ['table'] }))} onMouseLeave={() => handleStop()}>
                <Refresh sx={{ fontSize: 30, color: tableSettings.buttonsColor, '&:hover': { scale: '1.5' } }} />
              </IconButton>
            </Tooltip>

            {/* <SelectAutoWidth selectLabel={selectLabel!} selectValue={invoiceType} onSelect={setInvoiceType} selectOptions={selectOptions!} minHeight={tableVariables.selectHeight} /> */}

            {/* {isCustomerUser && (
              <SelectSearch
                selectLabel={t('supplierPartner', { ns: ['invoice'] })}
                selectValue={invoicePartner}
                onSelect={setInvoicePartner}
                rounded={true}
                minWidth={250}
                minHeight={tableVariables.selectHeight}
                async
                apiLink={API_SELECTLIST_SUPPLIER_PARTNERS_S}
                initSelectOptions={{ id: '0', label: t('allPartners', { ns: ['invoice'] }) }}
                defaultSelectOptions={{ id: '0', label: t('allPartners', { ns: ['invoice'] }) }}
              />
            )} */}

            {/* <TableFilter Columns={dataColumns_} Filters={Filters} setFilters={setFilters} /> */}

            <div className="m-auto" style={{ color: tableSettings.titleTextColor }}>
              {t('invoicesTitle', { ns: ['invoice'] })}
            </div>
          </div>
          <>
            <div className={` flex justify-items-center h-[calc(100%-${appHeight}px)]`}>
              <div className={`w-[100%] absolute left-[50%] top-[calc(${appHeight}px)] -translate-x-1/2`}>
                <TableMain
                  rows={fetchedData_filtered}
                  isLoadingData={isLoadingData}
                  isFetchingData={isFetchingData}
                  dataColumns={dataColumns_}
                  selectId={'id'}
                  handleOpenEditModal={() => {}}
                  handleOpenDeleteModal={() => {}}
                  mutate={mutateInvoices}
                  apiValue="invoices_c"
                  translation={'invoice'}
                />
              </div>
            </div>

            {/* {isItemModalOpen && !loadingSelectList && (
              <InvoiceViewModal
                openModal={isInvoiceEditModalOpen}
                handleCloseModal={handleCloseEditModal}
                handleClose={handleCloseEdit}
                handleCancel={handleCancelEdit}
                onSubmitProp={onSubmitEditProp}
                resetOnSubmit
                modalTitle={t('editInvoiceTitle', { ns: ['invoice'] })}
                contentText={t('toEditInvoice', { ns: ['invoice'] })}
                submitText={t('update', { ns: ['invoice'] })}
                baseFields={fields}
                jsonFields={jsonFields}
                sumFields={sumFields}
                dateFields={dateFields}
                translation="invoice"
                defaultValues={rowCustom}
                edit
              />
            )} */}
            {isItemModalOpen && <ItemModal apiLink={'/list/invoicenumbers'} title={t('listInvoiceNumbersTitle', { ns: ['invoice'] })} translation="invoice" />}
            {isInvoiceJsonListModalOpen && <ListModal modal={5} jsonData={data} columns={dataColumnsJson} title={t('listInvoiceJsonTitle', { ns: ['invoice'] })} translation="invoice" />}
            {isInvoiceRecipientsListModalOpen && (
              <ListModal modal={10} jsonData={data} columns={dataColumnsRecipients} title={t('listInvoiceRecipientsTitle', { ns: ['invoice'] })} translation="invoice" />
            )}
            {isInvoiceAttachmentsListModalOpen && (
              <ListModal modal={11} jsonData={data} columns={dataColumnsAttachments} title={t('listInvoiceAttachmentsTitle', { ns: ['invoice'] })} translation="invoice" />
            )}
            {isInvoiceAttachmentSignatureListModalOpen && (
              <ListModal modal={12} jsonData={data} columns={dataColumnsSignatureInfo} title={t('listSignatureInfoTitle', { ns: ['invoice'] })} translation="invoice" />
            )}
          </>
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default Invoices_c;
