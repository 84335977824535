import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Slide, Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useTextToSpeech from '../../logics/useTextToSpeech';
import { useReduxDispatch, useReduxSelector } from '../../store/Store';
import { tableActions } from '../../store/appSlices/TableSlice';
import { TransitionProps } from '@mui/material/transitions';
import { useColors } from '../../logics/useColors';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} onExited={() => {}} />;
});

const EmailTemplateViewLogoModal: React.FC = () => {
  const { t } = useTranslation('emailTemplate');
  const [open, setOpen] = useState(true);
  const srcLogo = useReduxSelector((state) => state.table.rowCustom as any).src;
  const dispatch = useReduxDispatch();
  const { handlePlay, handleStop } = useTextToSpeech();
  const { tableSettings } = useColors();

  const handleClose = () => {
    setOpen(false);
    dispatch(tableActions.setIsEmailTemplateViewLogoModalOpen(false));
  };

  const handleCancelModal = () => {
    handleClose();
  };

  const handleCloseModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  const onConfirm = async () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        sx={{
          border: 1,
          borderColor: tableSettings.buttonsColor,
          '& .MuiDialog-container': {
            '& .MuiPaper-root': { width: 'auto', maxWidth: 'auto' },
          },
        }}
        open={open}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <DialogTitle className="flex items-center justify-center text-2xl py-5" onMouseEnter={() => handlePlay(t('viewLogoModal', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
          {/* {t('viewLogoModal', { ns: ['emailTemplate'] })} */}
        </DialogTitle>
        <DialogContent dividers={true} onMouseEnter={() => handlePlay(t('logoSrc', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
          <Zoom in={true} style={{ transitionDelay: open ? '100ms' : '0ms' }} timeout={700}>
            <DialogContentText>
              <div className="flex items-center justify-center pb-5">
                <img className="m-auto transition-all duration-300 " src={srcLogo} style={{ height: 100 }} alt="logo"></img>
              </div>
            </DialogContentText>
          </Zoom>
        </DialogContent>
        <DialogActions>
          <div className="flex gap-9 mr-7 mb-7">
            {/* <Button onClick={onConfirm} onMouseEnter={() => handlePlay(t('downloadLogo', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
              <span className="bg-transparent text-sky-600 px-4 border border-sky-600 hover:bg-sky-600 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                {t('downloadLogo', { ns: ['emailTemplate'] })}
              </span>
            </Button> */}
            <Button onClick={handleCancelModal} onMouseEnter={() => handlePlay(t('cancel', { ns: ['emailTemplate'] }))} onMouseLeave={() => handleStop()}>
              <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                {t('cancel', { ns: ['emailTemplate'] })}
              </span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EmailTemplateViewLogoModal;
