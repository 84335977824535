import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Slide, Zoom } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useTextToSpeech from '../../logics/useTextToSpeech';
import { useReduxDispatch, useReduxSelector } from '../../store/Store';
import { tableActions } from '../../store/appSlices/TableSlice';
import { TransitionProps } from '@mui/material/transitions';
import { useColors } from '../../logics/useColors';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} onExited={() => {}} />;
});

const InvoiceStoreEmailDataViewModal: React.FC = () => {
  const { t } = useTranslation('invoiceStore');
  const [open, setOpen] = useState(true);
  const data = useReduxSelector((state) => state.table.rowCustom as any);
  const dispatch = useReduxDispatch();
  const { handlePlay, handleStop } = useTextToSpeech();
  const { tableSettings } = useColors();

  const handleClose = () => {
    setOpen(false);
    dispatch(tableActions.setIsInvoiceStoreEmailDataViewModalOpen(false));
  };

  const handleCancelModal = () => {
    handleClose();
  };

  const handleCloseModal: DialogProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  return (
    <>
      <Dialog
        sx={{
          border: 1,
          borderColor: tableSettings.buttonsColor,
          '& .MuiDialog-container': {
            '& .MuiPaper-root': { minWidth: '1000px', maxWidth: 'auto' },
          },
        }}
        open={open}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        scroll="paper"
      >
        <DialogTitle className="flex items-center justify-center text-2xl py-5" onMouseEnter={() => handlePlay(t('viewLogoModal', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
          {t('invoiceStoreEmailData', { ns: ['invoiceStore'] })}
        </DialogTitle>
        <DialogContent dividers={true} onMouseEnter={() => handlePlay(t('logoSrc', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
          <Zoom in={true} style={{ transitionDelay: open ? '100ms' : '0ms' }} timeout={700}>
            <DialogContentText>
              <div className="flex flex-col pb-5">
                <div className="font-semibold" onMouseEnter={() => handlePlay(t('senderAddress', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                  {t('senderAddress', { ns: ['invoiceStore'] })}:
                </div>
                <div className="pb-3" onMouseEnter={() => handlePlay(data.sender_address)} onMouseLeave={() => handleStop()}>
                  {data.sender_address}
                </div>

                <div className="font-semibold" onMouseEnter={() => handlePlay(t('recipient', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                  {t('recipient', { ns: ['invoiceStore'] })}:
                </div>
                <div className="pb-3" onMouseEnter={() => handlePlay(data.recipient)} onMouseLeave={() => handleStop()}>
                  {data.recipient}
                </div>

                <div className="font-semibold" onMouseEnter={() => handlePlay(t('subject', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                  {t('subject', { ns: ['invoiceStore'] })}:
                </div>
                <div className="pb-3" onMouseEnter={() => handlePlay(data.subject)} onMouseLeave={() => handleStop()}>
                  {data.subject}
                </div>

                <div className="font-semibold" onMouseEnter={() => handlePlay(t('sourceText', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                  {t('sourceText', { ns: ['invoiceStore'] })}:
                </div>
                <div className="pb-3" onMouseEnter={() => handlePlay(data.source_text)} onMouseLeave={() => handleStop()}>
                  {data.source_text}
                </div>

                <div className="font-semibold" onMouseEnter={() => handlePlay(t('dateTime', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                  {t('dateTime', { ns: ['invoiceStore'] })}:
                </div>
                <div className="pb-3" onMouseEnter={() => handlePlay(data.recipient)} onMouseLeave={() => handleStop()}>
                  {data.date_time}
                </div>

                <div className="font-semibold" onMouseEnter={() => handlePlay(t('attachmentsLength', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                  {t('attachmentsLength', { ns: ['invoiceStore'] })}:
                </div>
                <div className="pb-3" onMouseEnter={() => handlePlay(data.recipient)} onMouseLeave={() => handleStop()}>
                  {data.attachments_length}
                </div>

                <div className="font-semibold" onMouseEnter={() => handlePlay(t('messageId', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                  {t('messageId', { ns: ['invoiceStore'] })}:
                </div>
                <div className="pb-3" onMouseEnter={() => handlePlay(data.recipient)} onMouseLeave={() => handleStop()}>
                  {data.message_id}
                </div>

                {data.sent_to_admin_at && (
                  <>
                    <div className="font-semibold" onMouseEnter={() => handlePlay(t('sentToAdminAt', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                      {t('sentToAdminAt', { ns: ['invoiceStore'] })}:
                    </div>
                    <div className="pb-3" onMouseEnter={() => handlePlay(data.recipient)} onMouseLeave={() => handleStop()}>
                      {data.sent_to_admin_at}
                    </div>
                  </>
                )}

                {data.sent_to_support_at && (
                  <>
                    <div className="font-semibold" onMouseEnter={() => handlePlay(t('sentToSupportAt', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
                      {t('sentToSupportAt', { ns: ['invoiceStore'] })}:
                    </div>
                    <div className="pb-3" onMouseEnter={() => handlePlay(data.recipient)} onMouseLeave={() => handleStop()}>
                      {data.sent_to_support_at}
                    </div>
                  </>
                )}
              </div>
            </DialogContentText>
          </Zoom>
        </DialogContent>
        <DialogActions>
          <div className="flex gap-9 mr-7 mb-7">
            <Button onClick={handleCancelModal} onMouseEnter={() => handlePlay(t('cancel', { ns: ['invoiceStore'] }))} onMouseLeave={() => handleStop()}>
              <span className="bg-transparent text-red-500 px-4 border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent py-0 hover:scale-125 rounded-full">
                {t('cancel', { ns: ['invoiceStore'] })}
              </span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default InvoiceStoreEmailDataViewModal;
