import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataColumnsProp } from '../../../types/Table.type';
import { useReduxDispatch } from '../../../../store/Store';
import { tableActions } from '../../../../store/appSlices/TableSlice';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Button } from '@mui/material';
import { useColors } from '../../../../logics/useColors';
dayjs.extend(utc);

export const useDataColumnsUndeliveredEmail_ = () => {
  const { t, i18n } = useTranslation(['email']);
  const dispatch = useReduxDispatch();
  const { tableSettings } = useColors();

  const handleClickJson = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: any, modal: string) => {
    e.preventDefault();
    dispatch(tableActions.setRowCustom(data));

    switch (modal) {
      case 'login': {
        dispatch(tableActions.setIsEmailLoginJsonListModalOpen(true));
        break;
      }
      case 'data': {
        dispatch(tableActions.setIsEmailDataJsonListModalOpen(true));
        break;
      }
      default: {
        return false;
      }
    }
  };

  const dataColumns: DataColumnsProp[] = [
    { id: 'id', label: t('id', { ns: ['email'] }), type: 'number' },
    { id: 'sender', label: t('sender', { ns: ['email'] }), type: 'string' },
    { id: 'recipient', label: t('recipient', { ns: ['email'] }), type: 'string' },
    {
      id: 'type',
      label: t('type', { ns: ['email'] }),
      type: 'string',
      component: (row) => {
        if (row.type) {
          return <span>{row.type === 'customer' ? t('typeCustomer', { ns: ['email'] }) : t('typeOther', { ns: ['email'] })}</span>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'invoiceNumber',
      label: t('dataInvoiceNumber', { ns: ['email'] }),
      type: 'string',
      component: (row) => {
        if (row.data.invoiceNumber) {
          return <span>{row.data.invoiceNumber}</span>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'invoice_created_at',
      label: t('createdAt', { ns: ['email'] }),
      type: 'date',
      component: (row) => {
        if (row.invoice_created_at) {
          return <span>{i18n.language === 'hu' ? dayjs(row.invoice_created_at).format('YYYY-MM-DD HH:mm:ss') : dayjs(row.invoice_created_at).format('DD/MM/YYYY HH:mm:ss')}</span>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'partnerName',
      label: t('dataPartnerName', { ns: ['email'] }),
      type: 'string',
      component: (row) => {
        if (row.data.partnerName) {
          return <span>{row.data.partnerName}</span>;
        } else {
          return <></>;
        }
      },
    },
    // {
    //   id: 'partnerEmail',
    //   label: t('dataPartnerEmail', { ns: ['email'] }),
    //   type: 'string',
    //   component: (row) => {
    //     if (row.data.partnerEmail) {
    //       return <span>{row.data.partnerEmail}</span>;
    //     } else {
    //       return <></>;
    //     }
    //   },
    // },
    // { id: 'send_status', label: t('sendStatus', { ns: ['email'] }), type: 'string' },
    {
      id: 'sent_at',
      label: t('sentAt', { ns: ['email'] }),
      type: 'date',
      component: (row) => {
        if (row.sent_at) {
          return <span>{i18n.language === 'hu' ? dayjs(row.sent_at).format('YYYY-MM-DD HH:mm:ss') : dayjs(row.sent_at).format('DD/MM/YYYY HH:mm:ss')}</span>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'data',
      label: t('data', { ns: ['email'] }),
      type: 'string',
      component: (row) => {
        if (row.data) {
          return (
            <Button size="small" sx={{ textTransform: 'uppercase' }} onClick={(e) => handleClickJson(e, row.data, 'data')}>
              <span className={`bg-transparent ${tableSettings.buttonsColorText} text-nowrap`}>{t('data', { ns: ['email'] })}</span>
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
    { id: 'emailcode', label: t('emailCode', { ns: ['email'] }), type: 'string' },
  ];

  const dataColumnsData: DataColumnsProp[] = [
    { id: 'currency', label: t('dataCurrency', { ns: ['email'] }), type: 'string' },
    { id: 'language', label: t('dataLanguage', { ns: ['email'] }), type: 'string' },
    { id: 'invoiceSum', label: t('dataInvoiceSum', { ns: ['email'] }), type: 'string' },
    { id: 'isSEndEmail', label: t('dataIsSendEmail', { ns: ['email'] }), type: 'string' },
    { id: 'partnerName', label: t('dataPartnerName', { ns: ['email'] }), type: 'string' },
    { id: 'partnerEmail', label: t('dataPartnerEmail', { ns: ['email'] }), type: 'string' },
    { id: 'supplierName', label: t('dataSupplierName', { ns: ['email'] }), type: 'string' },
    { id: 'invoiceNumber', label: t('dataInvoiceNumber', { ns: ['email'] }), type: 'string' },
    { id: 'supplierEmail', label: t('dataSupplierEmail', { ns: ['email'] }), type: 'string' },
    { id: 'invoicePaymentTermDate', label: t('dataInvoicePaymentTermDate', { ns: ['email'] }), type: 'string' },
  ];

  const dataColumnsUndelivered: DataColumnsProp[] = [
    { id: 'id', label: t('id', { ns: ['email'] }), type: 'number' },
    { id: 'sender_name', label: t('senderName', { ns: ['email'] }), type: 'string' },
    { id: 'sender_address', label: t('senderAddress', { ns: ['email'] }), type: 'string' },
    { id: 'recipient', label: t('recipient', { ns: ['email'] }), type: 'string' },
    { id: 'subject', label: t('subject', { ns: ['email'] }), type: 'string' },
    {
      id: 'date_time',
      label: t('dateTime', { ns: ['email'] }),
      type: 'date',
      component: (row) => {
        if (row.date_time) {
          return <span>{i18n.language === 'hu' ? dayjs(row.date_time).format('YYYY-MM-DD HH:mm:ss') : dayjs(row.date_time).format('DD/MM/YYYY HH:mm:ss')}</span>;
        } else {
          return <></>;
        }
      },
    },
    { id: 'is_undelivered', label: t('isUndelivered', { ns: ['email'] }), type: 'boolean' },
    { id: 'email_code', label: t('emailCode', { ns: ['email'] }), type: 'string' },
    { id: 'source_text', label: t('sourceText', { ns: ['email'] }), type: 'string' },
    { id: 'message_id', label: t('messageId', { ns: ['email'] }), type: 'string' },
    { id: 'original_recipient', label: t('originalRecipient', { ns: ['email'] }), type: 'string' },
    { id: 'original_message_id', label: t('originalMessageId', { ns: ['email'] }), type: 'string' },
    { id: 'is_sent_to_supplier', label: t('isSentToSupplier', { ns: ['email'] }), type: 'boolean' },
    {
      id: 'sent_to_supplier_at',
      label: t('sentToSupplierAt', { ns: ['email'] }),
      type: 'date',
      component: (row) => {
        if (row.sent_to_supplier_at) {
          return <span>{i18n.language === 'hu' ? dayjs(row.sent_to_supplier_at).format('YYYY-MM-DD HH:mm:ss') : dayjs(row.sent_to_supplier_at).format('DD/MM/YYYY HH:mm:ss')}</span>;
        } else {
          return <></>;
        }
      },
    },
    { id: 'is_sent_to_admin', label: t('isSentToAdmin', { ns: ['email'] }), type: 'boolean' },
    {
      id: 'sent_to_admin_at',
      label: t('sentToAdminAt', { ns: ['email'] }),
      type: 'date',
      component: (row) => {
        if (row.sent_to_admin_at) {
          return <span>{i18n.language === 'hu' ? dayjs(row.sent_to_admin_at).format('YYYY-MM-DD HH:mm:ss') : dayjs(row.sent_to_admin_at).format('DD/MM/YYYY HH:mm:ss')}</span>;
        } else {
          return <></>;
        }
      },
    },
    {
      id: 'error_log',
      label: t('errorLog', { ns: ['email'] }),
      type: 'string',
    },
    {
      id: 'error_at',
      label: t('errorAt', { ns: ['email'] }),
      type: 'date',
      component: (row) => {
        if (row.error_at) {
          return <span>{i18n.language === 'hu' ? dayjs(row.error_at).format('YYYY-MM-DD HH:mm:ss') : dayjs(row.error_at).format('DD/MM/YYYY HH:mm:ss')}</span>;
        } else {
          return <></>;
        }
      },
    },
  ];

  return { dataColumns, dataColumnsData, dataColumnsUndelivered };
};
