import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Task, TaskFields } from './BoardSectionList';

type TaskItemProps = {
  task: Task;
  TASK_FIELDS: TaskFields;
};
type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

const TaskItem: React.FC<TaskItemProps> = ({ task, TASK_FIELDS }) => {
  return (
    <Card>
      <CardContent>
        {TASK_FIELDS.map((item: ArrayElement<TaskFields>) => {
          const value = <span>{`${task[item.label as keyof typeof task]} : ${task[item.id as keyof typeof task]}`}</span>;

          return <div key={item.id}> {item.component ? item.component(task) : value}</div>;
        })}
      </CardContent>
    </Card>
  );
};

export default TaskItem;
